import React, { PureComponent, Fragment } from 'react';
import { connect } from 'react-redux';
import { withRouter, RouteComponentProps } from 'react-router';
import moment from 'moment';
import { push } from 'connected-react-router';

import { AppState } from '../../../store';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import history from '../../../core/services/history';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks/dispatchOpportunitiesMenuCount';
import { loadHolidays } from '../../../common/ducks/holidays';
import { authenticateToken, loadRubiconDispatches } from '../../ducks/index';
import { loadMPUReasonCodes } from 'src/fleet/ducks/rubiconDispatches';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RubiconDispatchesPage from './RubiconDispatchesPage';
import translate from '../../../core/services/translate';
import { YES } from '../../constants/status';

interface RouteParams {
  token: string;
}
interface Props extends RouteComponentProps<RouteParams> {
  userId: string;
  vendorId: number;
  loadHolidays: DuckFunction<typeof loadHolidays>;
  authenticateToken: DuckFunction<typeof authenticateToken>;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  loadMPUReasonCodes: DuckFunction<typeof loadMPUReasonCodes>;
  loadRubiconDispatches: DuckFunction<typeof loadRubiconDispatches>;
  push: typeof push;
}

class RubiconDispatchesPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      authenticateToken,
      userId,
      vendorId,
      loadDispatchOpportunitiesCount,
      loadHolidays,
      loadMPUReasonCodes,
      loadRubiconDispatches,
      match: {
        params: { token },
      },
      location,
      push,
    } = this.props;
    let { showActionRequired, startDate, endDate } = getQueryParams(location.search);
    const isActionRequired = showActionRequired === YES ? true : false;

    if (!startDate && !endDate) {
      startDate =
        showActionRequired === YES
          ? moment().subtract(60, 'days').format('MM/DD/YYYY')
          : moment().subtract(10, 'days').format('MM/DD/YYYY');
      endDate =
        showActionRequired === YES
          ? moment().add(30, 'days').format('MM/DD/YYYY')
          : moment().add(10, 'days').format('MM/DD/YYYY');

      push(createUrl(location.pathname, location.search, { startDate, endDate, showActionRequired }));
    }

    return token
      ? authenticateToken(token).then(response => {
          if (!response || response.isVPortalLoginRequired) {
            history.push({ pathname: '/account/logout', state: { from: history.location } });
          } else {
            return Promise.all([
              loadDispatchOpportunitiesCount(userId, vendorId, token),
              loadMPUReasonCodes(),
              loadRubiconDispatches(userId, token, vendorId, startDate, endDate, isActionRequired),
              loadHolidays(),
            ]);
          }
        })
      : Promise.all([
          loadMPUReasonCodes(),
          loadRubiconDispatches(userId, token, vendorId, startDate, endDate, isActionRequired),
          loadHolidays(),
        ]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('autoDispatch.workOrders')}</DocumentTitle>

        <Resolver
          successComponent={RubiconDispatchesPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/workorders"
        />
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
});

const mapDispatchToProps = {
  authenticateToken,
  loadDispatchOpportunitiesCount,
  loadHolidays,
  loadMPUReasonCodes,
  loadRubiconDispatches,
  push,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RubiconDispatchesPageResolver));
