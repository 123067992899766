import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { BulkyItemScheduler } from '../interfaces/BulkyItemScheduler';
import {
  loadBulkyItemScheduler as doLoadBulkyItemScheduler,
} from '../services/bulkyItemScheduler';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';

// Actions
const START_LOAD = 'vendors/bulkyItemScheduler/START_LOAD';
const COMPLETE_LOAD = 'vendors/bulkyItemScheduler/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/bulkyItemScheduler/FAIL_LOAD';

// Initial state
const initialState = {
  bulkyCategoryTypes: [] as TechnicalType[],
  bulkyItemScheduler: {} as BulkyItemScheduler,
  bulkyItemTypes: [] as TechnicalType[],
  isLoading: false,
  isSaving: false,
  periodTypes: [] as TechnicalType[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          bulkyItemScheduler: action.bulkyItemScheduler,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (bulkyItemScheduler: BulkyItemScheduler) => ({
  type: COMPLETE_LOAD,
  bulkyItemScheduler,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadBulkyItemScheduler = (vendorId: number) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadBulkyItemSchedulerPromise = doLoadBulkyItemScheduler(vendorId);
  loadBulkyItemSchedulerPromise
    .then((bulkyItemScheduler: BulkyItemScheduler) => {
      dispatch(completeLoad(bulkyItemScheduler));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadBulkyItemSchedulerPromise;
};
