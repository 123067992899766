import { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { push } from 'connected-react-router';
import { destroy, reset } from 'redux-form';
import { difference, size } from 'lodash-es';
import { withRouter, RouteComponentProps } from 'react-router';

import { AppState } from '../../../store';
import confirm from '../../../core/services/confirm';
import { currentVendorIdSelector, currentUserIdSelector } from '../../../vendors/services/currentVendorSelector';
import {
  DetailsContainer,
  ForwardIconContainer,
  StatusFilterButton,
  StatusFiltersContainer,
} from '../styled/RubiconDispatches';
import { DuckFunction, DuckAction } from '../../../contracts/ducks';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { getDateByStatus } from '../utils/rubiconDispatchesPageHooks';
import { Table, PopoverWrapper, Icon } from '../../../core/components';
import { multiWordAndSearch } from '../../../core/services/search';
import {
  ModalBackButtonIcon,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Popover,
  Text,
  Button,
  ButtonSet,
} from '../../../core/components/styled';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { RubiconDispatchesFilterForm } from '../forms';
import RubiconDispatchesTableRow from './RubiconDispatchesTableRow';
import {
  CANCELLED,
  CONFIRM_SERVICE,
  ON_SCHEDULE,
  REQUESTED,
  SERVICE_CONFIRMED,
  WORK_ORDER_COMPLETE,
} from '../../constants';
import { loadRubiconDispatches, resetRubiconDispatches, hideNotesModal } from '../../ducks';
import { TABLE_ROW_HEIGHT_LARGEST } from '../../../core/constants';
import { NotesState } from '../../interfaces/RubiconDispatchesNotes';
import RubiconDispatchesNotesModalResolver from '../modal/RubiconDispatchesNotesModalResolver';
import { SCHEDULED } from '../../constants/rubiconDisptachesStatus';
import translate from '../../../core/services/translate';
import { OPEN_DISPATCHES_SERVICES_LIST } from '../../constants/openDispatachesServices';
import { downloadExcelFile } from '../../../utils/services/downloadExcelFile';
import { YES, NO } from '../../constants/status';
import { changeMobilePageSecondaryContent, changeMobilePageSubTitle } from 'src/core/ducks/mobilePage';
import { WorkOrdersMobilePageFiltersModal } from '../modal/WorkOrdersMobilePageFiltersModal';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { MobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { MobileList } from 'src/core/components/MobileList';
import { PENDING } from '../../constants/rubiconDisptachesStatus';
import { RubiconDispatchesMobileListRow } from './RubiconDispatchesMobileListRow';
import AddNewWorkOrderModalResolver from 'src/customers/components/modals/AddNewWorkOrderModalResolver';
import TicketConfirmationModalResolver from 'src/customers/components/modals/TicketConfirmationModalResolver';

const rubiconDispatchesTableCells = [
  {
    name: 'workorderNumber',
    label: translate('autoDispatch.rubiconDispatchesColumns.workorderNumber'),
    width: '15%',
    sortable: true,
  },
  {
    name: 'equipmentType',
    label: translate('opportunity.serviceInfo'),
    width: '23%',
    sortable: true,
    padding: 'defaultCellVertical no',
  },
  {
    name: 'customerName',
    label: translate('autoDispatch.rubiconDispatchesColumns.customer'),
    width: '24%',
    sortable: true,
  },
  {
    name: 'workOrderStatusDate',
    label: translate('common.status'),
    width: '20%',
    sortable: true,
  },
  {
    name: 'actions',
    label: translate('autoDispatch.rubiconDispatchesColumns.actions'),
    width: '18%',
    align: 'center',
    sortable: false,
  },
];

interface Props extends RouteComponentProps {
  divisions: any[];
  equipmentSubTypes: any[];
  isLoading: boolean;
  location: any;
  match: any;
  materialTypes: any[];
  notesModal: NotesState['modal'];
  push: any;
  reset: any;
  destroy: any;
  rubiconDispatches: any[];
  smartFilterOptions: any;
  notificationsCount: any;
  userId: string;
  vendorId: number;
  hideNotesModal: DuckAction<typeof hideNotesModal>;
  loadRubiconDispatches: DuckFunction<typeof loadRubiconDispatches>;
  changeMobilePageSecondaryContent: DuckFunction<typeof changeMobilePageSecondaryContent>;
  changeMobilePageSubTitle: DuckFunction<typeof changeMobilePageSubTitle>;
  resetRubiconDispatches: () => void;
}

interface State {
  defaultFilterId?: number | string | null;
  divisionIds: string[];
  equipmentSubTypeIds: string[];
  fromDate: string;
  isActionRequired: boolean;
  materialTypeIds: string[];
  prevDefaultFilterId?: number | string | null;
  prevFromDate: string;
  prevToDate: string;
  searchTerm: string;
  serviceIds: number[];
  toDate: string;
  isMobileFilterModalOpen: boolean;
  isAddNewWorkOrderModalOpen: boolean;
  isTicketConfirmationModalOpen: boolean;
  ticketConfirmationValues: any;
}

class RubiconDispatchesPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      isMobileFilterModalOpen: false,
      isAddNewWorkOrderModalOpen: false,
      isTicketConfirmationModalOpen: false,
      defaultFilterId: REQUESTED,
      divisionIds: [],
      equipmentSubTypeIds: [],
      fromDate: '',
      isActionRequired: false,
      materialTypeIds: [],
      prevDefaultFilterId: null,
      prevFromDate: '',
      prevToDate: '',
      searchTerm: '',
      serviceIds: [],
      ticketConfirmationValues: {},
      toDate: '',
    };
  }

  componentDidMount() {
    this.initializeState();
    this.props.changeMobilePageSecondaryContent(this.getMobilePageFilter());
  }

  getMobilePageFilter = () => (
    <Button margin="no small" onClick={() => this.setState({ isMobileFilterModalOpen: true })} text>
      <Icon width="24px" icon="filter" />
    </Button>
  );

  initializeState = () => {
    const {
      location: { search },
    } = this.props;
    const { showActionRequired, confirmService, onSchedule } = getQueryParams(search);
    if (confirmService) {
      this.setState({ defaultFilterId: CONFIRM_SERVICE });
    } else if (onSchedule) {
      this.setState({ defaultFilterId: ON_SCHEDULE });
    } else if (showActionRequired === YES) {
      this.setState({ defaultFilterId: null });
    }
  };

  updateMobilePageSubtitle = () => {
    this.props.changeMobilePageSubTitle(
      <>
        <Text weight="normal" margin="no xxSmall" singleLine size="small">
          {translate('common.total')}: {this.props.rubiconDispatches.length}
        </Text>
        <Text weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
          {translate('common.overdue')}: {this.props.rubiconDispatches.filter(d => !!d.daysOverDue).length}
        </Text>
      </>,
    );
  };

  componentDidUpdate(prevProps: Props) {
    const {
      location: { search: lastSearch },
    } = prevProps;
    const {
      loadRubiconDispatches,
      location: { search },
      match: {
        params: { token },
      },
      userId,
      vendorId,
    } = this.props;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    if (search !== lastSearch) {
      this.initializeState();
      if (!!startDate && !!endDate)
        loadRubiconDispatches(userId, token, vendorId, startDate, endDate, isActionRequired);
    }
    this.updateMobilePageSubtitle();
  }

  componentWillUnmount() {
    const { resetRubiconDispatches } = this.props;

    resetRubiconDispatches();
    this.props.changeMobilePageSecondaryContent();
    this.props.changeMobilePageSubTitle();
  }

  filteredDispatches = (onlySmartFilters = false) => {
    const { rubiconDispatches } = this.props;
    const {
      defaultFilterId: selectedFilterId,
      searchTerm,
      divisionIds,
      equipmentSubTypeIds,
      materialTypeIds,
      serviceIds,
    } = this.state;
    const today = moment().format('MM/DD/YYYY');
    let filteredList = rubiconDispatches.slice();
    const noStaticFilters =
      !searchTerm &&
      !divisionIds.length &&
      !equipmentSubTypeIds.length &&
      !materialTypeIds.length &&
      !serviceIds.length;

    if (!selectedFilterId && noStaticFilters) {
      return rubiconDispatches;
    }

    if (selectedFilterId) {
      if (selectedFilterId === REQUESTED) {
        filteredList = rubiconDispatches.filter((x: any) => x.statusId === REQUESTED);
      } else if (selectedFilterId === ON_SCHEDULE) {
        filteredList = rubiconDispatches.filter((x: any) => {
          const scheduledDate = moment(x.dates.scheduledDate).format('MM/DD/YYYY');
          return (x.statusId === SCHEDULED || x.statusId === PENDING) && moment(scheduledDate).isAfter(today);
        });
      } else if (selectedFilterId === CONFIRM_SERVICE) {
        filteredList = rubiconDispatches.filter((x: any) => {
          const scheduledDate = moment(x.dates.scheduledDate).format('MM/DD/YYYY');
          return (x.statusId === SCHEDULED || x.statusId === PENDING) && moment(scheduledDate).isSameOrBefore(today);
        });
      } else if (selectedFilterId === WORK_ORDER_COMPLETE) {
        filteredList = rubiconDispatches.filter(
          (x: any) => x.statusId === WORK_ORDER_COMPLETE || x.statusId === CANCELLED,
        );
      } else {
        filteredList = rubiconDispatches.filter(dispatch => selectedFilterId === dispatch.statusId);
      }
    }

    if (onlySmartFilters || noStaticFilters) {
      return filteredList;
    }
    const doubleFilteredList = filteredList.filter(
      dispatch =>
        (!divisionIds.length || divisionIds.indexOf(dispatch.vendID) !== -1) &&
        (!equipmentSubTypeIds.length || equipmentSubTypeIds.indexOf(dispatch.equipment.subType) !== -1) &&
        (!materialTypeIds.length || materialTypeIds.indexOf(dispatch.equipment.wasteMaterial) !== -1) &&
        (!serviceIds.length || serviceIds.indexOf(dispatch.pickupTypeId) !== -1) &&
        (!searchTerm ||
          multiWordAndSearch(dispatch.customerName, searchTerm) ||
          multiWordAndSearch(dispatch.customerAddress, searchTerm) ||
          multiWordAndSearch(dispatch.siteName, searchTerm) ||
          multiWordAndSearch(dispatch.siteCode, searchTerm) ||
          multiWordAndSearch(dispatch.workorderNumber, searchTerm)),
    );
    return doubleFilteredList;
  };

  disabledFilters = () => {
    const { location } = this.props;
    const { showActionRequired } = getQueryParams(location.search);

    const dispatches = this.filteredDispatches(true);
    const { divisions, equipmentSubTypes, materialTypes: materialTypeIds } = this.props;

    const divisionIds = divisions.map(division => division.value);
    const equipmentSubTypeIds = equipmentSubTypes.map(type => type.id);
    const serviceIds = OPEN_DISPATCHES_SERVICES_LIST.map(service => service.id);

    const foundDivisionIds: string[] = [];
    const foundEquipmentSubTypeIds: string[] = [];
    const foundMaterialTypeIds: string[] = [];
    const foundServiceIds: number[] = [];

    dispatches.forEach(dispatch => {
      if (foundDivisionIds.indexOf(dispatch.vendID) === -1) {
        foundDivisionIds.push(dispatch.vendID);
      }

      if (foundEquipmentSubTypeIds.indexOf(dispatch.equipment.subType) === -1) {
        foundEquipmentSubTypeIds.push(dispatch.equipment.subType);
      }

      if (foundMaterialTypeIds.indexOf(dispatch.equipment.wasteMaterial) === -1) {
        foundMaterialTypeIds.push(dispatch.equipment.wasteMaterial);
      }

      if (foundServiceIds.indexOf(dispatch.pickupTypeId) === -1) {
        foundServiceIds.push(dispatch.pickupTypeId);
      }
    });
    const disabledDivisionIds: string[] = difference(divisionIds, foundDivisionIds);
    const disabledEquipmentSubTypeIds: string[] = difference(equipmentSubTypeIds, foundEquipmentSubTypeIds);
    const disabledMaterialTypeIds: string[] = difference(materialTypeIds, foundMaterialTypeIds);
    const disabledServiceIds: number[] = difference(serviceIds, foundServiceIds);
    const disabledDateRange: boolean = showActionRequired === YES ? true : false;
    return {
      disabledDivisionIds,
      disabledEquipmentSubTypeIds,
      disabledMaterialTypeIds,
      disabledServiceIds,
      disabledDateRange,
    };
  };

  onDispatchesCriteriaChange = ({
    searchTerm = '',
    dateRange,
    divisionIds,
    equipmentSubTypeIds,
    materialTypeIds,
    serviceIds,
    isActionRequired,
  }: any) => {
    const { push, location } = this.props;
    const { defaultFilterId } = this.state;
    const { startDate, endDate, showActionRequired } = getQueryParams(location.search);
    const currentActionRequired = showActionRequired === YES ? true : false;

    if (!!dateRange && (dateRange.from !== startDate || dateRange.to !== endDate)) {
      push(
        createUrl(location.pathname, location.search, {
          startDate: dateRange.from,
          endDate: dateRange.to,
        }),
      );
    }
    let currentDefaultFilterId;
    if (isActionRequired !== currentActionRequired) {
      let currentStartDate;
      let currentEndDate;
      if (isActionRequired) {
        this.setState({
          prevDefaultFilterId: defaultFilterId,
          prevFromDate: startDate,
          prevToDate: endDate,
        });
        currentStartDate = moment().subtract(60, 'days').format('MM/DD/YYYY');
        currentEndDate = moment().add(30, 'days').format('MM/DD/YYYY');
      } else {
        const { prevDefaultFilterId, prevFromDate, prevToDate } = this.state;
        currentDefaultFilterId = !prevDefaultFilterId ? REQUESTED : prevDefaultFilterId;
        currentStartDate = !prevFromDate ? moment().subtract(10, 'days').format('MM/DD/YYYY') : prevFromDate;
        currentEndDate = !prevToDate ? moment().add(10, 'days').format('MM/DD/YYYY') : prevToDate;
        this.setState({ defaultFilterId: currentDefaultFilterId });
      }
      push(
        createUrl(location.pathname, location.search, {
          startDate: currentStartDate,
          endDate: currentEndDate,
          showActionRequired: isActionRequired ? YES : NO,
        }),
      );
    }

    this.setState({
      divisionIds: divisionIds || [],
      equipmentSubTypeIds: equipmentSubTypeIds || [],
      isActionRequired,
      materialTypeIds: materialTypeIds || [],
      searchTerm,
      serviceIds: serviceIds || [],
    });
  };

  onQuickFilterClick = (statusId: any) => {
    const { defaultFilterId: selectedFilterId } = this.state;
    this.setState({ defaultFilterId: selectedFilterId === statusId ? null : statusId });
  };

  getPopoverInfo = (status: number) => {
    switch (status) {
      case CANCELLED:
        return translate('tooltips.cancelled');

      case REQUESTED:
        return translate('tooltips.requested');

      case ON_SCHEDULE:
        return translate('tooltips.onSchedule');

      case CONFIRM_SERVICE:
        return translate('tooltips.confirmService');

      case SERVICE_CONFIRMED:
        return translate('tooltips.serviceConfirmed');

      case WORK_ORDER_COMPLETE:
        return translate('tooltips.completed');

      default:
        break;
    }
  };

  closeNotesModal = async (isReload: boolean, formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    const { reset, hideNotesModal } = this.props;
    reset('notesForm');
    hideNotesModal();

    if (isReload) {
      this.refreshPage();
    }
  };

  refreshPage = () => {
    const {
      location: { search },
      loadRubiconDispatches,
      match: {
        params: { token },
      },
      userId,
      vendorId,
    } = this.props;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    return loadRubiconDispatches(userId, token, vendorId, startDate, endDate, isActionRequired);
  };

  exportReport = () => {
    const {
      vendorId,
      userId,
      location: { search },
      match: {
        params: { token },
      },
    } = this.props;
    const {
      searchTerm,
      equipmentSubTypeIds,
      materialTypeIds,
      serviceIds,
      defaultFilterId: activeFilterId,
    } = this.state;
    const { showActionRequired, startDate, endDate } = getQueryParams(search);
    const isActionRequired = showActionRequired === YES ? true : false;
    const url = token
      ? `gus/workorderautodispatch/dispatches/export/${token}?isActionRequired=${isActionRequired}`
      : `gus/workorderautodispatch/dispatches/export/vendor/${vendorId}/user/${userId}?isActionRequired=${isActionRequired}`;

    const params = {
      startDate: isActionRequired ? moment().subtract(60, 'days').format('MM/DD/YYYY') : startDate,
      endDate: isActionRequired ? moment().add(30, 'days').format('MM/DD/YYYY') : endDate,
      search: searchTerm,
      equipmentType: equipmentSubTypeIds,
      materialType: materialTypeIds,
      serviceType: serviceIds,
      smartFilterId: activeFilterId,
    };

    downloadExcelFile(url, translate('autoDispatch.exportWorkOrders'), params);
  };

  render() {
    const {
      divisions,
      equipmentSubTypes,
      isLoading,
      location: { search },
      materialTypes: materialTypeIds,
      notesModal,
      smartFilterOptions,
    } = this.props;
    const {
      defaultFilterId,
      searchTerm,
      isMobileFilterModalOpen,
      isTicketConfirmationModalOpen,
      isAddNewWorkOrderModalOpen,
      prevDefaultFilterId,
      ticketConfirmationValues,
    } = this.state;
    const isActionRequired = getQueryParams(search).showActionRequired === YES ? true : false;

    const {
      startDate = isActionRequired
        ? moment().subtract(60, 'days').format('MM/DD/YYYY')
        : moment().subtract(10, 'days').format('MM/DD/YYYY'),
      endDate = isActionRequired
        ? moment().add(30, 'days').format('MM/DD/YYYY')
        : moment().add(10, 'days').format('MM/DD/YYYY'),
    } = getQueryParams(search);
    const dispatches = this.filteredDispatches();
    const disabledFilters = this.disabledFilters();
    const divisionIds = divisions.map(division => division.value);
    const equipmentSubTypeIds = equipmentSubTypes.map(type => type.id);
    const serviceIds = OPEN_DISPATCHES_SERVICES_LIST.map(service => service.id);

    const dispatchesFormInitialValues = {
      isActionRequired,
      searchTerm,
      dateRange: {
        from: startDate,
        to: endDate,
      },
      divisionIds: difference(divisionIds, disabledFilters.disabledDivisionIds),
      equipmentSubTypeIds: difference(equipmentSubTypeIds, disabledFilters.disabledEquipmentSubTypeIds),
      materialTypeIds: difference(materialTypeIds, disabledFilters.disabledMaterialTypeIds),
      serviceIds: difference(serviceIds, disabledFilters.disabledServiceIds),
    };

    const dispatchesList = dispatches.map(dispatch => ({
      ...dispatch,
      workOrderStatusDate: getDateByStatus(dispatch.statusName, dispatch.dates),
    }));

    const getSmartFilter = (option: any, small?: boolean) => {
      return (
        <StatusFilterButton
          small={small}
          key={option.label}
          type="button"
          isActive={option.value === defaultFilterId}
          onClick={() => this.onQuickFilterClick(option.value)}
        >
          <Text uppercase size="small" weight="medium" margin="no" color="primary">
            {option.label}
          </Text>

          <Text size="small" margin="xxSmall no no" weight="medium">
            {translate('autoDispatch.workOrdersCount', { count: option.count })}
          </Text>

          {!!option.overdue && (
            <Text margin="xxSmall no no" size="small" color="alert">
              {translate('autoDispatch.overdueCount', { count: option.overdue })}
            </Text>
          )}
        </StatusFilterButton>
      );
    };

    const getSmartFilterWithPopover = (option: any) => {
      return (
        <PopoverWrapper
          triggerButton={getSmartFilter(option)}
          popoverContent={<Popover>{this.getPopoverInfo(option.value)}</Popover>}
          size="small"
        />
      );
    };

    const getSmartFilters = (withPopover?: boolean) => {
      return !!size(smartFilterOptions)
        ? smartFilterOptions.map((option: any, index: number) => (
            <Fragment key={index}>
              {withPopover ? getSmartFilterWithPopover(option) : getSmartFilter(option, true)}

              {option.value !== WORK_ORDER_COMPLETE && (
                <ForwardIconContainer>
                  <ModalBackButtonIcon forward />
                </ForwardIconContainer>
              )}
            </Fragment>
          ))
        : null;
    };

    return (
      <>
        <PageContent>
          <DesktopWidthView>
            <PageHeader>
              <PageDetails withBackButton>
                <PageTitleContainer>
                  <PageTitle>{translate('autoDispatch.workOrders')}</PageTitle>
                </PageTitleContainer>
              </PageDetails>
              <PageActions flex>
                <ButtonSet margin="no no small no" vertical={true}>
                  <Button color="primary" margin="no no small medium" onClick={this.exportReport}>
                    {translate('common.export')}
                  </Button>
                  <Button
                    color="primary"
                    margin="no no no medium"
                    onClick={() => this.setState({ isAddNewWorkOrderModalOpen: true })}
                  >
                    {translate('customers.rubiconService.addNew')}
                  </Button>
                </ButtonSet>
              </PageActions>
            </PageHeader>
          </DesktopWidthView>

          <Panel>
            <PanelSectionGroup isLoading={isLoading}>
              <DetailsContainer noShadow padding="xxSmall">
                <DesktopWidthView>
                  <StatusFiltersContainer verticalCenter maxFilterWidth={5} padding="xxSmall">
                    {getSmartFilters(true)}
                  </StatusFiltersContainer>
                </DesktopWidthView>
                <MobileWidthView>
                  <StatusFiltersContainer noWrap verticalCenter maxFilterWidth={1} padding="small xxSmall">
                    {getSmartFilters(false)}
                  </StatusFiltersContainer>
                </MobileWidthView>
              </DetailsContainer>
              <DesktopWidthView>
                <RubiconDispatchesFilterForm
                  initialValues={dispatchesFormInitialValues}
                  onChange={this.onDispatchesCriteriaChange}
                  {...disabledFilters}
                />
              </DesktopWidthView>

              <PanelSection>
                <DesktopWidthView>
                  <Table
                    cells={rubiconDispatchesTableCells}
                    rows={dispatchesList}
                    rowComponent={RubiconDispatchesTableRow}
                    virtualized
                    scrollMarker
                    noOverflow
                    virtualizedProps={{
                      height: Math.min(dispatchesList.length * TABLE_ROW_HEIGHT_LARGEST, TABLE_ROW_HEIGHT_LARGEST * 8),
                      itemSize: TABLE_ROW_HEIGHT_LARGEST,
                    }}
                  />
                </DesktopWidthView>
              </PanelSection>
              <MobileWidthView>
                <MobileList
                  onSearch={searchTerm =>
                    this.onDispatchesCriteriaChange({ ...dispatchesFormInitialValues, ...this.state, searchTerm })
                  }
                  rows={dispatchesList}
                  virtualizedProps={{
                    itemSize: 123,
                    height: !dispatchesList.length ? 0 : document.documentElement.clientHeight - (52 + 105 + 70),
                  }}
                  rowComponent={RubiconDispatchesMobileListRow}
                  detailsComponentProps={{ showMobileDetails: true }}
                  detailsComponent={RubiconDispatchesTableRow}
                />
              </MobileWidthView>
              <PanelSection>
                {!dispatchesList.length && <Text margin="small">{translate('autoDispatch.noWorkorders')}</Text>}
              </PanelSection>
            </PanelSectionGroup>
          </Panel>

          {!!notesModal && <RubiconDispatchesNotesModalResolver onCancel={this.closeNotesModal} />}

          {isAddNewWorkOrderModalOpen && (
            <>
              <AddNewWorkOrderModalResolver
                closeModal={() => {
                  this.setState({ isAddNewWorkOrderModalOpen: false });
                }}
                toggleTicketConfirmation={() => this.setState({ isTicketConfirmationModalOpen: true })}
                setTicketConfirmationValues={(values: any) => {
                  this.setState({ ticketConfirmationValues: values });
                }}
                isRubiconServicesPage={false}
                hidden={false}
              ></AddNewWorkOrderModalResolver>
            </>
          )}
          {isTicketConfirmationModalOpen && (
            <TicketConfirmationModalResolver
              closeModal={() => {
                this.setState({ isTicketConfirmationModalOpen: false });
              }}
              values={ticketConfirmationValues}
            ></TicketConfirmationModalResolver>
          )}

          <MobileWidthView>
            <WorkOrdersMobilePageFiltersModal
              hidden={!isMobileFilterModalOpen}
              defaultFilterId={defaultFilterId}
              prevDefaultFilterId={prevDefaultFilterId}
              initialValues={dispatchesFormInitialValues}
              onChange={values => this.onDispatchesCriteriaChange({ ...values, searchTerm: this.state.searchTerm })}
              onClose={() => this.setState({ isMobileFilterModalOpen: false })}
              {...disabledFilters}
            />
          </MobileWidthView>
        </PageContent>
      </>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  divisions: state.fleet.rubiconDispatches.divisions,
  equipmentSubTypes: state.fleet.rubiconDispatches.equipmentOptions,
  isLoading: state.fleet.rubiconDispatches.isLoading,
  materialTypes: state.fleet.rubiconDispatches.materialTypes,
  notesModal: state.fleet.notes.modal,
  rubiconDispatches: state.fleet.rubiconDispatches.rubiconDispatches,
  smartFilterOptions: state.fleet.rubiconDispatches.smartFilterOptions,
  userId: (currentUserIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  vendorId: (currentVendorIdSelector as any)(state.account.login, state.vendors.defaultVendor),
  notificationsCount: state.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts,
});

const mapDispatchToProps = {
  loadRubiconDispatches,
  resetRubiconDispatches,
  push,
  reset,
  destroy,
  hideNotesModal,
  changeMobilePageSecondaryContent,
  changeMobilePageSubTitle,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(RubiconDispatchesPage));
