import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { map } from 'lodash-es';
import { Field, reduxForm, getFormValues, formValueSelector, InjectedFormProps } from 'redux-form';
import { RouteComponentProps } from 'react-router';

import { ActionButtonTooltip, Input, Dropdown, DatePicker, RadioButton } from '../../../core/components';
import { AppState } from 'src/store';
import { Button, ButtonSet, Grid, GridColumn, HorizontalRule, Text } from '../../../core/components/styled';
import { createSuccessNotification, createErrorNotification } from '../../../core/services/createNotification';
import { DesktopWidthView } from 'src/core/components/mediaQueries/DesktopWidthView';
import { DOCUMENT_TYPE, UNIT_OF_MEASUREMENT, CONFIRMED_WITHOUT_UPLOAD, COMPLETED } from '../../constants';
import { DuckFunction } from 'src/contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { isRequired, isDecimal, isDecimalWithZero, isDateValidValidator } from '../../../utils/services/validator';
import { loadDispatchOpportunitiesCount } from '../../../common/ducks';
import { MISSED_PICKUP } from 'src/fleet/constants/openDispatachesServices';
import { responseMissedPickup } from 'src/fleet/ducks/openDispatches';
import { uploadDocument, uploadDocumentImage, updateServiceConfirmation } from '../../ducks';
import openDispatchesFormInitialValueSelector from '../../services/openDispatchesFormInitialValueSelector';
import { TODAY } from '../../../core/constants';
import translate from '../../../core/services/translate';
import { UseIsMobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';

const UPLOAD_FILE = 'UploadFile';
const FieldMock = Field as any;

type RouteParams = {
  token: string;
};

interface PropsWithoutReduxForm extends RouteComponentProps<RouteParams> {
  completedDate?: string;
  documentType?: string;
  fileDetails: any[];
  isRollOff: boolean;
  isSaving: boolean;
  isUploadingDocumentImage: boolean;
  responseMissedPickup: DuckFunction<typeof responseMissedPickup>;
  loadDispatchOpportunitiesCount: DuckFunction<typeof loadDispatchOpportunitiesCount>;
  onCancel: (pristine: boolean, isReload?: boolean) => void;
  pickupTypeId: number;
  rubiconPONbr: string;
  serviceDate: string;
  status?: string;
  defaultUom?: string;
  uom?: number | string;
  updateServiceConfirmation: DuckFunction<typeof updateServiceConfirmation>;
  uploadDocument: DuckFunction<typeof uploadDocument>;
  uploadDocumentImage: DuckFunction<typeof uploadDocumentImage>;
  userId: string;
  vendID: string;
  vendorId: number;
  weightTicket?: string;
  itemId: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<{}, PropsWithoutReduxForm>;

type State = {
  selectedFileName: string;
  selectedFileId: number;
};

class DocumentUploadForm extends PureComponent<Props, State> {
  private uploadFileRef = React.createRef<HTMLInputElement>();
  state = {
    selectedFileName: '',
    selectedFileId: 0,
  };

  onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const files = e.target.files;
    if (files && files.length > 0) {
      this.onProfileImageSelected(files);
    } else {
      this.props.change('isUploadFile', '');
    }
    window.removeEventListener('focus', this.handleFocusBack);
  };

  onProfileImageSelected = (files: FileList) => {
    const {
      vendorId,
      uploadDocument,
      match: {
        params: { token },
      },
    } = this.props;
    const { selectedFileId } = this.state;
    uploadDocument(token, files[0], vendorId)
      .then(response => {
        this.props.change('isUploadFile', UPLOAD_FILE);
        this.setState({ selectedFileId: response.id, selectedFileName: response.fileName });
        createSuccessNotification(translate('common.alertMessages.uploadCompleted'));
      })
      .catch(() => {
        createErrorNotification(translate('common.alertMessages.uploadFailedDuplicateFileName'));
        if (!selectedFileId) {
          this.props.change('isUploadFile', '');
        }
      });
  };

  onSave = (event: React.FormEvent<HTMLFormElement>) => {
    const {
      onCancel,
      documentType,
      completedDate,
      itemId,
      pickupTypeId,
      rubiconPONbr,
      status,
      serviceDate,
      weightTicket,
      defaultUom,
      uom,
      userId,
      vendorId,
      responseMissedPickup,
      updateServiceConfirmation,
      loadDispatchOpportunitiesCount,
      uploadDocumentImage,
      location: { search },
      match: {
        params: { token },
      },
      fileDetails,
      vendID,
    } = this.props;
    const { dispatchStatus, startDate, endDate, workOrder, workOrderType, page, limit } = getQueryParams(search);
    const searchFilter = {
      dispatchStatus,
      startDate,
      endDate,
      workOrder,
      workOrderType,
      page,
      limit,
    };

    if (!completedDate) {
      return;
    }
    event.preventDefault();
    const { selectedFileId } = this.state;
    const weightTicketUom = defaultUom;
    if (selectedFileId) {
      const promise = uploadDocumentImage(
        selectedFileId,
        token,
        rubiconPONbr,
        documentType,
        userId,
        vendorId,
        serviceDate,
      );

      promise &&
        promise
          .then(() => {
            const action = 'Completed';
            const promise = updateServiceConfirmation(
              rubiconPONbr,
              status,
              null,
              null,
              moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
              action,
              weightTicket,
              uom,
              userId,
              token,
              vendorId,
              vendID,
              weightTicketUom,
              searchFilter,
              completedDate ? moment(completedDate).format('YYYY-MM-DD HH:mm:ss') : null,
            );

            promise &&
              promise
                .then(response => {
                  if (!response.errorMessage) {
                    const isReload = true;
                    loadDispatchOpportunitiesCount(userId, vendorId, token);
                    createSuccessNotification(translate('opportunity.opportunitySave'));
                    onCancel(true, isReload);
                  } else {
                    createErrorNotification(response.errorMessage, 15000);
                  }
                })
                .catch(() => {
                  createErrorNotification(translate('opportunity.opportunitySaveError'));
                });
          })
          .catch(() => {
            createErrorNotification(translate('common.alertMessages.uploadFailedDuplicateFileName'));
          });
    } else {
      const action = fileDetails && fileDetails.length > 0 ? 'Completed' : 'ConfirmedWithOutUpload';
      const responseType = 'SCHEDULED';
      const promise =
        pickupTypeId === MISSED_PICKUP && !!itemId
          ? responseMissedPickup(
              itemId,
              responseType,
              completedDate ? moment(completedDate).format('YYYY-MM-DD') : '',
              undefined,
              userId,
              token,
              vendorId,
              undefined,
              weightTicket,
              uom,
            )
          : updateServiceConfirmation(
              rubiconPONbr,
              status,
              null,
              null,
              moment(serviceDate).format('YYYY-MM-DD HH:mm:ss'),
              action,
              weightTicket,
              uom,
              userId,
              token,
              vendorId,
              vendID,
              weightTicketUom,
              searchFilter,
              completedDate ? moment(completedDate).format('YYYY-MM-DD HH:mm:ss') : null,
            );

      promise &&
        promise
          .then(response => {
            const isReload = true;
            if (!response.errorMessage) {
              loadDispatchOpportunitiesCount(userId, vendorId, token);
              createSuccessNotification(translate('opportunity.opportunitySave'));
              onCancel(true, isReload);
            } else {
              createErrorNotification(response.errorMessage, 15000);
            }
          })
          .catch(() => {
            createErrorNotification(translate('opportunity.opportunitySaveError'));
          });
    }
  };

  handleChange = (_: unknown, value: string) => {
    if (value === UPLOAD_FILE) this.openFileUploader();
  };

  openFileUploader = () => {
    this.uploadFileRef.current?.click();
  };

  clearfileName = () => {
    this.setState({ selectedFileId: 0, selectedFileName: '' });
    this.props.change('isUploadFile', '');
  };

  handleFocusBack = () => {
    this.props.change('isUploadFile', '');
    window.removeEventListener('focus', this.handleFocusBack);
  };

  handleClick = () => {
    window.addEventListener('focus', this.handleFocusBack);
  };

  render() {
    const {
      pickupTypeId,
      rubiconPONbr,
      serviceDate,
      status,
      onCancel,
      pristine,
      isRollOff,
      defaultUom,
      handleSubmit,
    } = this.props;

    const { selectedFileName } = this.state;
    const documentTypeSelectOption = map(DOCUMENT_TYPE, documentTypeOption => ({
      label: documentTypeOption.name,
      value: documentTypeOption.id,
    }));
    const uomOptions = map(UNIT_OF_MEASUREMENT, ({ id, name }) => ({
      label: name,
      value: id,
    }));
    const isFutureDate = moment(serviceDate).isAfter(moment().format('MM/DD/YYYY'));
    const disabledDaysObj = isFutureDate
      ? {
          before: moment(serviceDate, 'MM/DD/YYYY').toDate(),
        }
      : {
          after: TODAY,
          before: moment(serviceDate, 'MM/DD/YYYY').toDate(),
        };
    return (
      <UseIsMobileWidthView
        render={isMobile => (
          <>
            <form onSubmit={handleSubmit} noValidate>
              <Grid margin={isMobile ? 'no' : 'small no no'} multiLine>
                <GridColumn size="12/12">
                  <Text block size="large" weight="medium" margin={isMobile ? 'small no no' : 'small no xSmall no'}>
                    {translate('autoDispatch.confirmService')}
                  </Text>
                </GridColumn>

                <GridColumn size="12/12">
                  {isRollOff && (
                    <Field
                      name={`opportunities.settings${rubiconPONbr}${serviceDate}.completedDate`}
                      component={DatePicker}
                      label={translate('autoDispatch.completedDate')}
                      disabledDays={[disabledDaysObj]}
                      validate={[isRequired, isDateValidValidator]}
                      disabled={status === CONFIRMED_WITHOUT_UPLOAD || status === COMPLETED}
                      margin={isMobile ? 'xxSmall no' : 'small small'}
                    />
                  )}
                </GridColumn>

                <GridColumn size={isMobile ? '12/12' : '6/12'}>
                  <Field
                    name={`opportunities.settings${rubiconPONbr}${serviceDate}.weightTicket`}
                    component={Input}
                    type="text"
                    label={`${translate('autoDispatch.enterAmount')}`}
                    size="small"
                    validate={isRollOff ? [isDecimal] : [isDecimalWithZero]}
                    margin={isMobile ? 'xxSmall no' : 'small small'}
                  />
                </GridColumn>

                <GridColumn size={isMobile ? '12/12' : '6/12'}>
                  {defaultUom ? (
                    <Field
                      name={`opportunities.settings${rubiconPONbr}${serviceDate}.uom`}
                      component={Input}
                      type="text"
                      label={translate('common.unitOfMeasurement')}
                      validate={[isRequired]}
                      margin={isMobile ? 'xxSmall no' : 'small small'}
                      disabled
                    />
                  ) : (
                    <Field
                      name={`opportunities.settings${rubiconPONbr}${serviceDate}.uom`}
                      component={Dropdown}
                      options={uomOptions}
                      label={translate('common.unitOfMeasurement')}
                      validate={[isRequired]}
                      margin={isMobile ? 'xxSmall no' : 'small small'}
                    />
                  )}
                </GridColumn>
                {pickupTypeId !== MISSED_PICKUP && (
                  <>
                    <DesktopWidthView>
                      <GridColumn size="12/12">
                        <HorizontalRule />
                      </GridColumn>
                    </DesktopWidthView>

                    <GridColumn size="12/12">
                      <Text
                        block
                        size={isMobile ? 'large' : 'medium'}
                        weight="medium"
                        margin={isMobile ? 'small no no' : 'small no xSmall no'}
                      >
                        {translate('autoDispatch.uploadDocumentation')}
                      </Text>
                    </GridColumn>

                    <GridColumn size="12/12">
                      <Field
                        name={`opportunities.settings${rubiconPONbr}${serviceDate}.documentType`}
                        component={Dropdown}
                        options={documentTypeSelectOption}
                        label={translate('autoDispatch.documentType')}
                        validate={[isRequired]}
                        margin={isMobile ? 'xxSmall no' : 'small small'}
                      />
                    </GridColumn>

                    {!!selectedFileName && (
                      <GridColumn margin={isMobile ? 'small no no' : undefined} size="12/12">
                        <Text singleLine weight="medium" size="small" margin={isMobile ? 'small no' : 'small small'}>
                          {selectedFileName}
                        </Text>

                        <Button
                          id="clear-image-button"
                          text
                          color="primary"
                          padding="no"
                          margin={isMobile ? 'no small' : undefined}
                          onClick={() => this.clearfileName()}
                        >
                          <ActionButtonTooltip icon="cancelClear" tooltip="clear" />{' '}
                        </Button>
                      </GridColumn>
                    )}

                    <GridColumn marginLeft={isMobile ? undefined : '65px'} size={isMobile ? '12/12' : '10/12'}>
                      <FieldMock
                        name="isUploadFile"
                        value="UploadFile"
                        component={RadioButton}
                        disabled={!!selectedFileName}
                        type="radio"
                        label={translate('common.uploadDocument')}
                        block
                        color="primary"
                        size="small"
                        margin={isMobile ? 'small no' : 'no no xSmall'}
                        onChange={(name: string, value: string) => this.handleChange(name, value)}
                      />
                      <input
                        accept=".jpg,.png,.pdf,.ppt,.xls,.xlsx,.docx,.txt,.doc,.csv"
                        id="fileSelect"
                        onChange={this.onFileInputChange}
                        onClick={this.handleClick}
                        ref={this.uploadFileRef}
                        style={{ display: 'none' }}
                        type="file"
                      />
                    </GridColumn>
                  </>
                )}
              </Grid>

              <Grid centered>
                <GridColumn size="12/12">
                  <ButtonSet margin={isMobile ? 'small no' : 'large'}>
                    <Button type="submit" color="primary" onClick={this.onSave}>
                      {translate('common.save')}
                    </Button>
                    <Button type="button" color="secondary" onClick={() => onCancel(pristine)}>
                      {translate('autoDispatch.close')}
                    </Button>
                  </ButtonSet>
                </GridColumn>
              </Grid>
            </form>
          </>
        )}
      />
    );
  }
}

const formSelector = formValueSelector('documentUpload');

const mapStateToProps = (state: AppState, ownProps: Props) => {
  const initialValues = openDispatchesFormInitialValueSelector(
    state.fleet.serviceConfirmation.opportunities,
    state.fleet.rubiconDispatches.rubiconDispatches,
  );
  return {
    formValues: getFormValues('documentUpload')(state),
    initialValues,
    documentType: formSelector(
      state,
      `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.documentType`,
    ),
    isSaving: state.fleet.serviceConfirmation.isSaving,
    isUploadingDocumentImage: state.fleet.serviceConfirmation.isUploadingDocumentImage,
    weightTicket: formSelector(
      state,
      `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.weightTicket`,
    ),
    uom: formSelector(state, `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.uom`),
    completedDate: formSelector(
      state,
      `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.completedDate`,
    ),
    serviceDate: formSelector(
      state,
      `opportunities.settings${ownProps.rubiconPONbr}${ownProps.serviceDate}.serviceDate`,
    ),
  };
};

const mapDispatchToProps = {
  loadDispatchOpportunitiesCount,
  responseMissedPickup,
  uploadDocumentImage,
  uploadDocument,
  updateServiceConfirmation,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<{}, PropsWithoutReduxForm>({
    form: 'documentUpload',
    enableReinitialize: true,
  })(DocumentUploadForm),
);
