import styled, { css } from 'styled-components';
import { fadeAndSlideInDown } from '../../../../core/styles';
import { sizeMapper } from '../../../../utils/styles';

interface IsOpenProps {
  isOpen?: boolean;
}

export const ToggleContainer = styled.div<{
  margin?: string;
}>`
  padding-top: 9px;
  cursor: pointer;
  margin: ${props => sizeMapper(props.margin, 'no no no xSmall')};
`;

export const DispatchBoardFilterContainer = styled.div<
  IsOpenProps & {
    padding?: string;
  }
>`
  padding: ${props => sizeMapper(props.padding, 'xSmall no no')};
  ${props =>
    props.isOpen
      ? css`
          display: fllex;
          animation: ${fadeAndSlideInDown} 0.3s ease-out;
          width: 100%;
        `
      : css`
          display: none;
        `}
`;
