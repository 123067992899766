
import { isCityAlertFeatureEnabled } from 'src/vendors/ducks/features';
import { CityAlertSetting, CityAlertSettings } from 'src/vendors/interfaces/CityAlertSettings';
import store from '../../../store';

export const getActiveCityAlertTypes = (cityAlertSettings?: CityAlertSettings) => {
  const isCityAlertEnabled = isCityAlertFeatureEnabled(store.getState());

  const activeCityAlertSettings = cityAlertSettings
    ? cityAlertSettings.cityAlertTypes.filter((option: CityAlertSetting) => option.isActive)
    : [];

  const hasCityAlerts = activeCityAlertSettings.length > 0 && isCityAlertEnabled;
  return { activeCityAlertSettings, hasCityAlerts };
};
