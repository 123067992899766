import { http } from '../../core/services/http';

export const saveAddNewWorkOrder = (vendorId: any, divisionId: any, payload: any) => {
  const formData = new FormData();

  formData.append('Customer', payload.Customer);
  formData.append('Location', payload.Location);
  formData.append('ServiceId', payload.ServiceId);
  formData.append('DatePerformed', payload.DatePerformed);
  formData.append('ServiceRequestType', payload.ServiceRequestType);
  formData.append('Amount', payload.Amount);
  formData.append('Notes', payload.Notes);
  formData.append('Files', payload.Files ? payload.Files[0] : null);

  return http
    .post(`/gus/vendors/${vendorId}/divisions/${divisionId}/internal-requests/work-orders`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    });
};

export const submitChangeRequest = (vendorId: any, divisionId: any, payload: any) => {
  const formData = new FormData();
  formData.append('ServiceNumber', payload.ServiceNumber || null);
  formData.append('EquipmentSize', payload.EquipmentSize || null);
  formData.append('EquipmentType', payload.EquipmentType || null);
  formData.append('MaterialType', payload.MaterialType || null);
  formData.append('Frequency', payload.Frequency || null);
  formData.append('Container', payload.Container || null);
  formData.append('Rates', JSON.stringify(payload?.Rates || []));
  formData.append('DayOfService', payload.DayOfService || null);
  formData.append('Notes', payload.Notes || '');
  formData.append('Files', payload.Files ? payload.Files[0] : null);

  return http
    .post(`/gus/vendors/${vendorId}/divisions/${divisionId}/internal-requests/data-correction`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => {
      return response.data;
    });
};
