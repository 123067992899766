import { get, size } from 'lodash-es';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import { useSelector, useDispatch } from 'react-redux';
import Cookie from 'js-cookie';

import { AppState } from '../../../store';
import { BlogPosts, TwitterPosts, FooterMenu } from '..';
import { CreateUserLink, CreateUserPanel, AccountPanelLink } from '../styled';
import { getInitialLocale, getCurrentLanguageId } from '../../../core/services/i18n';
import { getQueryParams } from '../../../utils/services/queryParams';
import { login, loginWithSSOToken, resetLogin } from '../../ducks';
import {
  LoginAreaWrapper,
  LoginFormWrapper,
  LoginPageWrapper,
  RubiconHorizontalLogo,
  ServiceCarouselWrapper,
  SiteWidth,
} from '../styled/LoginPage';
import { NewsComponentContainer, NewsFeedAreaWrapper } from '../styled/News';
import { SelectDefaultVendorModal } from 'src/vendors/components/modals';
import { SELECTED_VENDOR_ID_COOKIE, SELECTED_VENDOR_ID_COOKIE_EXPIRATION } from 'src/common/constants';
import { Text } from '../../../core/components/styled';
import { User, UserLoginData } from 'src/account/ducks/login';
import { Vendor } from 'src/vendors/interfaces/Vendors';
import LoginForm, { LoginFormValues } from '../forms/LoginForm';
import ServiceCarousel from '../ServiceCarousel';
import translate from '../../../core/services/translate';

export default function LoginPage() {
  const dispatch = useDispatch();
  const location = useLocation();
  const params = getQueryParams(location.search);

  const [isTokenAvailable, setIsTokenAvailable] = useState(false);
  const [isVendorModalOpen, setIsVendorModalOpen] = useState(false);
  const [vendorList, setVendorList] = useState([] as Vendor[]);
  const [loginData, setLoginData] = useState({ userRedirectTo: '/workorders' } as UserLoginData);
  const [ssoError, setSsoError] = useState('');

  const { isLoginFailed, isAccountLocked, isLoggingIn, isSSoEnabled } = useSelector(
    (state: AppState) => state.account.login,
  );

  const createUserAccountUrl = (urlPrefix: string) => {
    if (params.autoDispatchToken) return `${urlPrefix}?autoDispatchToken=${params.autoDispatchToken}`;
    else if (params.opportunitiesToken) return `${urlPrefix}?opportunitiesToken=${params.opportunitiesToken}`;
    return urlPrefix;
  };

  const handleLoginSubmit = ({ email, password }: LoginFormValues) => {
    const redirectedFrom = get(location, 'state.from.pathname');
    let redirectTo = '/';

    if (params.autoDispatchToken) {
      redirectTo = `/workorders/${params.autoDispatchToken}`;
    } else if (params.opportunitiesToken) {
      redirectTo = '/opportunities';
    } else {
      redirectTo =
        !/^\/account\/logout/.test(redirectedFrom) && !redirectedFrom?.includes('ssoError')
          ? redirectedFrom
          : '/workorders';
    }

    login(
      email,
      password,
      redirectTo,
    )(dispatch).then((data: User) => {
      const vendorList = data?.vendorList?.vendors;
      const vendorId = data?.vendor?.id;

      if (size(vendorList)) {
        setLoginData({ userEmail: email, userPassword: password, userRedirectTo: redirectTo || '/workorders' });
        setIsVendorModalOpen(true);
        setVendorList(vendorList);
      } else {
        vendorId &&
          Cookie.set(SELECTED_VENDOR_ID_COOKIE, vendorId.toString(), { expires: SELECTED_VENDOR_ID_COOKIE_EXPIRATION });
      }
    });
  };

  useEffect(() => {
    if (params.autoDispatchToken || params.opportunitiesToken) {
      setIsTokenAvailable(true);
    }

    return () => {
      dispatch(resetLogin());
    };
  }, [params.autoDispatchToken, params.opportunitiesToken, dispatch]);

  //is there is a sso token, we need to autoLogin the user
  useEffect(() => {
    if (params.ssoAuth) {
      const redirectedFrom = get(location, 'state.from.pathname');
      const redirectTo = !/^\/account\/logout/.test(redirectedFrom)
        ? redirectedFrom
          ? redirectedFrom
          : '/workorders'
        : '/workorders';

      loginWithSSOToken(
        params.ssoAuth,
        redirectTo,
      )(dispatch).then((data: User) => {
        const vendorList = data?.vendorList?.vendors;
        const vendorId = data?.vendor?.id;

        if (size(vendorList)) {
          setLoginData({ userEmail: data.email, userPassword: '', userRedirectTo: redirectTo });
          setIsVendorModalOpen(true);
          setVendorList(vendorList);
        } else {
          vendorId &&
            Cookie.set(SELECTED_VENDOR_ID_COOKIE, vendorId.toString(), {
              expires: SELECTED_VENDOR_ID_COOKIE_EXPIRATION,
            });
        }
      });
    }
  }, [dispatch, location, params.ssoAuth]);

  //ssoError form the ssoLogin service

  useEffect(() => {
    if (params.ssoError && params.ssoError === 'AuthenticationFailed') {
      if (params.email) {
        const error = translate('account.ssoLoginFailWithEmail', { email: params.email });
        setSsoError(error);
      } else setSsoError(translate('account.ssoLoginFailWithNoEmail'));
      // remove the ssoError from the url
      window.history.replaceState({}, '', location.pathname);
    } else if (ssoError) {
      setSsoError('');
    }
  }, [location.pathname, params.email, params.ssoError, ssoError]);

  const languageId = getCurrentLanguageId();
  if (languageId && languageId !== getInitialLocale()) {
    window.location.reload();
    return null;
  }

  const onDefaultVendorSelected = () => {};

  return (
    <LoginPageWrapper>
      <SiteWidth>
        <RubiconHorizontalLogo />
        <LoginAreaWrapper>
          <ServiceCarouselWrapper>
            <ServiceCarousel interval={5000} />
          </ServiceCarouselWrapper>
          <LoginFormWrapper isLoading={isLoggingIn && !isVendorModalOpen}>
            <LoginForm
              isLoginFailed={isLoginFailed}
              isAccountLocked={isAccountLocked}
              isSSoEnabled={isSSoEnabled}
              ssoError={ssoError}
              onSubmit={handleLoginSubmit}
            />
            {isTokenAvailable && (
              <CreateUserPanel>
                <Text block size="large" weight="medium" align="center" margin="no no xSmall">
                  {translate('common.or')}
                </Text>
                <CreateUserLink>
                  <AccountPanelLink isCreateUser to={createUserAccountUrl('/account/create-user-account')}>
                    {translate('account.createUserAccount')}
                  </AccountPanelLink>
                </CreateUserLink>
              </CreateUserPanel>
            )}

            {isVendorModalOpen && (
              <SelectDefaultVendorModal
                loginData={loginData}
                onDefaultVendorSelected={onDefaultVendorSelected}
                shouldRefreshPage={false}
                vendorList={vendorList}
                ssoAuth={params.ssoAuth}
              />
            )}
          </LoginFormWrapper>
        </LoginAreaWrapper>
      </SiteWidth>
      <NewsComponentContainer>
        <NewsFeedAreaWrapper>
          <SiteWidth>
            <BlogPosts />
          </SiteWidth>
        </NewsFeedAreaWrapper>
        <SiteWidth>
          <TwitterPosts />
        </SiteWidth>
      </NewsComponentContainer>
      <FooterMenu />
    </LoginPageWrapper>
  );
}
