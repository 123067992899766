import { combineReducers } from 'redux';

import { reducer as geoFenceFilterOptionsReducer } from './geoFenceFilterOptions';
import { reducer as geoFencesReducer } from './geoFences';
import { reducer as mapControlsReducer } from './mapControls';
import { reducer as pinOnMapGeoFencesReducer } from './pinOnMapGeoFences';
import { reducer as routeReducer } from './route';
import { reducer as routeGeoFenceReducer } from './routeGeoFence';
import { routeMapSettingsReducer } from './routeMapSettings';
import { reducer as routeStopsReducer } from './routeStops';
import { reducer as routeSummaryReducer } from './routeSummary';
import { reducer as routeTemplateReducer } from './routeTemplate';
import { routeTemplateBuilderReducer } from './routeTemplateBuilder';
import { reducer as serviceZonesReducer } from './serviceZones';
import { reducer as stopMapDetailsReducer } from './stopMapDetails';
import { reducer as strobeImagesReducer } from './strobeImages';
import { reducer as supervisorsReducer } from './supervisors';
import { reducer as travelPathReducer } from './travelPath';
import { reducer as travelPathBuildAndEditReducer } from './travelPathBuildAndEdit';
import { reducer as videoRequestReducer } from './videoRequest';

export {
  cancelBatchedLocationsLoading, COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTE,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE,
  COMPLETE_SAVE as COMPLETE_SAVE_ROUTE,
  COMPLETE_BATCH_TRANSFER as COMPLETE_TRANSFER_STOPS, completedStopCountSelector,
  exportRoute,
  exportYRoute,
  loadRoute,
  loadYRoute,
  resetRoute,
  routeLocationAddressChange,
  routeLocationsByIdSelector,
  saveRoute,
  transferRouteLocations
} from './route';
export {
  COMPLETE_EXPORT as COMPLETE_EXPORT_ROUTE_TEMPLATE,
  COMPLETE_LOAD as COMPLETE_LOAD_ROUTE_TEMPLATE,
  COMPLETE_SAVE as COMPLETE_SAVE_ROUTE_TEMPLATE,
  COMPLETE_BATCH_TRANSFER as COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE,
  exportRouteTemplate,
  loadRouteTemplate,
  loadRouteTemplateLocationsBatched,
  loadRouteTemplateOptimized,
  resetRouteTemplate,
  routeTemplateLocationAddressChange,
  saveRouteTemplate,
  transferRouteTemplateLocations
} from './routeTemplate';
export { loadClosestStrobeImage, loadNextStrobeImages, loadPrevStrobeImages, loadStrobeImages } from './strobeImages';

export {
  COMPLETE_DELETE as COMPLETE_DELETE_SERVICE_ZONES,
  COMPLETE_LOAD as COMPLETE_LOAD_SERVICE_ZONES,
  COMPLETE_SAVE as COMPLETE_SAVE_SERVICE_ZONES,
  deleteServiceZone,
  loadServiceZones,
  resetServiceZones,
  saveServiceZone
} from './serviceZones';

export { loadGeoFenceBasicList, loadGeoFences, resetGeoFences } from './geoFences';

export { loadGeoFenceFilterOptions, resetGeoFenceFilterOptions } from './geoFenceFilterOptions';

export { loadPinOnMapGeoFences, resetPinOnMapGeoFences } from './pinOnMapGeoFences';

export { loadRouteSummary, loadSnowSweeperRouteSummary } from './routeSummary';

export {
  loadRouteLocationDetails,
  loadRouteStops,
  loadRouteStopsForMap,
  loadRouteStopsForSequence,
  loadUnscheduledStops,
  loadWasteAuditNotes,
  resetIsLoaded,
  resetRouteStops,
  updateRouteStops
} from './routeStops';

export { loadStopMapDetails, resetStopMapDetails } from './stopMapDetails';

export {
  toggleSpeedColor,
  clearRouteMapSelectedFeature,
  resetRouteMapControls,
  setDebugMode,
  setRouteMapSelectedFeature,
  setRouteMapViewport,
  setTriggerMapViewport
} from './mapControls';

export { loadVideoDownloadDetails, loadVideoRequests, loadVideoStatuses, saveVideoRequest } from './videoRequest';

export {
  downloadTravelPathGeoJsonFile,
  loadTravelPathStatusDetails,
  resetTravelPathDetails, resetTravelPathDetailsForBuildOrEdit, setIsTravelPathLegendOpen,
  setShowTravelPath,
  triggerTravelPath
} from './travelPath';

export {
  applyEditsToTravelPath, extendTravelPathLock, finishTravelPathBuildOrEdit,
  initializeTravelPathBuildOrEdit,
  loadGeoFencesForTravelPathModal,
  loadHaulerLocationsForTravelPathModal,
  loadRouteSegmentsForTravelPathModal,
  loadRouteStopsForTravelPathModal,
  loadRouteVehicleInformation,
  loadRouteVehiclePositionAndBreadcrumb,
  loadStreetNetworkForTravelPathModal,
  loadTravelPathBuilderFilters,
  loadTravelPathForBuildOrEdit,
  publishEditsToTravelPath, resetMapFeatures, resetStartEndTravelPathEdit, resetTravelPathBuildEdit, resetTravelPathBuildTracer, setIsTravelPathEditLegendOpen,
  setSelectedSegment, travelPathBuilderDoTracing
} from './travelPathBuildAndEdit';

export const reducer = combineReducers({
  geoFence: routeGeoFenceReducer,
  geoFences: geoFencesReducer,
  geoFencesFilterOptions: geoFenceFilterOptionsReducer,
  mapControls: mapControlsReducer,
  pinOnMapGeoFences: pinOnMapGeoFencesReducer,
  route: routeReducer,
  routeMapSettings: routeMapSettingsReducer,
  routeStops: routeStopsReducer,
  routeSummary: routeSummaryReducer,
  routeTemplate: routeTemplateReducer,
  routeTemplateBuilder: routeTemplateBuilderReducer,
  serviceZones: serviceZonesReducer,
  stopMapDetails: stopMapDetailsReducer,
  strobeImages: strobeImagesReducer,
  supervisors: supervisorsReducer,
  travelPath: travelPathReducer,
  travelPathBuildAndEdit: travelPathBuildAndEditReducer,
  videoRequest: videoRequestReducer,
});
