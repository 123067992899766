import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';

import { createNotification } from '../../core/ducks';
import { getAuthToken } from './auth';
import { http, httpInsightsReporting } from '../../core/services/http';
import history from '../../core/services/history';
import store from '../../store';
import translate from '../../core/services/translate';

const notificationTimeout = 6000;
let alreadyShownPasswordExpirationNotification = false;

const requestInterceptor = (config: AxiosRequestConfig) => {
  const authToken = getAuthToken();

  if (authToken) {
    config.headers = { ...config.headers, Authorization: authToken };
  }

  return config;
};

const requestErrorInterceptor = (error: any) => Promise.reject(error);

const responseInterceptor = (response: AxiosResponse) => response;

const responseErrorInterceptor = (error: AxiosError) => {
  if (error && error.response && error.response.status === 401) {
    const { headers } = error.response;

    if (headers.reason === 'password_expired') {
      if (!alreadyShownPasswordExpirationNotification) {
        alreadyShownPasswordExpirationNotification = true;

        createNotification(translate('account.passwordChangeRequired'), 'alert', notificationTimeout)(store.dispatch);
        history.push({
          pathname: '/account/logout',
          search: '?passwordChangeRequired=true',
          state: { from: history.location },
        });
        setTimeout(() => {
          alreadyShownPasswordExpirationNotification = false;
        }, notificationTimeout);
      }
    } else {
      history.push({ pathname: '/account/logout', state: { from: history.location } });
    }
  }

  return Promise.reject(error);
};

const registerAuthInterceptor = () => {
  http.interceptors.request.use(requestInterceptor as any, requestErrorInterceptor);
  http.interceptors.response.use(responseInterceptor, responseErrorInterceptor);

  httpInsightsReporting.interceptors.request.use(requestInterceptor as any, requestErrorInterceptor);
  httpInsightsReporting.interceptors.response.use(responseInterceptor, responseErrorInterceptor);
};

export default registerAuthInterceptor;
