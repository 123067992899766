import { PanelSearchInput } from 'src/core/components/styled';
import styled from 'styled-components';

export const RoutePlannerVehiclesAndDriversSearchFormWrapper = styled.div`
  padding: 2px;
  background: rgba(180, 180, 196, 0.1);
  border: 1px solid rgba(119, 119, 140, 0.2);
  box-shadow: 0px 0px 0px #d8d8d8;
  color: ${props => props.theme.grayBase};
  padding: 0 10px;
  width: 100%;

  svg {
    margin-right: 5px;
    width: 15px;
    height: 15px;
  }

  input {
    font-size: 12px;
    width: 100%;

    &::placeholder {
      font-size: 14px;
    }
  }
`;

export const SearchDriverOrVehicleWrapper = styled.div`
  ${PanelSearchInput} {
    &::placeholder {
      white-space: pre-line;
      position: relative;
      top: -7px;
      font-size: 14px;
    }
  }
`;
