
import {
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_FUELING_STATION,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_LANDFILL_OTHER,
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_TRUCK_YARD,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION
} from 'src/common/constants';
import translate from 'src/core/services/translate';

export const HAULER_LOCATION_FILTERS = [
  {
    id: CUSTOMER_SUBTYPE_TRUCK_YARD,
    label: translate('facilities.facilitySubTypes.truckYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_CONTAINER_YARD,
    label: translate('facilities.facilitySubTypes.containerYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL,
    label: translate('facilities.facilitySubTypes.landfill'),
  },
  {
    id: CUSTOMER_SUBTYPE_FUELING_STATION,
    label: translate('facilities.facilitySubTypes.fuelingStation'),
  },
];

export const SNOW_SWEEPER_HAULER_LOCATION_FILTERS = [
  {
    id: CUSTOMER_SUBTYPE_TRUCK_YARD,
    label: translate('facilities.facilitySubTypes.truckYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_CONTAINER_YARD,
    label: translate('facilities.facilitySubTypes.containerYard'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL,
    label: translate('facilities.facilitySubTypes.landfill'),
  },
  {
    id: CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
    label: translate('facilities.facilitySubTypes.saltChemicalFillingStation'),
  },
  {
    id: CUSTOMER_SUBTYPE_FUELING_STATION,
    label: translate('facilities.facilitySubTypes.fuelingStation'),
  },
  {
    id: CUSTOMER_SUBTYPE_LANDFILL_OTHER,
    label: translate('facilities.facilitySubTypes.otherOperationalFacility'),
  },
  {
    id: CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
    label: translate('facilities.facilitySubTypes.waterFillingStation'),
  },
];
