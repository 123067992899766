import { Equipment, Material, ZipCode } from "src/haulerProfile/interfaces/ServiceArea";
import { Feature } from "src/haulerProfile/interfaces/ZipCodeBoundaries";
import { ZipAreaPopupContent } from "./Styles";

export const getMapBounds = (points: google.maps.LatLngLiteral[]): google.maps.LatLngBounds => {
  
  let north = -Infinity;
  let south = Infinity;
  let east = -Infinity;
  let west = Infinity;

  points.forEach((point) => {
    if (point.lat > north) north = point.lat;
    if (point.lat < south) south = point.lat;
    if (point.lng > east) east = point.lng;
    if (point.lng < west) west = point.lng;
  });
  const sw = {
    lat: south,
    lng: west
  } as google.maps.LatLngLiteral;
  const ne = {
    lat: north,
    lng: east
  } as google.maps.LatLngLiteral;
  return new google.maps.LatLngBounds(sw, ne);
}

export const parseFeature = (feature: Feature, zipCodes: {
    equipments: Equipment[];
    materials: Material[];
    zipCode: ZipCode;
  }[], equipmentTypes: string, equipmentSizes?: string[]): {
  title: string;
  text: string | React.ReactNode;
  points: google.maps.LatLngLiteral[];
}[] =>  {
  const polygons: {
    title: string;
    text: string | React.ReactNode;
    points: google.maps.LatLngLiteral[];
}[] = [];

  const parse = (coord: any[]) => {
    if (Array.isArray(coord) && coord.length > 0) {
      if (coord[0].length === 2 && typeof coord[0][0] === 'number' && typeof coord[0][1] === 'number') {
        // This is a Polygon
        const points: google.maps.LatLngLiteral[] = coord.map(c => ({ lat: c[1], lng: c[0] }));
        const zipCode = zipCodes.find(zipCode => zipCode.zipCode.zipCode === feature.properties.zip);
        polygons.push({ 
          text: zipCode ? ZipAreaPopupContent(zipCode, equipmentTypes, equipmentSizes) : null,
          title: feature.properties.zip,
          points 
        });
      } else {
        // This is a nested array (MultiPolygon), go deeper
        for (const nestedCoord of coord) {
          parse(nestedCoord);
        }
      }
    }
  }
  if (feature.type === 'Feature') {
    parse(feature.geometry.coordinates);
  }
  return polygons;
}
