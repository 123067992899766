import store from '../../store';
import {
  ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS,
  ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS,
} from '../constants';
import { hasPermissionSelector } from '../ducks';

const selector = hasPermissionSelector as any;

export const hasPermission = (permission: string) => {
  const state = store.getState();

  return Boolean(selector(state.account.permissions, permission));
};

export const checkIfViewOnly = () =>
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS) &&
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS);

export const checkIfSupport = () =>
  !hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_SCHEDULED_JOBS) &&
  hasPermission(ROUTES_DISPATCH_BOARD_MANAGE_UNSCHEDULED_JOBS);
