import GoogleAnalyticsGtag, { trackEvent, trackPageView } from '@redux-beacon/google-analytics-gtag';
import { LOCATION_CHANGE } from 'connected-react-router';
import { pascalize } from 'humps';
import { get, size } from 'lodash-es';
import { createMiddleware } from 'redux-beacon';

import {
  ADD_SUSPENDED_LOCATION,
  COMPLETE_DELETE_CUSTOMER,
  COMPLETE_DELETE_LOCATION,
  COMPLETE_DELETE_SERVICE,
  COMPLETE_LOAD_CUSTOMER,
  COMPLETE_LOAD_CUSTOMERS,
  COMPLETE_LOAD_SUSPENDED_LOCATIONS,
  COMPLETE_SAVE_CUSTOMER,
  COMPLETE_SAVE_LOCATION,
  COMPLETE_SAVE_SERVICE,
  COMPLETE_SAVE_SUSPENDED_LOCATIONS,
  EDIT_SUSPENDED_LOCATION,
  SEARCH_LOCATIONS,
} from '../../customers/ducks';
import {
  COMPLETE_LOAD_VEHICLE,
  COMPLETE_VEHICLE_SAVE,
} from '../../fleet/ducks';
import { COMPLETE_LOAD_OPPORTUNITY } from '../../opportunity/ducks';
import {
  COMPLETE_DELETE_SERVICE_ZONES,
  COMPLETE_EXPORT_ROUTE,
  COMPLETE_EXPORT_ROUTE_TEMPLATE,
  COMPLETE_LOAD_ROUTE,
  COMPLETE_LOAD_ROUTE_TEMPLATE,
  COMPLETE_LOAD_SERVICE_ZONES,
  COMPLETE_SAVE_ROUTE,
  COMPLETE_SAVE_ROUTE_TEMPLATE,
  COMPLETE_SAVE_SERVICE_ZONES,
  COMPLETE_TRANSFER_STOPS,
  COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE,
} from '../../routes/ducks';
import { AppState } from '../../store';
import {
  COMPLETE_CHANGE_PASSWORD,
  COMPLETE_DELETE_USER,
  COMPLETE_LOAD_USERS,
  COMPLETE_SAVE_USER,
  COMPLETE_SAVE_VENDOR,
  VIEW_ADVANCED_PROFILE,
} from '../../vendors/ducks';
import { getBaseUrl } from './environment';

// the trackEvent function _needs_ to return an object with these keys
const emptyGATrack = {
  category: 'Discard',
  action: '',
};

interface Action {
  [key: string]: any;
}

const trackEventType = (category: string, action: string, label: string) =>
  trackEvent(() => ({
    category,
    action,
    label,
  }));

const trackCustomersSearchTermChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const searchTerm = get(nextState, 'form.customers.values.searchTerm');
  return !!searchTerm
    ? {
        category: 'Customers',
        action: 'Filter',
        label: 'Search',
      }
    : emptyGATrack;
});

const trackCustomersCustomerTypeChange = trackEvent((_action: Action, _prevState: AppState, nextState: AppState) => {
  const customerTypeId = get(nextState, 'form.customers.values.customerTypeId');
  return !!customerTypeId
    ? {
        category: 'Customers',
        action: 'Filter',
        label: 'Customer Type',
      }
    : emptyGATrack;
});

const trackPageHistory = trackPageView(action => ({
  path: action.payload.pathname,
  location: `${getBaseUrl()}${action.payload.pathname}`,
  title: '',
}));

const eventsMapper = (action: Action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return [trackPageHistory];
    case COMPLETE_LOAD_ROUTE:
      return trackEventType('Routes > Tracker', 'Edit', 'Edit Route');
    case COMPLETE_EXPORT_ROUTE:
      return trackEventType('Routes > Tracker > View', 'Export', 'Route Details Export');
    case COMPLETE_SAVE_ROUTE:
      return trackEventType(
        'Routes > Tracker > Edit',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Route`,
      );
    case COMPLETE_TRANSFER_STOPS:
      return trackEventType('Routes > Tracker > Edit', 'Transfer', 'Transfer Route Stops');
    case COMPLETE_LOAD_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner', 'View', 'Route Template');
    case COMPLETE_EXPORT_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner > View', 'Export', 'Export Route Template');
    case COMPLETE_SAVE_ROUTE_TEMPLATE:
      return trackEventType(
        'Routes > Planner > Edit',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Route Template`,
      );
    case COMPLETE_TRANSFER_STOPS_ROUTE_TEMPLATE:
      return trackEventType('Routes > Planner > Edit', 'Transfer', 'Transfer Route Template Stops');
    case COMPLETE_LOAD_SERVICE_ZONES:
      return trackEventType('Routes > Planner', 'View', 'Service Zones');
    case COMPLETE_DELETE_SERVICE_ZONES:
      return trackEventType('Routes > Planner > Service Zones', 'Delete', 'Delete Service Zone');
    case COMPLETE_SAVE_SERVICE_ZONES:
      return trackEventType(
        'Routes > Planner > Service Zones',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Service Zone`,
      );
    case COMPLETE_LOAD_CUSTOMERS: {
      const trackers = [
        trackEventType('Customers', 'Search', 'Customers List'),
        trackCustomersSearchTermChange,
        trackCustomersCustomerTypeChange,
      ];
      if (!!size(action.sortedBy)) trackers.push(trackEventType('Customers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_DELETE_CUSTOMER:
      return trackEventType('Customers', 'Delete', 'Delete Customer');
    case COMPLETE_LOAD_CUSTOMER:
      return trackEventType('Customer > Details', 'View', 'View Customer Details Page');
    case COMPLETE_SAVE_CUSTOMER:
      return trackEventType(
        'Customer > Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Customer`,
      );
    case COMPLETE_DELETE_LOCATION:
      return trackEventType('Customers > Details', 'Delete', 'Delete Customer Location Button');
    case COMPLETE_SAVE_LOCATION:
      return trackEventType(
        'Customers > Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Customer Location Button`,
      );
    case SEARCH_LOCATIONS:
      return trackEventType('Customers > Details', 'Search', 'Search Customer Locations');
    case COMPLETE_DELETE_SERVICE:
      return trackEventType('Customers > Location Details', 'Delete', 'Delete Service Button');
    case COMPLETE_SAVE_SERVICE:
      return trackEventType(
        'Customers > Location Details',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Service Button`,
      );
    case COMPLETE_LOAD_SUSPENDED_LOCATIONS: {
      const trackers = [trackEventType('Customers > Suspended Locations', 'Search', 'Suspended Locations List')];
      if (!!size(action.sortedBy)) trackers.push(trackEventType('Customers', 'Sort', `${pascalize(action.sortedBy)}`));
      return trackers;
    }
    case COMPLETE_SAVE_SUSPENDED_LOCATIONS:
      return trackEventType('Customers > Suspended Locations', 'Save', 'Save Suspended Locations Button');
    case ADD_SUSPENDED_LOCATION:
      return trackEventType('Customers > Suspended Locations', 'Add', 'Add Suspended Locations Button');
    case EDIT_SUSPENDED_LOCATION:
      return trackEventType('Customers > Suspended Locations', 'Edit', 'Edit Suspended Locations Button');
    case COMPLETE_LOAD_VEHICLE:
      return trackEventType('Fleet > Vehicles', 'Edit', 'Edit Vehicle Button');
    case COMPLETE_VEHICLE_SAVE:
      return trackEventType(
        'Fleet > Vehicles',
        action.saveType === 'Update' ? 'Edit' : 'Add',
        `${action.saveType} Vehicle Button`,
      );

    case COMPLETE_LOAD_USERS:
      return trackEventType('Account Settings', 'View', 'Manage Users');
    case COMPLETE_DELETE_USER:
      return trackEventType('Account Settings', 'Delete', 'Delete User');
    case COMPLETE_SAVE_USER:
      return trackEventType('Account Settings', action.saveType, `${action.saveType} User`);
    case COMPLETE_CHANGE_PASSWORD:
      return trackEventType('Account Settings', 'Edit', 'Change Password Button');
    case COMPLETE_SAVE_VENDOR:
      return trackEventType('Account Settings', 'Edit', 'Edit Vendor Info Button');
    case VIEW_ADVANCED_PROFILE:
      return trackEventType('Account Settings', 'View', 'Advanced Profile');
    case COMPLETE_LOAD_OPPORTUNITY:
      return trackEventType('Opportunities > Current Opportunities', 'View', 'View Page');
    default:
      return [];
  }
};

const ga = GoogleAnalyticsGtag('UA-45333213-4');

const gaMiddleware = createMiddleware(eventsMapper, ga);

export default gaMiddleware;
