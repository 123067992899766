import update from 'immutability-helper';
import {
  doLoadpickupFrequencyTypes,
} from '../services/loadPickupFrequencyTypes';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/pickupFrequencyTypes/START_LOAD';
const COMPLETE_LOAD = 'common/pickupFrequencyTypes/COMPLETE_LOAD';
const COMPLETE_LOAD_MONTHLY_SERVICES_YEARLY = 'common/pickupFrequencyTypes/COMPLETE_LOAD_MONTHLY_SERVICES_YEARLY';
const COMPLETE_LOAD_MONTHLY_SERVICES_MONTHLY = 'common/pickupFrequencyTypes/COMPLETE_LOAD_MONTHLY_SERVICES_MONTHLY';
const COMPLETE_LOAD_MONTHLY_SERVICES_WEEKLY = 'common/pickupFrequencyTypes/COMPLETE_LOAD_MONTHLY_SERVICES_WEEKLY';
const FAIL_LOAD = 'common/pickupFrequencyTypes/FAIL_LOAD';
const FAIL_LOAD_MONTHLY_SERVICES = 'common/pickupFrequencyTypes/FAIL_LOAD_MONTHLY_SERVICES';
const RESET = 'common/pickupFrequencyTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  pickupFrequencyTypes: undefined,
  monthlyServicesYearlyFrequency: undefined,
  monthlyServicesMonthlyFrequency: undefined,
  monthlyServicesWeeklyFrequency: undefined,
};

interface PickupFrequencyState {
  isLoading: boolean;
  pickupFrequencyTypes?: TechnicalType[];
  monthlyServicesYearlyFrequency?: TechnicalType[];
  monthlyServicesMonthlyFrequency?: TechnicalType[];
  monthlyServicesWeeklyFrequency?: TechnicalType[];
}

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          pickupFrequencyTypes: action.pickupFrequencyTypes,
        },
      });

    case COMPLETE_LOAD_MONTHLY_SERVICES_YEARLY:
      return update(state, {
        $merge: {
          isLoading: false,
          monthlyServicesYearlyFrequency: action.monthlyServicesYearlyFrequency,
        },
      });

    case COMPLETE_LOAD_MONTHLY_SERVICES_MONTHLY:
      return update(state, {
        $merge: {
          isLoading: false,
          monthlyServicesMonthlyFrequency: action.monthlyServicesMonthlyFrequency,
        },
      });

    case COMPLETE_LOAD_MONTHLY_SERVICES_WEEKLY:
      return update(state, {
        $merge: {
          isLoading: false,
          monthlyServicesWeeklyFrequency: action.monthlyServicesWeeklyFrequency,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          pickupFrequencyTypes: undefined,
        },
      });

    case FAIL_LOAD_MONTHLY_SERVICES:
      return update(state, {
        $merge: {
          isLoading: false,
          monthlyServicesYearlyFrequency: undefined,
          monthlyServicesMonthlyFrequency: undefined,
          monthlyServicesWeeklyFrequency: undefined,
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (pickupFrequencyTypes: TechnicalType[]) => ({
  type: COMPLETE_LOAD,
  pickupFrequencyTypes,
});


const failLoad = () => ({
  type: FAIL_LOAD,
});


type Dispatch = ThunkDispatch<PickupFrequencyState, any, AnyAction>;

export const loadPickupFrequencyTypes = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadPickupFrequencyTypesPromise = doLoadpickupFrequencyTypes();
  loadPickupFrequencyTypesPromise
    .then(pickupFrequencyTypes => dispatch(completeLoad(pickupFrequencyTypes)))
    .catch(() => dispatch(failLoad()));
  return loadPickupFrequencyTypesPromise;
};

export const resetPickupFrequencyTypes = () => ({
  type: RESET,
});
