import React, { Fragment, PureComponent } from 'react';

import { connect } from 'react-redux';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { map } from 'lodash-es';

import { AppState } from '../../../store';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
} from '../../../core/components/styled';
import { Checkbox, Input, Dropdown } from '../../../core/components';
import { Districts } from '../../interfaces/DivisionAccess';
import { gusIdSelector } from '../../ducks';
import { isRequired, maxLength50, isEmail } from '../../../utils/services/validator';
import { isSuperAdminSelector } from '../../../account/ducks';
import { LanguageOptions, UserVendors } from '../../interfaces/UserEditor';
import { USER_ROLES } from '../../constants';
import DivisionAccessModalResolver from '../modals/DivisionAccessModalResolver';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import translate from '../../../core/services/translate';
import userEditorFormInitialValuesSelector from '../../services/userEditorFormInitialValuesSelector';
import VendorAccessModalResolver from '../modals/VendorAccessModalResolver';
import { UserType } from 'src/common/interfaces/UserType';
import { isSingleSignOnFeatureEnabled } from 'src/vendors/ducks/features';

const userRoles = map(USER_ROLES, ({ name, id }) => ({
  label: name,
  value: id,
}));

interface ComponentProps {
  assignedVendors: UserVendors[];
  availableVendors: UserVendors[];
  districts: Districts[];
  gusId?: string;
  isAllAssignedVendorsRemoved: boolean;
  isSuperAdmin?: boolean;
  isSingleSignOnEnabled: boolean;
  isUserSuperAdmin: boolean;
  languageOptions: LanguageOptions[];
  onCancel: (pristine: boolean) => void;
  userId: string;
  userTypes: UserType[];
}

interface State {
  isDivisionModalOpen: boolean;
  isVendorAccessModalOpen: boolean;
}

type Props = ComponentProps & InjectedFormProps<any, ComponentProps>;

class UserEditorForm extends PureComponent<Props, State> {
  state = {
    isDivisionModalOpen: false,
    isVendorAccessModalOpen: false,
    isDefaultFiltersModalOpen: false,
  };

  handleDivisionAccess = () => {
    this.setState({ isDivisionModalOpen: true });
  };

  handleDivisionClose = () => {
    this.setState({ isDivisionModalOpen: false });
  };

  handleVendorAccess = () => {
    this.setState({ isVendorAccessModalOpen: true });
  };

  handleVendorAccessClose = () => {
    this.setState({ isVendorAccessModalOpen: false });
  };

  render() {
    const {
      assignedVendors,
      availableVendors,
      districts,
      gusId,
      handleSubmit,
      isAllAssignedVendorsRemoved,
      isSuperAdmin,
      isSingleSignOnEnabled,
      isUserSuperAdmin,
      languageOptions: languageOptionsArray,
      onCancel,
      pristine,
      userId,
      userTypes,
    } = this.props;
    const { isDivisionModalOpen, isVendorAccessModalOpen } = this.state;

    const languageOptions = map(languageOptionsArray, ({ name, id }) => ({
      label: name,
      value: id,
    }));

    const userTypesOptions = map(userTypes, ({ name, id }) => ({
      label: name,
      value: id,
    }));

    return (
      <Fragment>
        <ModalClose onClick={() => onCancel(pristine)}>
          <ModalCloseIcon />
        </ModalClose>
        <form onSubmit={handleSubmit} noValidate>
          <PanelSection padding="small xSmall no">
            <Grid multiLine>
              <GridColumn size="12/12">
                <Field
                  name="name"
                  component={Input}
                  label={translate('common.name')}
                  validate={[isRequired, maxLength50]}
                  readOnly={isSingleSignOnEnabled}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  name="email"
                  component={Input}
                  label={translate('common.email')}
                  validate={[isRequired, isEmail]}
                  readOnly={isSingleSignOnEnabled}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  name="roleId"
                  component={Dropdown}
                  margin="no"
                  options={userRoles}
                  label={translate('vendors.role')}
                  validate={[isRequired]}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  name="userTypeId"
                  component={Dropdown}
                  margin="small no"
                  options={[{ label: translate('common.userTypes.none'), value: -1 }, ...userTypesOptions]}
                  label={translate('common.userTypes.userType')}
                  validate={[isRequired]}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  name="languageId"
                  component={Dropdown}
                  margin="small no"
                  options={languageOptions}
                  label={translate('account.languagePreferences')}
                  validate={[isRequired]}
                />
              </GridColumn>
              <GridColumn size="12/12">
                <Field
                  block
                  name="isAccountActive"
                  component={Checkbox}
                  label={translate('common.active')}
                  margin="small no no no"
                  disabled={isSingleSignOnEnabled}
                />
              </GridColumn>

              {isSuperAdmin && (
                <>
                  <GridColumn size="12/12">
                    <Field
                      block
                      name="displayInferenceAudit"
                      component={Checkbox}
                      label={translate('inferenceAudit.displayInferenceAudit')}
                      margin="small no no no"
                    />
                  </GridColumn>

                  <GridColumn size="4/12" padding="medium no no xSmall">
                    <Button
                      size="small"
                      type="button"
                      color="primary"
                      onClick={this.handleVendorAccess}
                      disabled={isUserSuperAdmin}
                    >
                      {translate('account.vendorAccess')}
                    </Button>
                  </GridColumn>
                </>
              )}

              <GridColumn
                size="4/12"
                padding={isSuperAdmin ? 'medium xSmall no no' : 'medium xSmall no xSmall'}
                align={isSuperAdmin ? 'center' : 'left'}
              >
                {gusId && (
                  <Button size="small" type="button" color="primary" onClick={this.handleDivisionAccess}>
                    {translate('account.divisionAccess')}
                  </Button>
                )}
              </GridColumn>

              <GridColumn size="12/12">
                <ButtonSet margin="large auto no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </PanelSection>
        </form>
        {isDivisionModalOpen && (
          <DivisionAccessModalResolver districts={districts} userId={userId} closeModal={this.handleDivisionClose} />
        )}
        {isVendorAccessModalOpen && (
          <VendorAccessModalResolver
            isAllAssignedVendorsRemoved={isAllAssignedVendorsRemoved}
            assignedVendors={assignedVendors}
            availableVendors={availableVendors}
            userId={userId}
            closeModal={this.handleVendorAccessClose}
          />
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state: AppState, { userId }: any) => ({
  assignedVendors: state.vendors.vendorAccess.assignedUserVendors,
  availableVendors: state.vendors.vendorAccess.availableVendors,
  districts: state.vendors.users.districts,
  gusId: gusIdSelector(state),
  initialValues: userEditorFormInitialValuesSelector(state.vendors.users, userId),
  isAllAssignedVendorsRemoved: state.vendors.vendorAccess.isAllAssignedVendorsRemoved,
  isSuperAdmin: isSuperAdminSelector(state.account.login),
  isSingleSignOnEnabled: isSingleSignOnFeatureEnabled(state),
});

export default connect(mapStateToProps)(
  reduxForm<any, ComponentProps>({
    form: 'userEditor',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(UserEditorForm),
);
