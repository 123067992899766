import { push } from 'connected-react-router';
import { Resizable } from 're-resizable';
import React, { useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { getFormValues } from 'redux-form';

import { camelize } from 'humps';
import { find } from 'lodash-es';
import MapComponent, { MarkerItem } from 'src/common/components/googleMap/MapComponent';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from 'src/common/components/styled';
import { TechnicalType } from 'src/common/interfaces/TechnicalType';
import { MapDragHandle, Pagination, Table } from 'src/core/components';
import {
  Button,
  Grid,
  GridColumn,
  IconButton,
  IconButtonIcon,
  Message,
  Panel,
  PanelSection,
  PanelSectionGroup,
  Text,
} from 'src/core/components/styled';
import { LIMIT_PER_PAGE, PAGE } from 'src/core/constants';
import { MAX_LIMIT } from 'src/core/constants/pagination';
import translate from 'src/core/services/translate';
import RubiconServicesForm from 'src/customers/components/forms/RubiconServicesForm';
import { ACTIVE, ON_HOLD } from 'src/customers/constants/serviceStatuses';
import { exportRubiconServices, loadRubiconServices, resetRubiconServices } from 'src/customers/ducks/rubiconServices';
import { TopToggleButtonsContainer, TopToggleIconButton } from 'src/fleet/components/styled/Containers';
import { CONTAINERS_MAP_ICONS_BY_ID, MULTIPLE } from 'src/fleet/constants/containersMap';
import { clearRouteMapSelectedFeature, setRouteMapSelectedFeature } from 'src/routes/ducks';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { AppState } from 'src/store';
import { usePrevious } from 'src/utils/hooks';
import { createUrl, getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import { RubiconServicesMapLegend } from '../../../common/components/googleMap/RubiconServicesMapLegend';
import RubiconServicesTooltipResolver from '../../../common/components/googleMap/RubiconServicesTooltipResolver';
import { RubiconServicesTableRow } from './rubiconServices/RubiconServicesTableRow';

const rubiconServicesTableCells = [
  {
    name: 'container',
    label: ``,
    width: '18%',
  },
  { name: 'customerInfo', label: translate(`dispatchBoard.exceptionsDispatcher.customerInfo`), width: '20%' },
  { name: 'equipment', label: translate('common.equipmentType'), width: '16%' },
  { name: 'service', label: translate('common.service'), width: '18%' },
  {
    name: 'rates',
    label: translate('common.rates'),
    width: '12%',
  },
  { name: 'actions', label: translate('common.actions'), width: '12%', padding: 'defaultCellVertical no' },
];

const TABS = {
  list: 1,
  map: 2,
};

interface Props extends RouteComponentProps {
  gusId?: string;
  rubiconServicesFormValues: any;
}
interface RubiconServicesFormValues {
  customerName: string;
  divisionId: string;
  serviceId: string;
  equipmentType: string;
  materialType: string;
  serviceStatusIds: string[];
}

const RubiconServicesPage: React.FC<Props> = ({ rubiconServicesFormValues, location: { pathname, search } }) => {
  const dispatch = useDispatch();
  const params = getQueryParams(search);

  const userId = useSelector((state: AppState) => state.account.login.user.userId);
  const vendorId = useSelector(currentVendorId);
  const rubiconServices = useSelector((state: AppState) => state.customers.rubiconServices.rubiconServices);
  const rubiconServiceLocations = useSelector((state: AppState) => state.customers.rubiconServices.coordinates);
  const isLoading = useSelector((state: AppState) => state.customers.rubiconServices.isLoading);
  const total = useSelector((state: AppState) => state.customers.rubiconServices.total);
  const [activeTab, setActiveTab] = useState<number | null>(TABS.list);
  //const [isRefreshPage, setRefreshPage] = useState<boolean>(false);
  const equipmentTypes = useSelector((state: AppState) => state.common.equipmentTypes.equipmentTypes);

  const previousPageNumber = usePrevious(params.page || 1);
  
  useEffect(() => {
    const {
      customerName,
      destinationId,
      isTemp,
      startDate,
      endDate,
      serviceId,
      equipmentType,
      materialType,
      divisionId,
      serviceStatusIds,
      page,
    } = getQueryParams(search);

    if (previousPageNumber !== params.page) {
      dispatch(
        push(
          createUrl(pathname, search, {
            customerName,
            serviceId,
            equipmentType,
            materialType,
            divisionId,
            serviceStatusIds: serviceStatusIds && serviceStatusIds.toString(),
            destinationId: destinationId && destinationId.toString(),
            isTemp,
            startDate,
            endDate,
            page,
          }),
        ),
      );
      const limit = activeTab === TABS.map ? MAX_LIMIT : LIMIT_PER_PAGE;
      const pages = activeTab === TABS.map ? PAGE : page;

      loadRubiconServices(
        vendorId,
        userId,
        customerName,
        divisionId,
        serviceId,
        equipmentType,
        materialType,
        serviceStatusIds?.toString(),
        destinationId?.toString(),
        isTemp,
        startDate,
        endDate,
        pages,
        limit,
      )(dispatch);
    }
  }, [search, pathname, activeTab, previousPageNumber, params.page, userId, vendorId, dispatch]);

  const onRubiconServicesChange = (formData: any) => {
    const {
      customerName,
      destinationId,
      endDate,
      isTemp,
      startDate,
      serviceId,
      equipmentType,
      materialType,
      divisionId,
      serviceStatusIds,
    } = formData;

    dispatch(
      push(
        createUrl(pathname, search, {
          customerName,
          destinationId: destinationId && destinationId.toString(),
          endDate,
          isTemp,
          startDate,
          serviceId,
          equipmentType: equipmentType && equipmentType.toString(),
          materialType: materialType && materialType.toString(),
          divisionId: divisionId && divisionId.toString(),
          serviceStatusIds: serviceStatusIds && serviceStatusIds.toString(),
          page: 1,
        }),
      ),
    );

    const limit = activeTab === TABS.map ? MAX_LIMIT : LIMIT_PER_PAGE;

    loadRubiconServices(
      vendorId,
      userId,
      customerName,
      divisionId?.toString(),
      serviceId,
      equipmentType?.toString(),
      materialType?.toString(),
      serviceStatusIds?.toString(),
      destinationId?.toString(),
      isTemp,
      startDate,
      endDate,
      params.page,
      limit,
    )(dispatch);
  };

  const handleTabChange = async (tab: number) => {
    const {
      customerName,
      destinationId,
      isTemp,
      startDate,
      endDate,
      serviceId,
      equipmentType,
      materialType,
      divisionId,
      serviceStatusIds,
      page,
    } = getQueryParams(search);
    setActiveTab(tab);
    if (activeTab === 1) {
      dispatch(resetRubiconServices());
      loadRubiconServices(
        vendorId,
        userId,
        customerName,
        divisionId?.toString(),
        serviceId,
        equipmentType?.toString(),
        materialType?.toString(),
        serviceStatusIds?.toString(),
        destinationId?.toString(),
        isTemp,
        startDate,
        endDate,
        1,
        MAX_LIMIT,
      )(dispatch);
    } else {
      dispatch(resetRubiconServices());
      loadRubiconServices(
        vendorId,
        userId,
        customerName,
        divisionId?.toString(),
        serviceId,
        equipmentType?.toString(),
        materialType?.toString(),
        serviceStatusIds?.toString(),
        destinationId?.toString(),
        isTemp,
        startDate,
        endDate,
        page,
        LIMIT_PER_PAGE,
      )(dispatch);
    }
    dispatch(clearRouteMapSelectedFeature());
  };

  const exportServices = () => {
    const { customerName, serviceId, equipmentType, materialType, divisionId, serviceStatusIds } =
      rubiconServicesFormValues;

    exportRubiconServices(
      vendorId,
      userId,
      customerName,
      divisionId?.toString(),
      serviceId,
      equipmentType?.toString(),
      materialType?.toString(),
      serviceStatusIds?.toString(),
    )(dispatch);
  };

  const serviceFormInitialValues: Partial<RubiconServicesFormValues> = useMemo(() => {
    const { customerName, serviceId, equipmentType, materialType, divisionId, serviceStatusIds } =
      getQueryParams(search);
    return {
      serviceStatusIds: serviceStatusIds?.toString().split(',') || [ACTIVE, ON_HOLD],
      customerName: customerName || undefined,
      serviceId: serviceId || undefined,
      divisionId: divisionId?.toString().split(',') || null,
      equipmentType: equipmentType?.toString().split(',') || null,
      materialType: materialType?.toString().split(',') || null,
    };
  }, [search]);

  const markers: MarkerItem[] = [];
  rubiconServiceLocations.forEach(rubiconServiceLocation => {
    const isMultiple = rubiconServiceLocation.con.length > 1;
    const type = find(equipmentTypes, { id: Number(rubiconServiceLocation.con[0].t) }) as TechnicalType;

    markers.push({
      id: String(rubiconServiceLocation.lid),
      position: {
        lat: rubiconServiceLocation.la,
        lng: rubiconServiceLocation.lo
      },
      title: isMultiple ? translate('dashboard.multiple') : translate(`common.equipmentTypes.${camelize(type?.technicalName || '')}`),
      icon: {
        url: isMultiple ? CONTAINERS_MAP_ICONS_BY_ID[MULTIPLE].icon.iconUrl : CONTAINERS_MAP_ICONS_BY_ID[rubiconServiceLocation.con[0].t].icon.iconUrl,
        origin: new google.maps.Point(0, 0),
        scaledSize: new google.maps.Size(20, 20)
      },
      label: isMultiple ? '+' : undefined
    })
  });

  const closeModalAndRefresh = (isRefreshPage: boolean) => {
    const {
      customerName,
      destinationId,
      isTemp,
      startDate,
      endDate,
      serviceId,
      equipmentType,
      materialType,
      divisionId,
      serviceStatusIds,
      page,
    } = getQueryParams(search);
    if (isRefreshPage) {
      loadRubiconServices(
        vendorId,
        userId,
        customerName,
        divisionId,
        serviceId,
        equipmentType,
        materialType,
        serviceStatusIds?.toString(),
        destinationId?.toString(),
        isTemp,
        startDate,
        endDate,
        page,
        LIMIT_PER_PAGE,
      )(dispatch);
    }
  };
  return (
    <>
      <PageContent>
        <PageHeader>
          <PageDetails>
            <PageTitleContainer fluid>
              <Grid align="center">
                <GridColumn>
                  <PageTitle>{translate('customers.rubiconServices')}</PageTitle>
                </GridColumn>
                <GridColumn>
                  <TopToggleButtonsContainer>
                    <TopToggleIconButton isActive={activeTab === TABS.list}>
                      <IconButton
                        size="small"
                        margin="no"
                        color="secondary"
                        borderRadius="0"
                        onClick={() => handleTabChange(TABS.list)}
                      >
                        <IconButtonIcon icon="listView" size="xLarge" margin="no xSmall no " />
                        <Text margin="no no no xSmall" weight="medium">
                          {translate('common.list')}
                        </Text>
                      </IconButton>
                    </TopToggleIconButton>
                    <TopToggleIconButton hasBorderRight isActive={activeTab === TABS.map}>
                      <IconButton
                        size="small"
                        margin="no"
                        color="secondary"
                        borderRadius="0"
                        onClick={() => handleTabChange(TABS.map)}
                      >
                        <IconButtonIcon icon="map" size="xLarge" margin="no xSmall no " />
                        <Text margin="no no no xSmall" weight="medium">
                          {translate('common.map')}
                        </Text>
                      </IconButton>
                    </TopToggleIconButton>
                  </TopToggleButtonsContainer>
                </GridColumn>
              </Grid>
            </PageTitleContainer>
          </PageDetails>
          <PageActions>
            <Button id="export-rubicon-services-button" color="primary" onClick={exportServices} margin="no">
              {translate('common.export')}
            </Button>
          </PageActions>
        </PageHeader>
        <Panel>
          <RubiconServicesForm initialValues={serviceFormInitialValues} onSubmit={onRubiconServicesChange} />
          {activeTab !== TABS.map && (
            <PanelSectionGroup isLoading={isLoading}>
              <PanelSection>
                {rubiconServices.length ? (
                  <Table
                    cells={rubiconServicesTableCells}
                    rows={rubiconServices}
                    rowComponent={RubiconServicesTableRow}
                    rowProps={{ refreshPageOnCloseModal: closeModalAndRefresh }}
                  />
                ) : (
                  <Message padding="sMedium">{translate('haulerProfile.noServices')}</Message>
                )}
              </PanelSection>
              {!!total && <Pagination totalResults={total} />}
            </PanelSectionGroup>
          )}
          {activeTab === TABS.map && (
            <Resizable minWidth="100%" minHeight={340} handleComponent={{ bottom: <MapDragHandle /> }}>
              <MapComponent 
                size="large"
                markers={markers}
                legendComponent={<RubiconServicesMapLegend containerLocations={rubiconServiceLocations}/>}
                tooltipComponent={<RubiconServicesTooltipResolver containerLocations={rubiconServiceLocations}/>}
                onSelectMarker={id => { dispatch(setRouteMapSelectedFeature(RouteMapFeature.containerLocation, id)); }}
                onDeselectMarker={() => { dispatch(clearRouteMapSelectedFeature()); }}
              />
            </Resizable>
          )}
        </Panel>
      </PageContent>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  return {
    rubiconServicesFormValues: getFormValues('rubiconServicesHistory')(state) || {},
  } as any;
};

export default connect(mapStateToProps)(withRouter(RubiconServicesPage));
