import update from 'immutability-helper';
import { AnyAction } from 'redux';

import { TechnicalType } from '../interfaces/TechnicalType';

// Actions
const START_LOAD = 'common/jobPriorityTypes/START_LOAD';
const COMPLETE_LOAD = 'common/jobPriorityTypes/COMPLETE_LOAD';
const FAIL_LOAD = 'common/jobPriorityTypes/FAIL_LOAD';
const RESET = 'common/jobPriorityTypes/RESET';

// Initial state
const initialState = {
  isLoading: false,
  jobPriorityTypes: [] as TechnicalType[],
};

export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          jobPriorityTypes: action.jobPriorityTypes,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          jobPriorityTypes: [],
        },
      });

    case RESET:
      return update(state, {
        $merge: initialState,
      });

    default:
      return state;
  }
};

export const resetJobPriorityTypes = () => ({
  type: RESET,
});

// Thunks

