import React from 'react';
import { Switch, Redirect } from 'react-router';

import { Page } from '../../common/components';
import { ServiceChangeRequestsPageResolver } from './pages';
import { OpportunitiesPageResolver } from './pages/OpportunitiesPageResolver';
import { AuthRoute } from '../../account/components';

const Opportunity = () => (
  <Page>
    <Switch>
      <AuthRoute exact path="/opportunities/" component={OpportunitiesPageResolver} />
      <AuthRoute
        exact
        path="/opportunities/service-change-requests/"
        component={ServiceChangeRequestsPageResolver as any}
      />
      <Redirect to="/opportunities/" />
    </Switch>
  </Page>
);

export default Opportunity;
