import { AnyAction } from 'redux';
import update from 'immutability-helper';
import { ThunkDispatch } from 'redux-thunk';
import {
  uploadDocument as doUploadDocument,
  uploadDocumentByToken as doUploadDocumentByToken,
} from '../services/unassignedWeightTickets';

// Actions
const START_UPLOAD = 'fleet/unassignedWeightTicets/START_UPLOAD';
const COMPLETE_UPLOAD = 'fleet/unassignedWeightTicets/COMPLETE_UPLOAD';
const FAIL_UPLOAD = 'fleet/unassignedWeightTicets/FAIL_UPLOAD';

interface UnassignedWeightTicketsState {
  isUploading: boolean;
  uploadedFileDetail: any;
}

const initialState: UnassignedWeightTicketsState = {
  isUploading: false,
  uploadedFileDetail: {},
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: true,
        },
      });

    case COMPLETE_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
          uploadedFileDetail: action.file, //action.unassignedWeightTickets,
        },
      });

    case FAIL_UPLOAD:
      return update(state, {
        $merge: {
          isUploading: false,
        },
      });

    default:
      return state;
  }
};

const startUpload = () => ({
  type: START_UPLOAD,
});

const completeUpload = (file: any) => {
  return {
    type: COMPLETE_UPLOAD,
    file,
  };
};

const failUpload = () => ({
  type: FAIL_UPLOAD,
});

export const uploadDocument = (token: string, file: any, vendorId: number) => (
  dispatch: ThunkDispatch<UnassignedWeightTicketsState, any, AnyAction>,
) => {
  dispatch(startUpload());
  if (token) {
    const uploadDocumentPromise = doUploadDocumentByToken(file, token);
    uploadDocumentPromise
      .then(response => {
        dispatch(completeUpload(response));
      })
      .catch(() => dispatch(failUpload()));
    return uploadDocumentPromise;
  } else {
    const uploadDocumentPromise = doUploadDocument(file, vendorId);
    uploadDocumentPromise
      .then(response => {
        dispatch(completeUpload(response));
      })
      .catch(() => dispatch(failUpload()));
    return uploadDocumentPromise;
  }
};
