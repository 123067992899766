import React, { PureComponent, Fragment } from 'react';

import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { DocumentTitle } from '../../../common/components';
import { DuckFunction } from '../../../contracts/ducks';
import { getQueryParams } from '../../../utils/services/queryParams';
import { loadRouteImports } from '../../ducks';
import { PageLoading } from '../../../common/components/styled';
import { Resolver } from '../../../core/components';
import RouteImportsPage from './RouteImportsPage';
import translate from '../../../core/services/translate';

interface Props extends RouteComponentProps {
  loadRouteImports: DuckFunction<typeof loadRouteImports>;
}

class RouteImportsPageResolver extends PureComponent<Props> {
  loadDependencies = () => {
    const {
      location: { search },
    } = this.props;
    const { vendorId, fileProcessingStatusTypeId, startDate, endDate } = getQueryParams(search);
    const { loadRouteImports } = this.props;

    return Promise.all([loadRouteImports(vendorId, fileProcessingStatusTypeId, startDate, endDate)]);
  };

  render() {
    return (
      <Fragment>
        <DocumentTitle>{translate('routeImport.routeImports')}</DocumentTitle>
        <Resolver
          successComponent={RouteImportsPage}
          loadingComponent={PageLoading}
          resolve={this.loadDependencies}
          redirectOnError="/workorders"
        />
      </Fragment>
    );
  }
}

const mapDispatchToProps = { loadRouteImports };

export default connect(
  undefined,
  mapDispatchToProps,
)(RouteImportsPageResolver);
