import styled from 'styled-components';
import { GridColumn } from '../../../core/components/styled';
import { DARK, LIGHT, loading } from '../../../core/styles';

export const Loader = styled.div<{ light?: boolean }>`
  ${p => loading('14px', p.light ? LIGHT : DARK)};
`;

export const PinOnMapContainer = styled(GridColumn)`
  display: flex;
  align-items: center;

  *:focus {
    outline: none;
  }
`;

export const PopoverDetailsContainer = styled.div`
  padding: 0 10px 10px 10px;
  flex: 1 0 50%;

  & > div {
    padding-top: 10px;
  }
`;
