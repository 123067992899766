import { AnyAction } from 'redux';
import { createSelector } from 'reselect';
import { identity, mapKeys, sumBy } from 'lodash-es';
import { ThunkDispatch } from 'redux-thunk';
import update from 'immutability-helper';

import { CANCELED, COMPLETED, ISSUE_REPORTED, SERVICED } from '../../common/constants';
import { DispatchBoardRouteJobLocationAddress } from '../interfaces/DispatchBoardRouteJob';
import { Route } from '../interfaces/Route';
import { RouteLocation } from '../interfaces/RouteLocation';
import { TripTimeDetails } from '../components/mapWithTimeline/Interfaces';
import applyBatching from '../../common/services/applyBatching';
import {
  createRoute,
  exportRoute as doExportRoute,
  exportYRoute as doExportYRoute,
  loadRoute as doLoadRoute,
  loadYRoute as doLoadYRoute,
  routeLocationAddressChange as doRouteLocationAddressChange,
  transferRouteLocations as doTransferRouteLocations,
} from '../services/routeTracker';

// Actions
const BATCH_UPDATE = 'routes/route/BATCH_UPDATE';
const COMPLETE_ADDRESS_CHANGE = 'routes/route/COMPLETE_ADDRESS_CHANGE';
const FAIL_ADDRESS_CHANGE = 'routes/route/FAIL_ADDRESS_CHANGE ';
const FAIL_EXPORT = 'routes/route/FAIL_EXPORT';
const FAIL_LOAD = 'routes/route/FAIL_LOAD';
const FAIL_SAVE = 'routes/route/FAIL_SAVE';
const LOAD_TRIP_TIME_DETAILS = 'routes/route/LOAD_TRIP_TIME_DETAILS';
const RESET = 'routes/route/RESET';
const START_ADDRESS_CHANGE = 'routes/route/START_ADDRESS_CHANGE';
const START_BATCH_TRANSFER = 'routes/route/START_BATCH_TRANSFER';
const START_EXPORT = 'routes/route/START_EXPORT';
const START_LOAD = 'routes/route/START_LOAD';
const START_SAVE = 'routes/route/START_SAVE';
export const COMPLETE_BATCH_TRANSFER = 'routes/route/COMPLETE_BATCH_TRANSFER';
export const COMPLETE_EXPORT = 'routes/route/COMPLETE_EXPORT';
export const COMPLETE_LOAD = 'routes/route/COMPLETE_LOAD';
export const COMPLETE_SAVE = 'routes/route/COMPLETE_SAVE';
export const FAIL_BATCH_TRANSFER = 'routes/route/FAIL_BATCH_TRANSFER';

type Dispatch = ThunkDispatch<State, any, AnyAction>;

const batchedLocationsRequesters: { callId: string | null } & { [key: string]: boolean | null } = {
  callId: null,
};

interface State {
  batchTransfererInProgress: boolean;
  batchTransfererProgress: 0;
  isChangingAddress: boolean;
  isExporting: boolean;
  isLoading: boolean;
  isSaving: boolean;
  route?: Route;
  tripTimeDetails?: TripTimeDetails;
}

// Initial state
const initialState: State = {
  batchTransfererInProgress: false,
  batchTransfererProgress: 0,
  isChangingAddress: false,
  isExporting: false,
  isLoading: false,
  isSaving: false,
  route: undefined,
  tripTimeDetails: undefined,
};

// Reducer
export const reducer = (state: State = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: true,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          route: action.route,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          route: undefined,
        },
      });

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: true,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
        },
      });

    case START_EXPORT:
      return update(state, {
        $merge: {
          isExporting: true,
        },
      });

    case COMPLETE_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case FAIL_EXPORT:
      return update(state, {
        $merge: {
          isExporting: false,
        },
      });

    case START_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: true,
        },
      });

    case COMPLETE_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: false,
        },
      });

    case FAIL_BATCH_TRANSFER:
      return update(state, {
        $merge: {
          batchTransfererInProgress: false,
        },
      });

    case START_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: true,
        },
      });

    case COMPLETE_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: false,
        },
      });

    case FAIL_ADDRESS_CHANGE:
      return update(state, {
        $merge: {
          isChangingAddress: false,
        },
      });

    case BATCH_UPDATE:
      return update(state, {
        $merge: {
          batchTransfererProgress: action.progress,
        },
      });

    case RESET:
      return initialState;

    case LOAD_TRIP_TIME_DETAILS:
      return update(state, { $merge: { tripTimeDetails: action.tripTimeDetails } });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (route: Route) => ({
  type: COMPLETE_LOAD,
  route,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

const startSave = () => ({
  type: START_SAVE,
});

const completeSave = (saveType: 'Update' | 'Create') => ({
  type: COMPLETE_SAVE,
  saveType,
});

const failSave = () => ({
  type: FAIL_SAVE,
});

const startExport = () => ({
  type: START_EXPORT,
});

const completeExport = () => ({
  type: COMPLETE_EXPORT,
});

const failExport = () => ({
  type: FAIL_EXPORT,
});

const startBatchTransfer = () => ({
  type: START_BATCH_TRANSFER,
});

const completeBatchTransfer = () => ({
  type: COMPLETE_BATCH_TRANSFER,
});

const failBatchTransfer = () => ({
  type: FAIL_BATCH_TRANSFER,
});

const startAddressChange = () => ({
  type: START_ADDRESS_CHANGE,
});

const completeAddressChange = () => ({
  type: COMPLETE_ADDRESS_CHANGE,
});

const failAddressChange = () => ({
  type: FAIL_ADDRESS_CHANGE,
});

const batchUpdate = (progress: number) => ({
  type: BATCH_UPDATE,
  progress,
});

export const resetRoute = () => ({
  type: RESET,
});

export const loadRoute = (routeId: number, includeUnscheduledStops?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadRoutePromise = doLoadRoute(routeId, includeUnscheduledStops);
  loadRoutePromise.then(route => dispatch(completeLoad(route))).catch(() => dispatch(failLoad()));
  return loadRoutePromise;
};

export const cancelBatchedLocationsLoading = (requesterId: string) => {
  batchedLocationsRequesters[requesterId] = false;
};

export const saveRoute = (route: Route, optimized?: boolean) => (dispatch: Dispatch) => {
  dispatch(startSave());
  const saveRoutePromise = createRoute(route);
  const saveType = 'Create';
  saveRoutePromise.then(() => dispatch(completeSave(saveType))).catch(() => dispatch(failSave()));
  return saveRoutePromise;
};

export const exportRoute =
  (routeId: number, pickupStatusTypeId?: number, customerOrAddressContains?: string) => (dispatch: Dispatch) => {
    dispatch(startExport());
    const exportRoutePromise = doExportRoute(routeId, pickupStatusTypeId, customerOrAddressContains);
    exportRoutePromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
    return exportRoutePromise;
  };

export const transferRouteLocations =
  (request: {
    routeEntityLocationsToTransfer: RouteLocation[];
    targetRouteId: string;
    routeDate: Date | string;
    positionTypeId: number;
  }) =>
  async (dispatch: Dispatch) => {
    dispatch(startBatchTransfer());

    const batchSize = 50;
    const batchResult: {
      totalStops: number;
      transferredStops: number;
      notTransfferedStopIds: number[];
    } = {
      totalStops: 0,
      transferredStops: 0,
      notTransfferedStopIds: [],
    };

    await applyBatching(request.routeEntityLocationsToTransfer, batchSize, async (batchRouteLocations, progress) => {
      const result = await doTransferRouteLocations({
        ...request,
        routeLocationsToTransfer: batchRouteLocations,
      }).catch(() => dispatch(failBatchTransfer()));

      batchResult.totalStops += result.totalStops;
      batchResult.transferredStops += result.transferredStops;
      batchResult.notTransfferedStopIds = [...batchResult.notTransfferedStopIds, ...result.notTransfferedStopIds];

      dispatch(batchUpdate(progress));
    });

    dispatch(completeBatchTransfer());

    return batchResult;
  };

export const routeLocationAddressChange =
  (routeLocationAddress: Partial<DispatchBoardRouteJobLocationAddress>) => (dispatch: Dispatch) => {
    dispatch(startAddressChange());
    const routeLocationAddressChangePromise = doRouteLocationAddressChange(routeLocationAddress);
    routeLocationAddressChangePromise
      .then(() => dispatch(completeAddressChange()))
      .catch(() => dispatch(failAddressChange()));
    return routeLocationAddressChangePromise;
  };

// Selectors
const getCompletedStopCount = (routeState: State) =>
  sumBy(routeState.route?.routeLocations, routeLocation =>
    Number([COMPLETED, ISSUE_REPORTED, SERVICED, CANCELED].indexOf(routeLocation.pickupStatusId) > -1),
  );

export const completedStopCountSelector = createSelector(getCompletedStopCount, identity);

const getRouteLocationsById = (routeState: State) => mapKeys(routeState.route?.routeLocations, 'id');

export const routeLocationsByIdSelector = createSelector(getRouteLocationsById, identity);
export const loadYRoute = (routeId: number | string, loadRouteLocations?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadYRoutePromise = doLoadYRoute(routeId, loadRouteLocations);
  loadYRoutePromise.then(route => dispatch(completeLoad(route))).catch(() => dispatch(failLoad()));
  return loadYRoutePromise;
};

export const exportYRoute = (routeDetailId: number) => (dispatch: Dispatch) => {
  dispatch(startExport());
  const exportYRoutePromise = doExportYRoute(routeDetailId);
  exportYRoutePromise.then(() => dispatch(completeExport())).catch(() => dispatch(failExport()));
  return exportYRoutePromise;
};
