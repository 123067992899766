import { combineReducers } from 'redux';
import { reducer as containersForMapLocationReducer } from './containersForMapLocation';
import { reducer as notesReducer } from './notes';
import { reducer as openDispatchesReducer } from './openDispatches';
import { reducer as rubiconDispatchesReducer } from './rubiconDispatches';
import { reducer as serviceConfirmationReducer } from './serviceConfirmation';
import { reducer as unassignedWeightTicketsReducer } from './unassignedWeightTickets';
import { reducer as vehicleReducer } from './vehicle';
import { reducer as vehicleTypesForVendorReducer } from './vehicleTypesForVendor';
import { reducer as vehicleTypesReducer } from './vehicleTypes';
import { reducer as workOrderDocumentsReducer } from './workOrderDocuments';
import visionConfigurationReducer from './visionConfiguration';

export {
  checkVehiclesHaveRoutesInRange,
  loadVehicle,
  loadVehicleFacilites,
  resetVehicle,
  saveVehicle,
  loadAiModel,
  clearAiModel,
  aiModelTypesSelector,
  vendorAiModelTypesSelector,
  modelConfigurationSelector,
  vehicleIdSelector,
  selectCommands,
  saveVehicleModelConfig,
  loadBinColors,
  COMPLETE_LOAD as COMPLETE_LOAD_VEHICLE,
  COMPLETE_VEHICLE_SAVE,
} from './vehicle';
export {
  loadVehicleTypes,
  resetVehicleTypes,
  vehicleTypesByIdSelector,
  vehicleTypesForRoleTypeSelector,
  vehicleSubTypesSelector,
} from './vehicleTypes';
export {
  loadVehicleTypesForVendor,
  saveVehicleTypesForVendor,
  resetVehicleTypesForVendor,
  vehicleTypesForVendorWithRoleTypeSelector,
  technicalNameByVehicleTypeIdSelector,
  vehicleIdByTechnicalNameSelector,
  vehicleIdsByTechnicalNamesSelector,
} from './vehicleTypesForVendor';
export {
  authenticateToken,
  loadOpenDispatches,
  updateOpenDispatches,
  getServiceContractByCode,
  createUnassignedJob,
  saveServiceContract,
  setNewServiceContractId,
} from './openDispatches';
export { loadRubiconDispatches, resetRubiconDispatches } from './rubiconDispatches';
export { loadDocuments } from './workOrderDocuments';
export {
  loadServiceConfirmation,
  updateServiceConfirmation,
  uploadDocumentImage,
  downloadDocument,
} from './serviceConfirmation';
export { getNotes, addNotes, showModal as showNotesModal, hideModal as hideNotesModal } from './notes';

export {
  clearVisionConfiguration,
  loadVisionConfigurationForVehicle,
  loadVisionConfigurationWithVehicleTypeId,
  saveVisionConfigurationForVehicle,
  saveVisionConfigurationWithVehicleTypeId,
  visionConfigurationSelector,
} from './visionConfiguration';

export { uploadDocument } from './unassignedWeightTickets';

export { loadContainersForMapLocation, resetContainersForMapLocation } from './containersForMapLocation';

export const reducer = combineReducers({
  containersForMapLocation: containersForMapLocationReducer,
  notes: notesReducer,
  openDispatches: openDispatchesReducer,
  rubiconDispatches: rubiconDispatchesReducer,
  serviceConfirmation: serviceConfirmationReducer,
  unassignedWeightTickets: unassignedWeightTicketsReducer,
  vehicle: vehicleReducer,
  vehicleTypes: vehicleTypesReducer,
  vehicleTypesForVendor: vehicleTypesForVendorReducer,
  visionConfiguration: visionConfigurationReducer,
  workOrderDocuments: workOrderDocumentsReducer,
});
