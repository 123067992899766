import React, { useMemo, useState } from 'react';
import { withRouter } from 'react-router';
import { NavLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';

import AdminOnlyGuard from 'src/account/components/AdminOnlyGuard';
import { Icon } from 'src/core/components';
import { Text } from 'src/core/components/styled';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { OPPORTUNITIES, WORK_ORDERS, AUTO_DISPATCH } from 'src/opportunity/constants/status';
import matchRoute from 'src/utils/services/matchRoute';
import SelectDefaultVendorModalToggle from 'src/vendors/components/modals/SelectDefaultVendorModalToggle';
import { getTokenFromUrl } from '../utils/navigation';
import {
  CloseNavigationBar,
  MainNavigationIcon,
  MainNavigationLogo,
  MobileNavigationBarContainer,
  MobileNavigationNode,
  MobileNavigationNodesList,
  MobileNavigationNodeTitleContainer,
  MobileNavigationOpener,
  MobileNavigationSecondaryContent,
  MobileNavigationSubNode,
  MobileNavigationSubNodesList,
  MobilePageTitleContainer,
  NavigationBarFlyout,
  NavigationBarFlyoutBackground,
  StickyNav,
  SubNavigationItemCount,
} from './styled/NavigationBar';
import { useIsMobileView } from 'src/utils/hooks';
import { vendorGusIdSelector } from 'src/account/ducks';

interface BaseNode {
  title: string;
  isHidden?: boolean;
}

interface NavigationSubNode extends BaseNode {
  url: string;
  notificationCount?: number | string;
}
interface NavigationNode extends BaseNode {
  isRubiconLogo?: boolean;
  logo: string;
  subNodes?: NavigationSubNode[];
  url?: string;
}

const SubNavigationLink = styled(NavLink)`
  display: flex;
  align-items: center;

  &.active {
    color: ${p => p.theme.brandPrimary};
  }
`;

interface Props extends RouteComponentProps {
  isLoggedIn: boolean;
}

const MobileNavigationBarComponent: React.FC<Props> = ({ location: { pathname } }, isLoggedIn) => {
  const [isFlyoutOpen, setIsFlyoutOpen] = useState(false);

  const dispatchOpportunitiesCounts = useSelector(
    s => s.common.dispatchOpportunitiesCount.dispatchOpportunitiesCounts || {},
  );

  const isVendorWithGusId = useSelector(state => vendorGusIdSelector(state.account.login, state.vendors.defaultVendor));

  const opportunitiesCount = dispatchOpportunitiesCounts.opportunities + dispatchOpportunitiesCounts.bidOpportunities;

  const autoDispatchToken = matchRoute(pathname, '/workorders')
    ? getTokenFromUrl(pathname, WORK_ORDERS.toLowerCase())
    : getTokenFromUrl(pathname, AUTO_DISPATCH);

  const isActive = (path: string) => matchRoute(pathname, path);

  const opportunitiesToken = getTokenFromUrl(pathname, OPPORTUNITIES.toLowerCase());

  const token = autoDispatchToken || opportunitiesToken;

  const hideLoggedInPages = !!token || !isLoggedIn;

  const isMobile = useIsMobileView();

  const navigationNodes: NavigationNode[] = useMemo(
    () => [
      {
        title: 'Rubicon',
        isRubiconLogo: true,
        logo: 'main',
        subNodes: [
          {
            title: translate('opportunity.opportunities.opportunities'),
            url: '/opportunities',
            notificationCount: opportunitiesCount,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('customers.rubiconServices'),
            url: `/rubicon-services`,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('opportunity.serviceChangeRequests'),
            url: `/opportunities/service-change-requests${token ? `/${token}` : ''}`,
            notificationCount: dispatchOpportunitiesCounts.serviceChangeRequests,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('autoDispatch.workOrders'),
            url: `/workorders${token ? `/${token}` : ''}`,
            notificationCount: dispatchOpportunitiesCounts.workOrders,
            isHidden: !isVendorWithGusId,
          },
          {
            title: translate('invoice.invoices'),
            url: '/invoices',
            isHidden: !isVendorWithGusId,
          },
        ],
      },
      // {
      //   title: translate('tooltips.select'),
      //   logo: 'rubiconSelect',
      //   url: '/rubicon-select',
      // },
      {
        title: translate('common.settings'),
        logo: 'settingsCircle',
        isHidden: hideLoggedInPages,
        subNodes: [
          {
            title: translate('common.applicationSettings'),
            url: '/account-settings',
          },
          // {
          //   title: translate('common.adminSettings'),
          //   url: '/settings/tracking',
          // },
          {
            title: translate('haulerProfile.haulerProfile'),
            url: '/hauler-profile/overview',
            isHidden: !isVendorWithGusId,
          },
        ],
      },
    ],
    [
      opportunitiesCount, 
      isVendorWithGusId, 
      token, 
      dispatchOpportunitiesCounts.serviceChangeRequests, 
      dispatchOpportunitiesCounts.workOrders, 
      hideLoggedInPages, 
    ],
  );

  const pageTitle = useSelector(s => s.core.mobilePage.title);
  const pageSubtitle = useSelector(s => s.core.mobilePage.subTitle);
  const secondaryContent = useSelector(s => s.core.mobilePage.secondaryContent);

  return (
    <StickyNav>
      <MobileNavigationBarContainer>
        <MobileNavigationOpener margin="no small" onClick={() => setIsFlyoutOpen(true)} text>
          <Icon customViewBox="0 0 24 24" width="24px" icon="menu" />
        </MobileNavigationOpener>
        <MobilePageTitleContainer>
          <Text margin="no xxSmall" size="xLarge">
            {pageTitle}
          </Text>
          {pageSubtitle && (
            <Text margin="no xxSmall" size="small">
              {pageSubtitle}
            </Text>
          )}
        </MobilePageTitleContainer>

        {isMobile && secondaryContent && (
          <MobileNavigationSecondaryContent>{secondaryContent}</MobileNavigationSecondaryContent>
        )}
        {isFlyoutOpen && <CloseNavigationBar onClick={() => setIsFlyoutOpen(false)}>X</CloseNavigationBar>}
        {isFlyoutOpen && <NavigationBarFlyoutBackground />}
        <NavigationBarFlyout isOpen={isFlyoutOpen}>
          <MobileNavigationNodesList>
            {navigationNodes
              .filter(n => !n.isHidden)
              .map((n, i) => (
                <MobileNavigationNode key={i}>
                  {n.isRubiconLogo ? (
                    <>
                      <MainNavigationLogo
                        onClick={() => setIsFlyoutOpen(false)}
                        to='/workorders'
                      />
                    </>
                  ) : n.url ? (
                    <MobileNavigationNodeTitleContainer>
                      <SubNavigationLink onClick={() => setIsFlyoutOpen(false)} to={n.url!}>
                        <MainNavigationIcon icon={n.logo} />
                        <Text
                          margin="xSmall xxSmall"
                          color={`${isActive(n.url) ? 'primary' : 'grayDark'}`}
                          size="xxLarge"
                        >
                          {n.title}
                        </Text>
                      </SubNavigationLink>
                    </MobileNavigationNodeTitleContainer>
                  ) : (
                    <MobileNavigationNodeTitleContainer>
                      <MainNavigationIcon icon={n.logo} />
                      <Text margin="no xxSmall" color="grayDark" size="xxLarge">
                        {n.title}
                      </Text>
                    </MobileNavigationNodeTitleContainer>
                  )}

                  {n.subNodes && n.subNodes.length && (
                    <MobileNavigationSubNodesList>
                      {n.isRubiconLogo && (
                        <AdminOnlyGuard>
                          <MobileNavigationSubNode key="vendorChanger">
                            <SelectDefaultVendorModalToggle />
                          </MobileNavigationSubNode>
                        </AdminOnlyGuard>
                      )}
                      {n.subNodes
                        .filter(n => !n.isHidden)
                        .map((sn, i) => (
                          <MobileNavigationSubNode key={i}>
                            <SubNavigationLink onClick={() => setIsFlyoutOpen(false)} to={sn.url!}>
                              <Text size="large">{sn.title}</Text>
                              {!!sn.notificationCount && (
                                <SubNavigationItemCount isVisible={!!sn.notificationCount}>
                                  {Number(sn.notificationCount) > 99 ? '99+' : sn.notificationCount}
                                </SubNavigationItemCount>
                              )}
                            </SubNavigationLink>
                          </MobileNavigationSubNode>
                        ))}
                    </MobileNavigationSubNodesList>
                  )}
                </MobileNavigationNode>
              ))}
            {!hideLoggedInPages && (
              <MobileNavigationSubNodesList isAccount>
                <SubNavigationLink to={'/account/logout'}>
                  <Text size="large">{translate('account.logout')}</Text>
                </SubNavigationLink>
              </MobileNavigationSubNodesList>
            )}
          </MobileNavigationNodesList>
        </NavigationBarFlyout>
      </MobileNavigationBarContainer>
    </StickyNav>
  );
};

export const MobileNavigationBar = withRouter(MobileNavigationBarComponent);
