import React from 'react';
import { Switch, Redirect } from 'react-router';
import { AdminRoute } from '../../../account/components';

import { PageContent, PageHeader, PageDetails, PageTitleContainer, PageTitle } from '../../../common/components/styled';
import { Panel, PanelSection, Tabs, TabLink } from '../../../core/components/styled';
import ProximitySettingsPageResolver from './ProximitySettingsPageResolver';
import VersionStatusesPageResolver from './VersionStatusesPageResolver';
import translate from '../../../core/services/translate';
import DeviceStatusesPageResolver from './DeviceStatusesPageResolver';

const SettingsPage = () => (
  <PageContent>
    <PageHeader>
      <PageDetails>
        <PageTitleContainer>
          <PageTitle>{translate('common.settings')}</PageTitle>
        </PageTitleContainer>
      </PageDetails>
    </PageHeader>

    <Panel>
      <PanelSection centered withBorder padding="sMedium">
        <Tabs>
          {/* <TabLink to="/settings/tracking">{translate('settings.trackingSettings')}</TabLink> */}
          <TabLink to="/settings/proximity">{translate('settings.proximitySettings')}</TabLink>
          <TabLink to="/settings/version-statuses">{translate('settings.versionStatus')}</TabLink>
          <TabLink to="/settings/device-statuses">{translate('settings.deviceStatus')}</TabLink>
        </Tabs>
      </PanelSection>

      <Switch>
        {/* <AdminRoute exact path="/settings/tracking" component={TrackingSettingsPageResolver as any} /> */}
        <AdminRoute exact path="/settings/proximity" component={ProximitySettingsPageResolver} />
        <AdminRoute exact path="/settings/version-statuses" component={VersionStatusesPageResolver as any} />
        <AdminRoute exact path="/settings/device-statuses" component={DeviceStatusesPageResolver as any} />
        <Redirect to="/settings/proximity" />
      </Switch>
    </Panel>
  </PageContent>
);

export default SettingsPage;
