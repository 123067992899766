import styled, { css } from 'styled-components';

export const MapControl = styled.div<{ notAbsolute?: boolean; left?: string; top?: string }>`
  ${p =>
    p.notAbsolute
      ? css`
          button {
            background-color: initial;
            color: ${p => p.theme.brandPrimary};
            font-size: 12px;
            padding: 0;
          }
        `
      : css`
          position: absolute;
          left: ${p.left || '20px'};
          top: ${p.top || '20px'};
        `}
`;

export type ComplexMapControlPosition = 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';

const calculateOffset = (offset = 0, normalMargin = 20) => `${normalMargin + offset}px`;

interface ComplexMapControlProps {
  background?: string;
  normalMargin?: number;
  position?: ComplexMapControlPosition;
  vertical?: boolean;
  xOffset?: number;
  yOffset?: number;
  zIndex?: number;
}

export const ComplexMapControl = styled.div<ComplexMapControlProps>`
  position: absolute;
  display: flex;

  z-index: ${props => props.zIndex || 2};

  ${props =>
    props.background &&
    css`
      background: ${props.background};
      padding: 5px;
    `}

  ${props =>
    props.vertical &&
    css`
      flex-direction: column;
      align-items: flex-end;
    `}

  ${props =>
    (!props.position || props.position === 'top-left') &&
    css`
      top: ${calculateOffset(props.yOffset, props.normalMargin)};
      left: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'top-right' &&
    css`
      top: ${calculateOffset(props.yOffset, props.normalMargin)};
      right: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'bottom-left' &&
    css`
      bottom: ${calculateOffset(props.yOffset, props.normalMargin)};
      left: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}

  ${props =>
    props.position === 'bottom-right' &&
    css`
      bottom: ${calculateOffset(props.yOffset, props.normalMargin)};
      right: ${calculateOffset(props.xOffset, props.normalMargin)};
    `}
`;

interface MapLegendProps {
  collapsed?: boolean;
  color?: boolean;
  position?: string;
  minWidth?: string;
}

export const MapLegend = styled.div<MapLegendProps>`
  background: #fff;
  padding: 6px 10px;
  font-size: 15px;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  & b {
    margin-right: 20px;
  }

  & > svg,
  & > span > svg {
    width: 15px;
    height: 15px;
    transition: transform 0.2s;
    transform: rotate(0);
    margin: 0;
    position: absolute;
    top: 8px;
    right: 5px;
  }

  ${props =>
    props.collapsed &&
    css`
      & > svg,
      & > span > svg {
        transform: rotate(180deg);
      }
    `}
`;
