import styled from 'styled-components';


export const DispatchBoardJobRouteTitle = styled.span`
  display: block;
  padding: 4px 12px;
  background-color: ${props => props.theme.grayLighter};
  text-transform: uppercase;
  line-height: 20px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: 11px;
  color: ${props => props.theme.grayBase};
`;

export const DispatchBoardFlexWrapper = styled.div`
  display: flex;
`;
