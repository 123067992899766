import { GOOGLE as google } from 'src/common/constants';
import mapPinCircle from 'src/common/assets/img/common/mapPinCirclePrimaryFull.svg';

export const GEO_FENCE_OPTIONS = { fillColor: '#dceeee', strokeColor: '#00a599', strokeOpacity: 1, strokeWeight: 1 };

export const GEO_FENCE_OPTIONS_SAT = {
  fillColor: '#ffd65a',
  strokeColor: '#ffd65a',
  strokeOpacity: 1,
  strokeWeight: 1,
};
export const GEO_FENCE_OPTIONS_SELECTED = {
  fillColor: '#ffa500',
  strokeColor: '#ffa500',
  strokeOpacity: 1,
  strokeWeight: 1,
};

export const MAP_BOUNDS: google.maps.LatLngBounds = new google.maps.LatLngBounds();
export const MAP_CENTER: google.maps.LatLng = new google.maps.LatLng({ lat: 39.5, lng: -98.35 });
export const MAP_CLUSTERER_THRESHOLD = 500;
export const MAP_DEFAULT_ZOOM_SMALL = 3;
export const MAP_DEFAULT_ZOOM = 5;
export const MAP_CITY_ZOOM = 13;
export const MAP_CITY_ZOOM_IN = 15;
export const MAP_CITY_ZOOM_IN_BIGGER = 17;
export const MAP_CITY_ZOOM_IN_BIGGEST = 21;
export const MAP_CITY_ZOOM_SMALL = 11;
export const MAP_GET_BOUNDS = (map: google.maps.Map) => map.getBounds();
export const MAP_GET_CENTER = (map: google.maps.Map) => map.getCenter() || MAP_CENTER;
export const MAP_GET_DIV = (map: google.maps.Map) => map.getDiv();
export const MAP_GET_ZOOM = (map: google.maps.Map) => map.getZoom() || MAP_DEFAULT_ZOOM;
export const MAP_LABEL_STYLE = { color: '#fff', fontSize: '10px' };
export const MAP_MARKER_ICON = { url: mapPinCircle, anchor: new google.maps.Point(16, 15) };
export const MAP_MAX_ZOOM = 15;
export const MAP_STYLE = { height: '100%', width: '100%' };

export const HYBRID_VIEW_ID = 'hybrid';
export const SATELLITE_VIEW_ID = 'satellite';
export const TERRAIN_VIEW_ID = 'terrain';
export const ROADMAP_VIEW_ID = 'roadmap';

export type MapTypeId =
  | typeof HYBRID_VIEW_ID
  | typeof SATELLITE_VIEW_ID
  | typeof ROADMAP_VIEW_ID
  | typeof TERRAIN_VIEW_ID;

export const MAP_VIEW_OPTIONS = {
  [HYBRID_VIEW_ID]: GEO_FENCE_OPTIONS_SAT,
  [SATELLITE_VIEW_ID]: GEO_FENCE_OPTIONS_SAT,
  [ROADMAP_VIEW_ID]: GEO_FENCE_OPTIONS,
  [TERRAIN_VIEW_ID]: GEO_FENCE_OPTIONS,
};
