import React, { useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { ACCOUNT_SETTINGS_MANAGE_USERS } from '../../../account/constants';
import { AppState } from '../../../store';
import { ButtonLink, Grid, GridColumn } from '../../../core/components/styled';
import { PermissionGuard } from '../../../account/components';
import LabeledDataView from '../../../core/components/LabeledDataView';
import translate from '../../../core/services/translate';
import { Vendor } from '../../interfaces/Vendors';
import QuickBooksButton from './components/QuickBooksButton';
import { billingFeatureStatusSelector } from 'src/vendors/ducks/features';
import { checkIsBillingActive } from 'src/vendors/ducks';

interface Props {
  roleId?: number;
}

const AccountSettingsVendorDisplay: React.FC<Props> = ({ roleId }) => {
  const dispatch = useDispatch();
  const vendor = useSelector((state: AppState) => state.vendors.vendor.vendor) as any as Vendor;

  const isBillingFeatureActive = useSelector((state: AppState) =>
    billingFeatureStatusSelector(state.vendors.features.features),
  );
  const { isBillingActive } = useSelector((state: AppState) => state.vendors.stripeAccount);

  useEffect(() => {
    if (isBillingFeatureActive) {
      checkIsBillingActive(vendor.id)(dispatch);
    }
  }, [dispatch, isBillingFeatureActive, vendor.id]);

  return (
    <Grid multiLine>
      <GridColumn size="6/12" padding="no xSmall small no">
        <LabeledDataView label={translate('common.name')} value={vendor.name} />
      </GridColumn>

      <GridColumn size="6/12" padding="no xSmall no no">
        <LabeledDataView
          noWrap
          label={translate('common.address')}
          value={vendor.homeAddress && vendor.homeAddress.formattedAddress}
        />
      </GridColumn>

      <GridColumn size="12/12" padding="no no small no" verticalAlign="flex-end">
        {isBillingFeatureActive && <QuickBooksButton />}
        {isBillingFeatureActive && (
          <ButtonLink
            line
            to="/finance/onboarding"
            color="primary"
            disabled={isBillingActive}
            size="small"
            margin="no small"
          >
            {translate(
              `finance.stripeIntegration.${isBillingActive ? 'stripeAccountActive' : 'activateStripeAccount'}`,
            )}
          </ButtonLink>
        )}
        <PermissionGuard permission={ACCOUNT_SETTINGS_MANAGE_USERS}>
          <ButtonLink line to="/users" color="primary" size="small">
            {translate('vendors.manageUsers')}
          </ButtonLink>
        </PermissionGuard>
      </GridColumn>
    </Grid>
  );
};

export default AccountSettingsVendorDisplay;
