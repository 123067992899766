import { debounce } from 'lodash-es';
import { PureComponent } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { Field, InjectedFormProps, reduxForm } from 'redux-form';

import { Input, LocationPicker } from '../../../core/components';
import { Button, ButtonSet, Grid, GridColumn } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import { hasCity, hasCountry, hasStreet, hasZip, isRequired, maxLength10, maxLength50 } from '../../../utils/services/validator';
import businessProfileFormInitialValuesSelector from '../../services/businessProfileFormInitialValuesSelector';

interface PropsWithoutReduxForm extends RouteComponentProps {
  closeModal: () => void;
  isEditable: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<any, PropsWithoutReduxForm>;

class BusinessProfileForm extends PureComponent<Props> {
  onAddressChange = debounce((event: any, address: any) => {
    const { change } = this.props;
    const newAddress = {
      ...address,
      formattedAddress: address.formattedAddress,
    };
    change('address', newAddress);
  }, 800);

  render() {
    const { closeModal, handleSubmit, isEditable, reset } = this.props;
    return (
      <form onSubmit={handleSubmit} noValidate>
        <Grid multiLine>
          <GridColumn size="12/12" padding="no">
            <Field
              readOnly={!isEditable}
              name="companyName"
              component={Input}
              label={translate('haulerProfile.companyName')}
              validate={[isRequired, maxLength50]}
            />
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Field
              readOnly={!isEditable}
              name="address"
              component={LocationPicker as any}
              label={translate('common.address')}
              validate={[isRequired, hasCountry, hasCity, hasStreet, hasZip]}
              onChange={this.onAddressChange}
            />
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Field
              readOnly={!isEditable}
              name="taxID"
              component={Input}
              label={translate('haulerProfile.taxIdNumber')}
              validate={[maxLength10]}
            />
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Field
              readOnly={!isEditable}
              name="dOT"
              component={Input}
              label={translate('haulerProfile.dotNumber')}
              validate={[maxLength10]}
            />
          </GridColumn>

          <GridColumn size="12/12" padding="no">
            <Field
              readOnly={!isEditable}
              name="companyWebsite"
              component={Input}
              label={translate('haulerProfile.website')}
            />
          </GridColumn>

          {!!isEditable && (
            <GridColumn size="12/12" padding="no no medium no">
              <ButtonSet align="center" margin="no">
                <Button type="submit" color="primary">
                  {translate('common.save')}
                </Button>
                <Button
                  type="button"
                  margin="no small"
                  color="secondary"
                  onClick={() => {
                    reset();
                    closeModal();
                  }}
                >
                  {translate('common.cancel')}
                </Button>
              </ButtonSet>
            </GridColumn>
          )}
        </Grid>
      </form>
    );
  }
}

const mapStateToProps = (state: AppState) => ({
  initialValues: businessProfileFormInitialValuesSelector(state.haulerProfile.businessProfile.businessInfo),
});

export default withRouter(
  connect(mapStateToProps)(
    reduxForm<any, PropsWithoutReduxForm>({
      form: 'businessProfile',
      onSubmitFail: focusFirstInvalidField,
      enableReinitialize: true,
    })(BusinessProfileForm),
  ),
);
