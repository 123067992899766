import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/ie11'; // For IE 11 support
import 'react-app-polyfill/stable';
// IE 11 polyfills for fetch and AbortController support
import 'promise-polyfill/src/polyfill';
import 'unfetch/polyfill';
import 'abortcontroller-polyfill';
// eslint-disable-next-line @typescript-eslint/no-unused-vars

import { Fragment } from 'react';
import { render } from 'react-dom';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { Switch, Route } from 'react-router';
import { ThemeProvider } from 'styled-components';

import store from './store';
import { AuthRoute, AdminRoute, Account, GuestRoute } from './account/components';
import { getIsInMaintenanceMode } from 'src/core/services/environment';
import { GlobalStyles, theme } from 'src/core/styles';
import { HaulerProfile } from './haulerProfile/components';
import { InferenceAudit } from './inferenceAudit/components';
import { Invoice } from './opportunity/components';
import { LastLocationsProvider, NotificationList } from 'src/core/components';
import { LoginPageResolver } from './account/components/pages';
import { MaintenancePage } from './landing/components/pages/MaintenancePage';
import { Opportunity } from './opportunity/components';
import { Page, DefaultRoute } from './common/components';
import { PaymentsPage } from './payments/components/pages';
import { RouteImport } from './routeImport/components';
import { SelectDefaultVendorPageResolver } from './vendors/components/pages';
import { SettingsPage } from './settings/components/pages';
import { Vendors } from './vendors/components';
import { Workorders } from './fleet/components';
import history from 'src/core/services/history';
import initialize from 'src/core/services/initialize';
import registerAuthInterceptor from './account/services/registerAuthInterceptor';
import RubiconServicesPageResolver from 'src/customers/components/pages/RubiconServicesPageResolver';
import TimePickerStyles from 'src/core/styles/TimePickerStyles';

initialize();
registerAuthInterceptor();

// IE 11 fix for AbortController
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const AbortController = window.AbortController;

const AppRouter = () => {
  return (
    <Page>
      <Switch>
        <AuthRoute path="/rubicon-services" component={RubiconServicesPageResolver} />
        <AdminRoute path="/settings" component={SettingsPage} />
        <AdminRoute path="/route-import" component={RouteImport} />
        <AuthRoute path="/inference-audit" component={InferenceAudit} />
        <AuthRoute path="/:path(account-settings|users)" component={Vendors} />
        <Route component={DefaultRoute} />
      </Switch>
    </Page>
  );
};

const Root = () => {
  // const vendorId = useSelector(currentVendorId);
  // const userId = useSelector(currentUserId);
  const isInMaintenanceMode = getIsInMaintenanceMode();

  return isInMaintenanceMode ? (
    <MaintenancePage />
  ) : (
    <ConnectedRouter history={history}>
      <Fragment>
        <GlobalStyles />
        <TimePickerStyles />
        <LastLocationsProvider>
          <Switch>
            <GuestRoute exact path="/" component={LoginPageResolver} />
            <Route path="/account" component={Account} />
            <Route path="/opportunities" component={Opportunity} />
            <Route path="/invoices" component={Invoice} />
            <Route path="/workorders" component={Workorders} />
            {/* <Route path="/rubicon-select" component={RubiconSelectPage} /> */}
            <Route path="/payments" component={PaymentsPage} />
            <Route path="/hauler-profile" component={HaulerProfile} />
            <AdminRoute exact path="/select-default-vendor" component={SelectDefaultVendorPageResolver} />
            <Route component={AppRouter} />
          </Switch>
          {/* {vendorId && <SOSAlert vendorId={vendorId} />}
          {userId && <VideoMessageAlert userId={userId} />} */}
          <NotificationList />
        </LastLocationsProvider>
      </Fragment>
    </ConnectedRouter>
  );
};

render(
  <ThemeProvider theme={theme}>
    <Provider store={store}>
      <Root />
    </Provider>
  </ThemeProvider>,
  document.getElementById('root'),
);
