import { rgba } from 'polished';
import styled, { css } from 'styled-components';

import { ButtonWrapperWithPopover } from '../../../common/components/styled';
import { BareButtonStyle } from '../../../core/components/styled';
import { mapper, sizeMapper } from '../../../utils/styles';

interface Props {
  border?: boolean;
  size?: string;
  color?: string;
  padding?: string;
  margin?: string;
  align?: string;
  vertical?: boolean;
  verticalCenter?: boolean;
  requiresRightMargin?: boolean;
  noWrap?: boolean;

  /**
   * This property will set the basic filter width which
   * will not shrink wether more filters are added, but
   * will overflow in a scrolling manner. This width will
   * not surpass the @param maxFilterWidth in any case.
   *
   * Both @param filterWidth and @param maxFilterWidth are
   * used as proportions from the parent.
   *
   * Example:
   * filterWidth = 6
   * maxFilterWidth = 5
   * The filter will have a width of 100% / 6, which is a
   * greater width than 100% / 5. Careful with this. :D
   */
  filterWidth?: number;

  /**
   * This property restricts the filter entry width so it
   * would not expand throughout the entire parent's space.
   * This is mainly used when there are not enough filters
   * so all the space in the parent is used, thus making the
   * filter to maintain a small proportion.
   */
  maxFilterWidth?: number;
}

export const ActionRequiredContainer = styled.div<Props>`
  border-left: 2px solid lightslategrey;
  padding: 0px 5px;
  ${props =>
    props.border &&
    css`
      border: none;
    `}
`;

export const ServiceIconImage = styled.img<{ width?: string }>`
  width: ${p => p.width || '50px'};
  border-radius: 3px 3px 0 0;
  cursor: pointer;
`;

export const RubiconServicesIconImage = styled.img<{ width?: string }>`
  width: ${p => p.width || '50px'};
  border-radius: 3px 3px 0 0;
`;
export const PopoverDetailsContainer = styled.div`
  display: flex;
  text-align: left;
  & > div {
    margin-top: 20px;
  }
`;

export const StatusFilterButton = styled.button<{ isActive?: boolean; small?: boolean }>`
  ${BareButtonStyle}
  display: flex;
  flex-direction: column;
  padding: ${p => (p.small ? '5px' : '20px')};
  border: 1px solid ${props => (props.isActive ? props.theme.brandPrimary : props.theme.gray)};
  border-radius: 6px;
  width: 100%;
  height: ${p => (p.small ? '65px' : '90px')};
  text-align: left;

  ${props =>
    props.isActive &&
    css`
      background-color: ${rgba(props.theme.brandPrimary, 0.13)};
    `}
`;

export const StatusFiltersContainer = styled.div<Props>`
  width: 100%;
  display: flex;
  overflow: auto;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  white-space: ${p => (p.noWrap ? 'nowrap' : 'initial')};

  & ${ButtonWrapperWithPopover} {
    margin: 5px;
    flex: 1 0 ${props => `calc(100% / ${props.filterWidth || 6} - 10px)`};
    max-width: ${props => `calc(100% / ${props.maxFilterWidth || props.filterWidth || 6} - 10px)`};
  }

  ${props =>
    props.verticalCenter &&
    css`
      align-items: center;
    `}

  ${props =>
    props.requiresRightMargin &&
    css`
      &:after {
        content: '';
        flex: 1 0 5px;
      }
    `}
`;

interface DetailsContainerProps {
  padding?: string;
  noShadow?: boolean;
}

export const DetailsContainer = styled.div<DetailsContainerProps>`
  display: flex;
  position: relative;
  padding: ${props => sizeMapper(props.padding, 'no xSmall')};
  width: 100%;

  ${props =>
    !props.noShadow &&
    css`
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: 100px;
        background: linear-gradient(to right, transparent, white);
        pointer-events: none;
      }
    `}
`;

interface ForwardIconContainerProps {
  border?: boolean;
  flex?: boolean;
}

export const ForwardIconContainer = styled.div<ForwardIconContainerProps>`
  margin: 0 6px;

  ${props =>
    props.border &&
    css`
      margin: 0 2px 0 2px;
      border-right: 2px solid #00a599;
    `};

  ${props =>
    props.flex &&
    css`
      display: flex;
    `};
`;

export const WorkOrderLabel = styled.span<Props>`
  display: inline-block;
  width: ${props => mapper(props.size, { auto: 'auto', small: '60px', medium: '80px', large: '100px' }, 'auto')};
  max-width: 100%;
  height: 22px;
  overflow: hidden;
  border-radius: 5px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  line-height: 32px;
  font-weight: ${props => props.theme.fontWeightMedium};
  font-size: ${props => mapper(props.size, { small: '12px', medium: '14px', large: '16px' }, 'large')};
  color: ${props => props.theme.brandDefault};
`;
