import { GoogleMap, InfoWindow, Marker, MarkerClusterer, OverlayView, Polygon, useJsApiLoader } from '@react-google-maps/api';
import React, { ReactElement, ReactNode, useCallback, useEffect, useState } from 'react';
import TooltipIconButton from 'src/core/components/TooltipIconButton';
import { IconButton, IconButtonIcon } from 'src/core/components/styled';
import { MAP_CENTER, MAP_DEFAULT_ZOOM_SMALL } from 'src/core/constants';
import { MAP_CITY_ZOOM_IN_BIGGEST, MAP_DEFAULT_ZOOM } from 'src/core/constants/mapOptions';
import { getGoogleMapApiKey } from 'src/core/services/environment';
import translate from 'src/core/services/translate';
import { theme } from 'src/core/styles';
import { ComplexMapControl } from 'src/routes/components/styled/RouteMap';
import { SERVICE_AREAS_OPTIONS_SAT } from 'src/routes/constants';
import defaultPinIcon from './../../assets/img/common/mapPin.svg';
import { MapContainer, MapPopup, MapPopupContent, MapPopupTitle } from './Styles';
import { getMapBounds } from './util';

export type MarkerItem = {
  id: string;
  position: google.maps.LatLngLiteral;
  label?: string;
  title?: string; 
  text?: string | ReactNode;
  icon?: google.maps.Icon;
}

interface Props {
  clickable?: boolean;
  markers?: MarkerItem[];
  polygons?: Array<{
    title: string; 
    text: string | ReactNode;
    points: google.maps.LatLngLiteral[];
  }>;
  onSelectMarker?: (id: string) => void;
  onDeselectMarker?: (id: string) => void;
  legendComponent?: ReactElement;
  tooltipComponent?: ReactElement;
  size?: string;
  height?: string;
  initialZoom?: number;
  showSatellite?: boolean;
}

const MapComponent: React.FC<Props> = ({ clickable = true, markers, polygons, onSelectMarker, onDeselectMarker, legendComponent, tooltipComponent, size, height, initialZoom, showSatellite = true }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: getGoogleMapApiKey() || ''
  })

  const [map, setMap] = useState<google.maps.Map | null>(null);
  const [zoom, setZoom] = useState<number>(MAP_DEFAULT_ZOOM);
  const [isColorized, setIsColorized] = useState<boolean>(true);
  const [mapType, setMapType] = useState(google.maps.MapTypeId.ROADMAP);
  const [selectedMarker, setSelectedMarker] = useState<MarkerItem | null>(null);

  const onLoad = useCallback((loadedMap: google.maps.Map) => {
    setMap(loadedMap);
  }, [])

  const onUnmount = useCallback(() => {
    setMap(null);
  }, [])

  const onZoomChanged = useCallback(() => {
    if (map) {
      const newZoom = map.getZoom();
      if (typeof newZoom === 'number') {
        setZoom(newZoom);
      }
    } 
  }, [map])

  const onZoomIn = () => {
    if (map) {
      map.setZoom(zoom + 1);
    }
  };

  const onZoomOut = () => {
    if (map) {
      map.setZoom(zoom - 1);
    }
  };

  const onColorize = () => {
    setIsColorized(!isColorized);
  };
  
  const toggleMapType = () => {
    if (mapType === google.maps.MapTypeId.ROADMAP) {
      setMapType(google.maps.MapTypeId.SATELLITE);
    } else {
      setMapType(google.maps.MapTypeId.ROADMAP);
    }
  };

  useEffect(() => {
    if (map) {
      const points: google.maps.LatLngLiteral[] = [];
      if (markers?.length) {
        points.push(...markers.map(m => m.position));
      } else if (polygons?.length) {
        points.push(...polygons.flatMap(polygon => polygon.points));
      }
      if (points.length > 0) {
        const bounds = getMapBounds(points);
        const boundCenter = {
          lat: bounds.getCenter().lat(),
          lng: bounds.getCenter().lng(),
        }
        map.setCenter(boundCenter);
        if (initialZoom) {
          map.setZoom(initialZoom);
        } else {
          map.setZoom(MAP_DEFAULT_ZOOM);
          map.fitBounds(bounds);
        }
      } else {
        map.setCenter({ lat: MAP_CENTER.lat(), lng: MAP_CENTER.lng() });
        map.setZoom(MAP_DEFAULT_ZOOM);
      }
      setSelectedMarker(null);
    }
  }, [map, markers, polygons, initialZoom]);

  if (loadError) return <div>Error loading map</div>;
  if (!isLoaded) return <div>Loading map...</div>;

  const isSatellite = mapType === google.maps.MapTypeId.SATELLITE;

  return (
    <MapContainer size={size} height={height}>
      <ComplexMapControl vertical position="bottom-left" xOffset={-10} yOffset={5} zIndex={100}>
        <TooltipIconButton
          tooltip="zoomIn"
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color="secondary"
          margin="no"
          onClick={onZoomIn}
          disabled={zoom === MAP_CITY_ZOOM_IN_BIGGEST}
        >
          <IconButtonIcon icon="mapboxZoomIn" />
        </TooltipIconButton>
        <TooltipIconButton
          tooltip="zoomOut"
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color="secondary"
          margin="xxSmall no no"
          onClick={onZoomOut}
          disabled={zoom === MAP_DEFAULT_ZOOM_SMALL}
        >
          <IconButtonIcon icon="mapboxZoomOut" />
        </TooltipIconButton>
        <TooltipIconButton
          tooltip={isColorized ? 'colorizeMapDec' : 'colorizeMap'}
          tooltipPosition="right"
          tooltipColor="grayDarker"
          color={isColorized ? 'primary' : 'secondary'}
          margin="xxSmall no no"
          onClick={onColorize}
          disabled={isSatellite}
        >
          <IconButtonIcon icon="layers" />
        </TooltipIconButton>
        {showSatellite ? (
          <TooltipIconButton
            tooltip={isSatellite ? 'disableSatelliteView' : 'enableSatelliteView'}
            tooltipPosition="right"
            tooltipColor="grayDarker"
            color={isSatellite ? 'primary' : 'secondary'}
            margin="xxSmall no no"
            onClick={toggleMapType}
          >
            <IconButtonIcon icon="map" />
          </TooltipIconButton>
        ) : null}
      </ComplexMapControl>
      {legendComponent ? (
        <ComplexMapControl vertical position="bottom-right" xOffset={-10} yOffset={5} zIndex={100}>
          {legendComponent}
        </ComplexMapControl>
      ) : null}
      <GoogleMap
        mapContainerStyle={{ width: '100%', height: '100%' }}
        options={{
          disableDefaultUI: true,
          zoomControl: false,
          clickableIcons: false,
          styles: isColorized ? null : [
            { featureType: 'landscape', stylers: [ { saturation: -100 }, { gamma: 1.5 } ] },
            { featureType: 'road', stylers: [ { saturation: -100 }, { gamma: 1.5 } ] },
            { featureType: 'administrative', stylers: [ { saturation: -100 }, { gamma: 1.5 } ] },
            { featureType: 'poi', stylers: [ { saturation: -100 }, { gamma: 1.5 } ] },
            { featureType: 'transit', stylers: [ { saturation: -100 }, { gamma: 1.5 } ] },
          ]
        }}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onZoomChanged={onZoomChanged}
        mapTypeId={mapType}
      >
        {markers && markers.length > 0 ? (
          <MarkerClusterer
            calculator={(markers) => {
              const count = markers.length;
              const index = count >= 750 ? 3 : count >= 100 ? 2 : 1;
              return { text: String(count), index };
            }}
            styles={[
              { url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><circle cx="24" cy="24" r="20" fill="%2351bbd6"/></svg>', height: 50, width: 40, textColor: theme.black },
              { url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><circle cx="24" cy="24" r="20" fill="%23f1f075"/></svg>', height: 75, width: 60, textColor: theme.black },
              { url: 'data:image/svg+xml;charset=UTF-8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48"><circle cx="24" cy="24" r="20" fill="%23f28cb1"/></svg>', height: 100, width: 80, textColor: theme.black },
            ]}
          >
          {(clusterer) =>
            markers.map((marker) => (
              <Marker
                key={marker.id}
                position={marker.position}
                icon={marker.icon || {
                  url: defaultPinIcon,
                  origin: new google.maps.Point(0, 0),
                  scaledSize: new google.maps.Size(30, 30)
                }}
                label={marker.label ? ({
                  text: marker.label,
                  color: '#000000',
                  fontWeight: 'bold'
                } as google.maps.MarkerLabel) : undefined}
                clusterer={clusterer}
                clickable={Boolean(clickable && marker.title)}
                onClick={() => {
                  onSelectMarker?.(marker.id);
                  if (clickable && marker.title) {
                    setSelectedMarker(marker);
                  }
                }}
              />
            ))
          }
        </MarkerClusterer>
        ) : null}
        {polygons && polygons.length > 0 ? polygons.map((polygon, index) => {
          const polyCenter = getMapBounds(polygon.points).getCenter();
          return (
            <React.Fragment key={index}>
              <Polygon
                paths={polygon.points}
                options={{
                  fillColor: isSatellite ? SERVICE_AREAS_OPTIONS_SAT.fillColor : theme.brandPrimary,
                  fillOpacity: 0.1,
                  strokeColor: isSatellite ? SERVICE_AREAS_OPTIONS_SAT.fillColor : theme.brandPrimary,
                  strokeOpacity: 1,
                  strokeWeight: 1.5,
                }}
                onClick={() => clickable && polygon.title ? setSelectedMarker({
                  id: polygon.title,
                  position: { lat: polyCenter.lat(), lng: polyCenter.lng() },
                  title: polygon.title,
                  text: polygon.text
                }) : null}
              />
              <OverlayView
                position={polyCenter}
                mapPaneName={OverlayView.OVERLAY_LAYER}
                >
                  <div style={{ 
                    color: isSatellite ? SERVICE_AREAS_OPTIONS_SAT.fillColor : theme.brandPrimary,
                    fontSize: zoom || 12,
                    textShadow: `0 0 10px ${isSatellite ? theme.black : theme.brandWhite}, 0 0 15px ${isSatellite ? theme.black : theme.brandWhite}, 0 0 20px ${isSatellite ? theme.black : theme.brandWhite}`,
                    visibility: zoom >= 10 ? 'visible' : 'hidden',
                  }}>
                    <strong>{polygon.title}</strong>
                  </div>
              </OverlayView>
            </React.Fragment>
        )}) : null}
        {selectedMarker && selectedMarker.title ? (
          <InfoWindow
            position={selectedMarker.position}
            options={{ pixelOffset: new google.maps.Size(0, -20) }}
            onCloseClick={() => {
              setSelectedMarker(null);
              onDeselectMarker?.(selectedMarker.id);
            }}
          >
            <MapPopup>
              <IconButton
                style={{position: 'absolute', right: 0, top: 0}}
                margin="no" size="xSmall" color="secondary" title={translate('common.close')}
                onClick={() => {
                  setSelectedMarker(null);
                  onDeselectMarker?.(selectedMarker.id);
                }}
              >
                <IconButtonIcon icon="close"/>
              </IconButton>
              {tooltipComponent ? tooltipComponent : 
              <>
                <MapPopupTitle title={selectedMarker.title}/>
                <MapPopupContent>
                  {selectedMarker.text}
                </MapPopupContent>
              </>}
            </MapPopup>
          </InfoWindow>
        ) : null}
      </GoogleMap>
    </MapContainer>
  );
}

export default React.memo(MapComponent);
