import { http } from '../../core/services/http';

export const uploadDocument = (file: any, vendorId: number) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post(`vendor/${vendorId}/weight-tickets/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};

export const uploadDocumentByToken = (file: any, token: string) => {
  const formData = new FormData();
  formData.append('file', file);

  return http
    .post(`gus/${token}/weight-tickets/files`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then(response => response.data);
};
