import { http } from 'src/core/services/http';

export const loadStreetNetwork = (
  vendorId: number,
  routeId?: number,
  routeTemplateId?: number,
  includeRouteTemplateIds?: boolean,
  streetSegmentAssignedStatus?: boolean,
  streetSegmentAssignedTypesStatus?: string | number,
  streetSegmentStatus?: boolean,
  streetSegmentNumberOfLanes?: string,
  streetSegmentNumberOfPasses?: string,
  streetSegmentServiceSides?: string,
  driverIds?: string,
  pickupStatusTypeIds?: string,
  segmentsFilterEndDate?: Date | string,
  segmentsFilterStartDate?: Date | string,
  alternativeFleetServiceTypeIds?: string,
  vehicleTypeId?: number,
) => {
  const streetSegmentAssignedTypesStatusFormatted =
    typeof streetSegmentAssignedTypesStatus === 'string' && streetSegmentAssignedTypesStatus?.length
      ? streetSegmentAssignedTypesStatus
          .split(',')
          .map(status => Number(status) + 1)
          .toString()
      : streetSegmentAssignedTypesStatus;

  return http
    .get(`${vendorId}/streetNetwork/segments`, {
      params: {
        routeId,
        routeTemplateId,
        includeRouteTemplateIds,
        isAssigned: routeId ? streetSegmentAssignedStatus : undefined,
        isActive: streetSegmentStatus,
        lanesFilter: streetSegmentNumberOfLanes,
        passesFilter: streetSegmentNumberOfPasses,
        serviceSideTypeIds: streetSegmentServiceSides,
        assignmentTypes:
          routeTemplateId || routeTemplateId === 0 ? streetSegmentAssignedTypesStatusFormatted : undefined,
        driverIds,
        pickupStatusTypeIds,
        startDate: segmentsFilterStartDate,
        endDate: segmentsFilterEndDate,
        alternativeFleetServiceTypeIds,
        vehicleTypeId,
      },
    })
    .then(response => response.data);
};
