import { mapKeys } from 'lodash-es';
import translate from '../../core/services/translate';

export const RUBICON_X = 1;
export const RUBICON_Y = 2;
export const RUBICON_XY = 3;
export const RUBICON_Z = 4;
export const RUBICON_XZ = 5;
export const RUBICON_YZ = 6;
export const RUBICON_XYZ = 7;

export const DEVICE_ROLE_TYPES = mapKeys(
  [
    { id: RUBICON_X, name: translate('vendors.deviceRoleTypes.rubiconX'), technicalName: 'RubiconX' },
    { id: RUBICON_Y, name: translate('vendors.deviceRoleTypes.rubiconY'), technicalName: 'RubiconY' },
    { id: RUBICON_XY, name: translate('vendors.deviceRoleTypes.rubiconXY'), technicalName: 'RubiconXY' },
    { id: RUBICON_Z, name: translate('vendors.deviceRoleTypes.rubiconZ'), technicalName: 'RubiconZ' },
    { id: RUBICON_XZ, name: translate('vendors.deviceRoleTypes.rubiconXZ'), technicalName: 'RubiconXZ' },
    { id: RUBICON_YZ, name: translate('vendors.deviceRoleTypes.rubiconYZ'), technicalName: 'RubiconYZ' },
    { id: RUBICON_XYZ, name: translate('vendors.deviceRoleTypes.rubiconXYZ'), technicalName: 'RubiconXYZ' },
  ],
  'id',
);
