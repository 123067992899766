import { FC, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from '../../../store';
import { ACCOUNT_SETTINGS_EDIT_VENDOR } from '../../../account/constants';
import { ActionButtonTooltip } from '../../../core/components';
import { AdminOnlyGuard, PermissionGuard } from '../../../account/components';
import {
  VendorEditorModal,
} from '../modals';
import {
  Button,
  ButtonLink,
  Grid,
  GridColumn,
  Panel,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import { changePassword } from '../../ducks';
import { createErrorNotification, createSuccessNotification } from '../../../core/services/createNotification';
import {
  PageActions,
  PageContent,
  PageDetails,
  PageHeader,
  PageTitle,
  PageTitleContainer,
} from '../../../common/components/styled';
import { User } from '../../../account/ducks/login';
import AccountSettingsUserForm from '../forms/AccountSettingsUserForm';
import AccountSettingsVendorDisplay from '../forms/AccountSettingsVendorDisplay';
import createTranslationKey from '../../../utils/services/createTranslationKey';
import translate from '../../../core/services/translate';

const AccountSettingsPage: FC = () => {
  const dispatch = useDispatch();

  const [createNewVendor, setCreateNewVendor] = useState(false);
  const [userProfileInEditMode, setUserProfileInEditMode] = useState(false);
  const [vendorModalOpen, setVendorModalOpen] = useState(false);

  const rawUser = useSelector((state: AppState) => state.account.login.user);
  const vendor = useSelector((state: AppState) => state.vendors.vendor.vendor);
  const currentUser = useSelector((state: AppState) => state.vendors.users.user);

  const user = useMemo(
    () => ({
      ...rawUser,
      name: rawUser ? rawUser.name : '-',
      password: userProfileInEditMode ? '' : new Array(17).join(' '),
    }),
    [rawUser, userProfileInEditMode],
  );

  const toggleUserProfileEditMode = () => {
    setUserProfileInEditMode(!userProfileInEditMode);
  };

  const openCreateVendorModal = () => {
    setVendorModalOpen(true);
    setCreateNewVendor(true);
  };

  const openEditVendorModal = () => {
    setVendorModalOpen(true);
    setCreateNewVendor(false);
  };

  const closeVendorModal = () => {
    setVendorModalOpen(false);
    setCreateNewVendor(false);
  };

  const onSubmitChangePassword = (formData: any) => {
    changePassword(formData)(dispatch)
      .then(() => {
        createSuccessNotification(translate('vendors.alertMessages.passwordChanged'));
        toggleUserProfileEditMode();
      })
      .catch((e: { code: string; message: string }) => {
        createErrorNotification(`${translate(createTranslationKey(e.code, 'vendors.alertMessages'))}`);
      });
  };

  return (
    <PageContent>
      <PageHeader>
        <PageDetails>
          <PageTitleContainer>
            <PageTitle>{translate('common.applicationSettings')}</PageTitle>
          </PageTitleContainer>
        </PageDetails>

        <PageActions>
          <ButtonLink color="alert" line to="/account/logout">
            {translate('account.logout')}
          </ButtonLink>

          <AdminOnlyGuard>
            <Button color="primary" margin="no no no small" onClick={openCreateVendorModal}>
              {translate('vendors.createVendor')}
            </Button>
          </AdminOnlyGuard>
        </PageActions>
      </PageHeader>

      <Panel padding="medium">
        <PanelSection withBorder padding="no no medium">
          <PanelSectionGroup withBorder padding="no medium no no" width="33.33%">
            <Grid margin="no no medium">
              <GridColumn size="9/12" padding="no">
                <PanelSectionTitle margin="no">{translate('vendors.userProfile')}</PanelSectionTitle>
              </GridColumn>

              <GridColumn size="3/12" padding="no" align="right" verticalAlign="center">
                <Button
                  text
                  padding="no"
                  color={userProfileInEditMode ? 'alert' : 'grayDark'}
                  onClick={toggleUserProfileEditMode}
                >
                  <ActionButtonTooltip
                    iconSize="sMedium"
                    tooltip={userProfileInEditMode ? 'cancel' : 'editUser'}
                    icon={userProfileInEditMode ? 'close' : 'edit'}
                  />
                </Button>
              </GridColumn>
            </Grid>

            <AccountSettingsUserForm
              onSubmit={onSubmitChangePassword}
              passwordEditable={userProfileInEditMode}
              initialValues={user}
            />
          </PanelSectionGroup>

          <PanelSectionGroup padding="no no no medium" width="66.66%">
            <Grid margin="no no medium">
              <GridColumn size="11/12" padding="no">
                <PanelSectionTitle margin="no">{translate('vendors.companyConfiguration')}</PanelSectionTitle>
              </GridColumn>

              <PermissionGuard permission={ACCOUNT_SETTINGS_EDIT_VENDOR}>
                <GridColumn size="1/12" padding="no" align="right" verticalAlign="center">
                  <Button text padding="no" color="grayDark" onClick={openEditVendorModal}>
                    <ActionButtonTooltip iconSize="sMedium" tooltip="editVendor" icon="edit" />
                  </Button>
                </GridColumn>
              </PermissionGuard>
            </Grid>

            <AccountSettingsVendorDisplay roleId={currentUser ? (currentUser as User).roleId : undefined} />
          </PanelSectionGroup>
        </PanelSection>
      </Panel>

      {vendorModalOpen && <VendorEditorModal vendor={createNewVendor ? undefined : vendor} close={closeVendorModal} />}
    </PageContent>
  );
};

export default AccountSettingsPage;
