import LabeledDataView from "src/core/components/LabeledDataView";
import { FormGroup, Text } from 'src/core/components/styled';
import { Box } from 'src/core/components/styled/Box';
import translate from "src/core/services/translate";
import { rotate } from "src/core/styles";
import { ALL_EQUIPMENTS_TYPES, EQUIPMENT_TYPES } from "src/haulerProfile/constants";
import { Equipment, Material, ZipCode } from "src/haulerProfile/interfaces/ServiceArea";
import { getEquipmentTypeId } from "src/haulerProfile/utils/equipments";
import { mapper, sizeMapper } from "src/utils/styles";
import styled, { css } from "styled-components";

const mapPopupWidth = 315;
const mapPopupMinHeight = 93;

export const MapContainer = styled.div<{ size?: string, height?: string }>`
  width: 100%;
  height 100%;
  position: relative;

  *:focus {
    outline: none;
  }

  ${props =>
    props.size &&
    css`
      height: ${mapper(
        props.size,
        {
          small: '300px',
          medium: '400px',
          large: '500px',
        },
        'medium',
      )};
      min-height: 100%;
      max-height: 100%;
    `};
  
  ${props =>
    props.height &&
    css`
      height: ${props.height};
    `}

  .gm-style-iw-chr {
    display: none !important;
  }
  .gm-style-iw-d {
    margin-top: 20px;
  }
`;

export const MapPopup = styled.div<{ minHeight?: number; maxHeight?: number; width?: number }>`
  display: flex;
  flex-wrap: wrap;
  width: ${p => p.width || mapPopupWidth}px;
  min-height: ${p => (p.minHeight ? `${p.minHeight}px` : `${mapPopupMinHeight}px`)};
  max-height: ${p => (p.maxHeight ? `${p.maxHeight}px` : '100%')};
  align-items: flex-start;
  overflow-y: auto;
  overflow-x: hidden;

  & > ${Text} {
    flex: 0 0 100%;
  }
`;

interface MapPopupTitleProps {
  title: string;
  beforeTitleContent?: React.ReactNode;
  afterTitleContent?: React.ReactNode;
  secondTitle?: React.ReactNode;
}
export const MapPopupTitle: React.FC<MapPopupTitleProps> = ({
  title,
  beforeTitleContent = null,
  afterTitleContent = null,
  secondTitle = null,
}) => (
  <>
    <Box display="flex" alignItems="center" width="100%" pl={4} pb={8}>
      {beforeTitleContent}

      <Text weight="medium" size="medium">
        {title}
      </Text>

      {afterTitleContent}
    </Box>
    {secondTitle}
  </>
);

interface MapPopupContentProps {
  padding?: string;
  verticalAllign?: boolean;
}
export const MapPopupContent = styled.div<MapPopupContentProps>`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  padding: ${props => sizeMapper(props.padding, 'no')};

  & > ${FormGroup} {
    margin: 5px 5px 0;
  }
  ${props =>
    props.verticalAllign &&
    css`
      align-items: center;
    `};
`;

export const ZipAreaPopupContent = (zipCode: {
  equipments: Equipment[];
  materials: Material[];
  zipCode: ZipCode;
} | undefined, equipmentTypes: string, equipmentSizes?: string[]) => {
  return (
    <>
      {EQUIPMENT_TYPES.filter(et => equipmentTypes === ALL_EQUIPMENTS_TYPES || equipmentTypes === et).map(
        (equipmentType, index) => {
          const equipmentSizesByZip = (zipCode?.equipments || [])
            .filter(equipment => equipmentType === getEquipmentTypeId(equipment))
            .map(
              equipment =>
                equipment.other || translate(`haulerProfile.equipments.equipmentTypes.${equipment.binClass}`),
            );

          const allEquipmentSize = equipmentSizes?.length ? equipmentSizes : equipmentSizesByZip;

          return allEquipmentSize?.length ? (
            <LabeledDataView
              width="100%"
              size="small"
              label={translate(`haulerProfile.equipments.equipmentSubTypes.${equipmentType}`)}
              value={allEquipmentSize?.join(', ')}
              key={index}
            />
          ) : null;
        },
      )}
    </>
  )
}

export const MapPopupLoading = styled.div<{ minHeight?: number; width?: number }>`
  position: relative;
  width: ${p => p.width || mapPopupWidth}px;
  height: ${p => p.minHeight || mapPopupMinHeight}px;

  &:after {
    content: '';
    position: absolute;
    box-sizing: content-box;
    top: calc(50% - 9.5px);
    left: calc(50% - 9.5px);
    width: 15px;
    height: 15px;
    border-radius: 15px;
    border: 2px solid ${props => props.theme.brandPrimary};
    border-right-color: transparent;
    animation: ${rotate} 1s linear infinite;
    z-index: 1;
  }
`;
