import axios, { ResponseType } from 'axios';
import humps from 'humps';
import { isPlainObject, isArray } from 'lodash-es';

import { getCurrentLanguageId } from './i18n';
import {
  getApiBaseURL,
  getInsightsReportingApiBaseURL,
  getCustomerCoreApiBaseURL,
} from './environment';
import { API_KEY, LANGUAGE_OPTIONS } from '../constants';

const transformResponse = (data: any) => (isPlainObject(data) || isArray(data) ? humps.camelizeKeys(data) : data);

const getAcceptLanguage = () => {
  const currentLanguageId = Number(getCurrentLanguageId());
  return LANGUAGE_OPTIONS.find(language => language.id === currentLanguageId)?.name || LANGUAGE_OPTIONS[0].name;
};

const defaultHttpSettings = {
  responseType: 'json' as ResponseType,
  headers: {
    'ocp-apim-subscription-key': API_KEY,
    'Content-Type': 'application/json',
    'Accept-Language': getAcceptLanguage(),
  },
  transformResponse: (axios.defaults.transformResponse as Array<any>).concat(transformResponse),
};

export const http = axios.create({
  ...defaultHttpSettings,
  baseURL: `${getApiBaseURL()}/`,
});

export const httpInsightsReporting = axios.create({
  ...defaultHttpSettings,
  baseURL: `${getInsightsReportingApiBaseURL()}/`,
});

export const httpCustomerCore = axios.create({
  ...defaultHttpSettings,
  baseURL: `${getCustomerCoreApiBaseURL()}/`,
});
