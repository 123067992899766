import { Fragment, useEffect, useState } from 'react';
import { Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { get } from 'lodash-es';
import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  VendorTypeDropdown,
} from '..';
import { AdminGuard } from '../../../account/components';
import {
  Checkbox,
  Input,
  LocationPicker,
} from '../../../core/components';
import {
  Button,
  ButtonSet,
  Grid,
  GridColumn,
  ModalClose,
  ModalCloseIcon,
  PanelSection,
  PanelSectionGroup,
  PanelSectionTitle,
} from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { AppState } from '../../../store';
import focusFirstInvalidField from '../../../utils/services/focusFirstInvalidField';
import {
  hasCity,
  hasCoordinates,
  hasCountry,
  hasStreet,
  hasStreetNumber,
  hasZip,
  isEmail,
  isRequired,
  maxLength3,
  maxLength50,
} from '../../../utils/services/validator';
import {
  RUBICON_PRO,
  SMART_CITY,
} from '../../constants';
import { Vendor } from '../../interfaces/Vendors';

export interface VendorEditorFormValues extends Vendor {
  deviceRoles?: number[];
  xDeviceTypeId?: number;
}

interface PropsWithoutReduxForm {
  onCancel: (isPristine: boolean) => void;
  vendor?: Vendor;
  systemOfMeasurementId: number;
  isAutoSnapToCurbDisabled?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<VendorEditorFormValues, PropsWithoutReduxForm>;

const VendorEditorForm = (props: Props) => {
  const { vendor, handleSubmit, onCancel, pristine, change } = props;

  const formSelector = formValueSelector('vendorEditorForm');
  const selectedFormValues = useSelector((state: AppState) =>
    formSelector(
      state,
      'activateAppLicenses',
      'endDate',
      'isActive',
      'startDate',
      'vendorTypeId',
      'deviceRoles',
      'fleetRouteInstanceId',
    ),
  );

  const startDateValue = get(selectedFormValues, 'startDate');
  const endDateValue = get(selectedFormValues, 'endDate');

  const [values, setValues] = useState({
    currentValues: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    prevValues: {
      startDate: startDateValue,
      endDate: endDateValue,
    },
    changedInput: '',
  });

  const trimValue = (value: string) => value && value.trim();
  const existDates = startDateValue && endDateValue;
  const existPrevDates = values.prevValues.startDate && values.prevValues.endDate;

  const resetEndDate = moment(startDateValue).diff(moment(endDateValue), 'days') > 0;

  useEffect(() => {
    if (startDateValue && !values.currentValues.startDate) {
      setValues({
        currentValues: { startDate: startDateValue, endDate: endDateValue },
        prevValues: { startDate: values.prevValues.startDate, endDate: values.prevValues.endDate },
        changedInput: '',
      });
    }

    if (
      values.changedInput === 'endDate' &&
      (startDateValue || values.currentValues.startDate) &&
      endDateValue &&
      moment(endDateValue).diff(moment(startDateValue), 'days') < 0 &&
      moment(values.prevValues.endDate).format('MM/DD/YYYY') === moment(endDateValue).format('MM/DD/YYYY')
    ) {
      setValues({
        prevValues: {
          startDate: values.currentValues.startDate,
          endDate: null,
        },
        currentValues: { startDate: values.currentValues.startDate, endDate: null },
        changedInput: '',
      });
      change('endDate', null);
    }

    if (
      (!values.currentValues.startDate || values.currentValues.startDate === 'Invalid date') &&
      values.currentValues.endDate
    ) {
      setValues({
        prevValues: {
          startDate: values.currentValues.startDate,
          endDate: null,
        },
        currentValues: { startDate: values.currentValues.startDate, endDate: null },
        changedInput: '',
      });
      change('endDate', null);
    }

    if (
      (values.prevValues.startDate !== values.currentValues.startDate ||
        values.prevValues.endDate !== values.currentValues.endDate) &&
      existDates &&
      existPrevDates
    ) {
      if (values.changedInput === 'startDate' && values.prevValues.startDate.length && resetEndDate) {
        setValues({
          prevValues: { startDate: values.currentValues.startDate, endDate: null },
          currentValues: { startDate: values.prevValues.startDate, endDate: null },
          changedInput: '',
        });
        change('startDate', startDateValue);
        change('endDate', null);
      } else if (
        values.changedInput === 'endDate' &&
        values.prevValues.endDate.length &&
        moment(endDateValue).diff(moment(startDateValue), 'days') < 0
      )
        change('endDate', moment(values.prevValues.endDate));
    }
  }, [change, endDateValue, startDateValue, existDates, existPrevDates, resetEndDate, values, vendor]);

  const renderCheckboxes = (shouldRender: boolean) => {
    if (!shouldRender) return;
    return (
      <AdminGuard>
        <GridColumn size="12/12" />
        <GridColumn size="4/12">
          <Field
            name="isActive"
            component={Checkbox}
            props={{
              label: translate('vendors.portalAccessEnabled'),
              margin: 'defaultCellVertical no',
            }}
            onChange={(event, newIsActive) => {
              if (!newIsActive) {
                change('activateAppLicenses', false);
              }
            }}
          />
        </GridColumn>

        <GridColumn size="4/12">
          <Field
            name="isInternalVendor"
            component={Checkbox}
            props={{
              label: translate('vendors.isInternalVendor'),
              margin: 'defaultCellVertical no',
            }}
          />
        </GridColumn>
        <GridColumn size="6/12" />
      </AdminGuard>
    );
  };

  const isPro = get(selectedFormValues, 'vendorTypeId') === RUBICON_PRO;
  const isSmartCity = get(selectedFormValues, 'vendorTypeId') === SMART_CITY;

  return (
    <Fragment>
      <ModalClose onClick={() => onCancel(pristine)}>
        <ModalCloseIcon />
      </ModalClose>
      <form onSubmit={handleSubmit} noValidate>
        <PanelSection padding="no">
          <PanelSectionGroup padding="no" width="100%">
            <Grid margin="no" multiLine>
              <GridColumn size="12/12">
                <PanelSectionTitle margin="no no small">{translate('vendors.vendorInformation')}</PanelSectionTitle>
              </GridColumn>

              <GridColumn size="12/12">
                <Field
                  name="name"
                  component={Input}
                  label={translate('common.name')}
                  validate={[isRequired, maxLength50]}
                />
              </GridColumn>

              <AdminGuard>
                <GridColumn size="4/12">
                  <Field name="vendorTypeId" component={VendorTypeDropdown} withLabel validate={[isRequired]} />
                </GridColumn>

                <GridColumn size="4/12">
                  <Field name="gusId" component={Input} label={translate('vendors.gusId')} normalize={trimValue} />
                </GridColumn>
              </AdminGuard>

              {!vendor && (
                <AdminGuard>
                  <GridColumn size="4/12">
                    <Field
                      name="haulerCode"
                      component={Input}
                      label={translate('vendors.haulerCode')}
                      validate={[isRequired, maxLength3]}
                      disabled={!!get(vendor, 'id')}
                    />
                  </GridColumn>
                </AdminGuard>
              )}

              <GridColumn size="12/12">
                <Field
                  name="homeAddress"
                  component={LocationPicker}
                  label={translate('vendors.companyAddress')}
                  validate={[isRequired, hasCoordinates, hasCountry, hasCity, hasZip, hasStreet, hasStreetNumber]}
                />
              </GridColumn>

              {!vendor && (
                <>
                  <GridColumn size="6/12">
                    <Field
                      name="sysAdminName"
                      component={Input}
                      label={translate('vendors.sysAdminName')}
                      validate={[isRequired]}
                    />
                  </GridColumn>

                  <GridColumn size="6/12">
                    <Field
                      name="inviteEmailAddress"
                      component={Input}
                      label={translate('vendors.sysAdminEmail')}
                      validate={[isRequired, isEmail]}
                    />
                  </GridColumn>
                </>
              )}
              {renderCheckboxes(!isPro && !isSmartCity)}
            </Grid>
          </PanelSectionGroup>
        </PanelSection>

        <PanelSection padding="large no no">
          <PanelSectionGroup padding="no" width="100%">
            <Grid margin="no" centered>
              <GridColumn size="12/12">
                <ButtonSet margin="no">
                  <Button type="submit" color="primary">
                    {translate('common.save')}
                  </Button>
                </ButtonSet>
              </GridColumn>
            </Grid>
          </PanelSectionGroup>
        </PanelSection>
      </form>
    </Fragment>
  );
};

export default reduxForm<VendorEditorFormValues, PropsWithoutReduxForm>({
  form: 'vendorEditorForm',
  onSubmitFail: focusFirstInvalidField,
  enableReinitialize: true,
})(VendorEditorForm);
