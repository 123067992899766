import translate from '../../core/services/translate';

export const STREET_SEGMENT_ASSIGNED_TYPES_STATUSES = [
  {
    id: 1,
    label: translate('customers.streetNetwork.status.assignedToThisRouteTemplate'),
  },
  {
    id: 2,
    label: translate('customers.streetNetwork.status.assignedToAnotherRouteTemplate'),
  },
  {
    id: 3,
    label: translate('customers.streetNetwork.status.notAssignedToAnyRouteTemplate'),
  },
];
