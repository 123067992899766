import { useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { RouteComponentProps, withRouter } from 'react-router';
import { MapPopupLoading } from 'src/common/components/googleMap/Styles';
import { TypedResolver } from 'src/core/components';
import { useSelector } from 'src/core/hooks/useSelector';
import { loadContainersForMapLocation } from 'src/fleet/ducks';
import { ContainerLocationForMap } from 'src/fleet/interfaces/containers';
import { RouteMapFeature } from 'src/routes/ducks/mapControls';
import { getQueryParams } from 'src/utils/services/queryParams';
import { currentVendorId } from 'src/vendors/services/currentVendorSelector';
import RubiconServicesTooltip from './RubiconServicesTooltip';

interface QueryParams {
  searchTerm?: string;
  containerTypeId?: number;
  locationId?: number;
  equipmentTypeId?: number;
  equipmentSizeId?: number;
  equipmentConditionId?: number;
}

interface Props extends RouteComponentProps {
  containerLocations: ContainerLocationForMap[];
}

const RubiconServicesTooltipResolver = ({ containerLocations, location: { search } }: Props) => {
  const dispatch = useDispatch();
  const { selectedFeature } = useSelector(state => state.routes.mapControls);
  const vendorId = useSelector(currentVendorId);

  const containerLocation: ContainerLocationForMap | undefined = useMemo(() => {
    if (!selectedFeature || selectedFeature.feature !== RouteMapFeature.containerLocation) {
      return undefined;
    }
    return containerLocations.find(loc => loc.lid === selectedFeature.id);
  }, [selectedFeature, containerLocations]);

  if (!containerLocation || !vendorId) {
    return null;
  }

  const loadDependencies = () => {
    if (!selectedFeature) return Promise.reject();

    const { searchTerm, containerTypeId, equipmentTypeId, equipmentSizeId, equipmentConditionId } =
      getQueryParams<QueryParams>(search);

    const dependencies = [
      loadContainersForMapLocation(
        vendorId,
        containerTypeId,
        equipmentTypeId,
        equipmentTypeId ? equipmentSizeId : undefined,
        equipmentConditionId,
        searchTerm,
        containerLocation.lid,
      )(dispatch),
    ];
    return Promise.all(dependencies);
  };

  return (
    <TypedResolver
      key={containerLocation.lid}
      successComponent={RubiconServicesTooltip}
      successProps={{ containerLocation }}
      loadingComponent={MapPopupLoading}
      loadingProps={{ latitude: containerLocation.la, longitude: containerLocation.lo }}
      resolve={loadDependencies}
    />
  );
};

export default withRouter(RubiconServicesTooltipResolver);
