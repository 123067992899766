import humps from 'humps';

import { getFeatureCollection, getPointFeature } from 'src/common/components/map/util';
import { PICKUP_STATUSES, WASTE_AUDIT } from 'src/common/constants';
import { NEW_INSIGHT_ICON_TYPES } from 'src/common/constants/insightIcons';
import {
  ACCOUNT_STATUSES,
  ACTIVE,
  COMPLETED,
  CONTAINER_NOT_OUT,
  EXTRA_PICKUP,
  ISSUE_REPORTED,
  SCHEDULED,
  SERVICED,
} from 'src/routes/constants';
import { RouteStop } from 'src/routes/interfaces/RouteStop';
import { RouteSummary } from 'src/routes/interfaces/RouteSummary';
import { ROUTE_MAP_ROUTE_STOPS_LAYER } from '../constants';

export type ContainerInsightFeatureProperties = {
  id: number;
  clickable: boolean;
  layer: string;
  icon?: string;
  orderNo?: number;
};

export const getRouteStopIconType = (routeStop: RouteStop, routeSummary?: RouteSummary) => {
  const accountStatus =
    routeStop.accountTypeId && routeStop.accountTypeId !== ACTIVE && routeStop.accountTypeId !== EXTRA_PICKUP
      ? ACCOUNT_STATUSES[routeStop.accountTypeId]?.technicalName
      : '';
  const flagged = routeStop.vendorLocationAlertIds?.length ? 'Flagged' : '';
  const assisted = routeStop.assistingVehicleRegplate ? 'Assisted' : '';
  let pickupStatus = PICKUP_STATUSES[routeStop.pickupStatusTypeId || SCHEDULED].technicalName;

  if (routeStop.pickupStatusTypeId === SERVICED)
    return NEW_INSIGHT_ICON_TYPES.find(
      ({ types }) => types.indexOf(humps.camelize(PICKUP_STATUSES[SERVICED].technicalName)) !== -1,
    );

  if (routeStop.numberOfIssues && routeSummary?.vehicleTypeName !== WASTE_AUDIT) {
    pickupStatus = PICKUP_STATUSES[ISSUE_REPORTED].technicalName;
    if (routeStop.pickupStatusTypeId === SCHEDULED) pickupStatus += 'NotServiced';
    if (routeStop.pickupStatusTypeId === COMPLETED) pickupStatus += 'Serviced';
    if (routeStop.pickupStatusTypeId === ISSUE_REPORTED) pickupStatus += 'NotServiced';
  }

  if (
    routeSummary?.vehicleTypeName === WASTE_AUDIT &&
    !!routeStop.numberOfIssues &&
    (routeStop.wasteAuditStatuses?.length || routeStop.wasteAuditLocationStatusTypeId === CONTAINER_NOT_OUT)
  ) {
    pickupStatus = PICKUP_STATUSES[ISSUE_REPORTED].technicalName;
    if (routeStop.pickupStatusTypeId === COMPLETED) pickupStatus += 'Serviced';
    if (routeStop.pickupStatusTypeId === ISSUE_REPORTED) pickupStatus += 'NotServiced';
  }

  if (routeStop.displayAsScheduled && routeStop.displayAsScheduled.get()) {
    pickupStatus = PICKUP_STATUSES[SCHEDULED].technicalName;
  }

  const insightType = `ic${accountStatus}${flagged}${assisted}${pickupStatus}`;

  return NEW_INSIGHT_ICON_TYPES.find(({ types }) => types.indexOf(insightType) !== -1);
};

export const getRouteStopsGeoJSON = (routeStops: RouteStop[], routeSummary?: RouteSummary) =>
  getFeatureCollection<GeoJSON.Point, ContainerInsightFeatureProperties>(
    routeStops.map(routeStop => {
      return getPointFeature(routeStop.id, [routeStop.displayLongitude, routeStop.displayLatitude], {
        id: routeStop.id,
        clickable: true,
        layer: ROUTE_MAP_ROUTE_STOPS_LAYER,
        icon: getRouteStopIconType(routeStop, routeSummary)?.id,
        orderNo: routeStop.orderNo,
        newOrderNumber: routeStop.newOrderNumber || undefined,
      });
    }),
  );
