import { combineReducers } from 'redux';

import { reducer as currencyOptions } from './currencyOptions';
import { reducer as customerReducer } from './customer';
import { reducer as customerTypesOptions } from './customerTypesOptions';
import { reducer as locationAlertsReducer } from './locationAlerts';
import { reducer as customerLocationsReducer } from './locations';
import { reducer as mapControlReducer } from './mapControl';
import { reducer as paymentTermOptionsReducer } from './paymentTermOptions';
import { reducer as rubiconServicesReducer } from './rubiconServices';
import { reducer as rubiconServicesDocumentsReducer } from './rubiconServicesDocuments';
import { reducer as rubiconServicesOptionsReducer } from './rubiconServicesOptions';
import { reducer as rubiconServicesRatesReducer } from './rubiconServicesRates';
import { reducer as temporaryAddressReducer } from './temporaryAddress';
import { reducer as vehiclesInProximity } from './vehiclesInProximity';

export {
  COMPLETE_LOAD as COMPLETE_LOAD_CUSTOMER,
  COMPLETE_SAVE as COMPLETE_SAVE_CUSTOMER,
  loadCustomer,
  resetCustomer,
  saveCustomer,
  setCurrentCustomersPageUrl
} from './customer';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_CUSTOMER,
  COMPLETE_LOAD as COMPLETE_LOAD_CUSTOMERS,
} from './customers';
export { loadCustomerTypes } from './customerTypesOptions';
export { loadLocationAlerts, resetLocationAlerts } from './locationAlerts';
export { loadRubiconServicesOptions } from './rubiconServicesOptions';
export {
  COMPLETE_DELETE as COMPLETE_DELETE_SERVICE,
  COMPLETE_SAVE as COMPLETE_SAVE_SERVICE,
} from './services';
export {
  ADD_SUSPENDED_LOCATION,
  COMPLETE_LOAD as COMPLETE_LOAD_SUSPENDED_LOCATIONS,
  COMPLETE_SAVE as COMPLETE_SAVE_SUSPENDED_LOCATIONS,
  EDIT_SUSPENDED_LOCATION,
} from './suspendedLocations';
export { completeLoad, resetTemporaryAddress, saveTemporaryAddress } from './temporaryAddress';

export {
  COMPLETE_DELETE as COMPLETE_DELETE_LOCATION,
  COMPLETE_SAVE as COMPLETE_SAVE_LOCATION, customerLocationByIdSelector,
  deleteCustomerLocation,
  loadCustomerLocations,
  loadCustomerLocationsForWorkflow,
  resetCustomerLocations,
  reverseGeocode,
  saveCustomerLocation, SEARCH_LOCATIONS, searchLocations
} from './locations';

export { getVehiclesInProximity, resetVehiclesInProximity } from './vehiclesInProximity';

export { loadPaymentTerms } from './paymentTermOptions';

export { loadCurrencies } from './currencyOptions';

export const reducer = combineReducers({
  currencies: currencyOptions,
  customer: customerReducer,
  customerTypes: customerTypesOptions,
  locationAlerts: locationAlertsReducer,
  locations: customerLocationsReducer,
  mapControl: mapControlReducer,
  paymentTerms: paymentTermOptionsReducer,
  rubiconServiceRates: rubiconServicesRatesReducer,
  rubiconServices: rubiconServicesReducer,
  rubiconServicesDocuments: rubiconServicesDocumentsReducer,
  rubiconTypes: rubiconServicesOptionsReducer,
  temporaryAddress: temporaryAddressReducer,
  vehiclesInProximity,
});
