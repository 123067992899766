import React, { ChangeEvent, Fragment, useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { push } from 'connected-react-router';
import { difference, map, size, flatMap, uniqBy, pick } from 'lodash-es';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { reset } from 'redux-form';

import { AppState } from '../../../store';
import { AWARD, REQUEST_FOR_PRICING } from '../../constants/requestType';
import BulkAcceptAwardsModal from '../modal/BulkAcceptAwardsModal';
import BulkDeclineAwardsModal from '../modal/BulkDeclineAwardsModal';
import { changeMobilePageSecondaryContent, changeMobilePageSubTitle } from 'src/core/ducks/mobilePage';
import confirm from '../../../core/services/confirm';
import { currentUserId, currentVendorId } from '../../../vendors/services/currentVendorSelector';
import {
  DetailsContainer,
  ForwardIconContainer,
  StatusFilterButton,
  StatusFiltersContainer,
} from '../../../fleet/components/styled/RubiconDispatches';
import { downloadOpenBidsTemplate } from '../../services/opportunities';
import { EndDestination } from '../../interfaces/ChangeRequests';
import { getQueryParams, createUrl } from '../../../utils/services/queryParams';
import { hideNotesModal } from '../../../fleet/ducks';
import ImportOpenBidsModal from '../modal/ImportOpenBidsModal';
import { loadOpportunities } from '../../ducks/opportunities';
import { MobileList } from 'src/core/components/MobileList';
import { MobileWidthView } from 'src/core/components/mediaQueries/MobileWidthView';
import { multiWordAndSearch } from '../../../core/services/search';
import { NotesState } from 'src/fleet/interfaces/RubiconDispatchesNotes';
import OpportunitiesPageFilterForm from '../forms/OpportunitiesPageFilterForm';
import {
  opportunitiesSmartFilters,
  getSmartFilterPopoverContext,
} from './opportunitiesPageSection/opportunitiesSmartFilters';
import { OPEN_AWARD, OPEN_FOR_BID, SELECTED_MAX_OPEN_AWARDS } from '../../constants/opportunitiesStatus';
import OpportunitiesFormTableRow from '../forms/OpportunitiesFormTableRow';
import { OpportunitiesMobileFiltersModal } from '../modal/OpportunitiesMobileFiltersModal';
import { OpportunityMobileListRow } from '../OpportunityMobileListRow';
import {
  PageActions,
  PageContent,
  PageFooter,
  PageHeader,
  PageDetails,
  PageTitleContainer,
  PageTitle,
} from '../../../common/components/styled';
import { Panel, PanelSection, Text, Popover, ModalBackButtonIcon, Button } from '../../../core/components/styled';
import { REQUEST_TYPE_LIST } from '../../constants/requestType';
import RubiconDispatchesNotesModalResolver from '../../../fleet/components/modal/RubiconDispatchesNotesModalResolver';
import { SmartFilterExtended } from 'src/common/interfaces/SmartFilters';
import { Table, PopoverWrapper, Icon, MoreButton, UnconnectedCheckbox } from '../../../core/components';
import { TABLE_ROW_HEIGHT_xLARGE } from '../../../core/constants/table';
import translate from '../../../core/services/translate';
import { useSmartFilters } from '../../../common/hooks/smartFilters';
import { useIsMobileView } from 'src/utils/hooks';
import { YES, NO } from '../../../fleet/constants/status';

const CheckAllAwardedOpportunities = ({ checkAll, checked }: any) => (
  <UnconnectedCheckbox
    block
    noLabel
    size="small"
    margin="no no xSmall no"
    name="checkAllAwardedOpportunities"
    checked={checked}
    onChange={() => checkAll()}
  />
);

interface Props extends RouteComponentProps {
  notesModal: NotesState['modal'];
}
interface OpportunitiesFormValues {
  dateRange: { from: string; to: string };
  divisionIds: string[];
  equipmentSubTypeIds: string[];
  isActionRequired: boolean;
  materialTypeIds: string[];
  requestTypeIds: number[];
  searchTerm: string;
}

const OpportunitiesPage: React.FC<Props> = ({ location: { pathname, search } }) => {
  const dispatch = useDispatch();
  const {
    startDate: initialStartDate = moment().subtract(10, 'days').format('MM/DD/YYYY'),
    endDate: initialEndDate = moment().add(12, 'weeks').format('MM/DD/YYYY'),
    showActionRequired,
  } = getQueryParams(search);

  const isMobile = useIsMobileView();
  const [allOpenAwardsChecked, setAllOpenAwardsChecked] = React.useState<boolean>(false);
  const [endDestinations, setEndDestinations] = React.useState<EndDestination[]>([]);
  const [isBulkAcceptModalOpen, setIsBulkAcceptModalOpen] = React.useState<boolean>(false);
  const [isBulkDeclineModalOpen, setIsBulkDeclineModalOpen] = React.useState<boolean>(false);
  const [selectedOpenAwardsOpportunities, setSelectedOpenAwardsOpportunities] = React.useState<number[]>([]);
  const [currentActionRequired, setCurrentActionRequired] = React.useState<boolean>(showActionRequired === YES);
  const [currentSearchTerm, setCurrentSearchTerm] = React.useState<string>('');
  const [currentDivisionIds, setCurrentDivisionIds] = React.useState<string[]>([]);
  const [currentEquipmentSubTypeIds, setCurrentEquipmentSubTypeIds] = React.useState<string[]>([]);
  const [currentMaterialTypeIds, setCurrentMaterialTypeIds] = React.useState<string[]>([]);
  const [currentRequestTypeIds, setCurrentServiceIds] = React.useState<number[]>([]);
  const [prevFromDate, setPrevFromDate] = React.useState<string>('');
  const [prevToDate, setPrevToDate] = React.useState<string>('');
  const [isMobileFilterModalOpen, setIsMobileFilterModalOpen] = React.useState(false);
  const [isImportOpenBidsModalOpen, setIsImportOpenBidsModalOpen] = React.useState(false);

  const vendorId = useSelector(currentVendorId);
  const userId = useSelector(currentUserId);
  const isAutoRefresh = useSelector((state: AppState) => state.opportunity.opportunities.isAutoRefresh);
  const isLoading = useSelector((state: AppState) => state.opportunity.opportunities.isLoading);
  const paymentInfo = useSelector((state: AppState) => state.opportunity.opportunities.paymentInfo);
  const opportunities = useSelector((state: AppState) => state.opportunity.opportunities.opportunities);
  const divisions = useSelector((state: AppState) => state.opportunity.opportunities.divisions);
  const equipmentTypes = useSelector((state: AppState) => state.opportunity.opportunities.equipmentTypes);
  const materialTypes = useSelector((state: AppState) => state.opportunity.opportunities.materialTypes);
  const notesModal = useSelector((state: AppState) => state.fleet.notes.modal);

  const divisionIds = divisions.map(division => division.id);
  const equipmentSubTypes = equipmentTypes.map(type => type.id);
  const requestTypeIds = REQUEST_TYPE_LIST.map(requestType => requestType.id);
  const materialTypeIds = materialTypes.map(material => material.id);

  const { filters, activeFilterEntries: filteredOpportunities } = useSmartFilters(
    opportunitiesSmartFilters,
    opportunities,
  );

  useEffect(() => {
    if (isAutoRefresh) {
      const isActionRequired = showActionRequired === YES;
      const interval = setInterval(() => {
        loadOpportunities(userId, vendorId, isActionRequired, initialStartDate, initialEndDate)(dispatch);
      }, 30000);
      return () => clearInterval(interval);
    }
  }, [dispatch, isAutoRefresh, userId, vendorId, showActionRequired, initialStartDate, initialEndDate]);

  useEffect(() => {
    changeMobilePageSecondaryContent(
      <Button margin="no small" onClick={() => setIsMobileFilterModalOpen(true)} text>
        <Icon width="24px" icon="filter" />
      </Button>,
    )(dispatch);
    changeMobilePageSubTitle(
      <>
        <Text weight="normal" margin="no xxSmall" singleLine size="small">
          {translate('common.total')}: {opportunities.length}
        </Text>
        <Text weight="normal" margin="no xxSmall" singleLine size="small" color="alert">
          {translate('common.overdue')}: {opportunities.filter(d => !!d.action.isRequired).length}
        </Text>
      </>,
    )(dispatch);
    return () => {
      changeMobilePageSecondaryContent()(dispatch);
      changeMobilePageSubTitle()(dispatch);
    };
  }, [dispatch, opportunities]);

  const disabledFilters = React.useMemo(() => {
    const foundDivisionIds: string[] = [];
    const foundEquipmentSubTypeIds: string[] = [];
    const foundMaterialTypeIds: string[] = [];
    const foundRequestTypeIds: number[] = [];

    filteredOpportunities.forEach(opportunity => {
      if (foundDivisionIds.indexOf(opportunity.vendorId) === -1) {
        foundDivisionIds.push(opportunity.vendorId);
      }

      if (foundEquipmentSubTypeIds.indexOf(opportunity.equipment.subType) === -1) {
        foundEquipmentSubTypeIds.push(opportunity.equipment.subType);
      }

      if (foundMaterialTypeIds.indexOf(opportunity.equipment.wasteMaterialId) === -1) {
        foundMaterialTypeIds.push(opportunity.equipment.wasteMaterialId);
      }

      if (foundRequestTypeIds.indexOf(opportunity.requestType.id) === -1) {
        foundRequestTypeIds.push(opportunity.requestType.id);
      }
    });
    const disabledDivisionIds: string[] = difference(divisionIds, foundDivisionIds);
    const disabledEquipmentSubTypeIds: string[] = difference(equipmentSubTypes, foundEquipmentSubTypeIds);
    const disabledMaterialTypeIds: string[] = difference(materialTypeIds, foundMaterialTypeIds);
    const disabledServiceIds: number[] = difference(requestTypeIds, foundRequestTypeIds);
    const disabledDateRange: boolean = showActionRequired === YES;
    return {
      disabledDivisionIds,
      disabledEquipmentSubTypeIds,
      disabledMaterialTypeIds,
      disabledServiceIds,
      disabledDateRange,
    };
  }, [filteredOpportunities, divisionIds, equipmentSubTypes, materialTypeIds, requestTypeIds, showActionRequired]);

  const onOpportunitiesCriteriaChange = ({
    dateRange: { from: fromDate, to: toDate },
    divisionIds,
    equipmentSubTypeIds,
    isActionRequired,
    materialTypeIds,
    requestTypeIds,
    searchTerm = '',
  }: OpportunitiesFormValues) => {
    const { startDate: currentStartDate, endDate: currentEndDate } = getQueryParams(search);

    if (fromDate !== currentStartDate || toDate !== currentEndDate) {
      dispatch(push(createUrl(pathname, search, { startDate: fromDate, endDate: toDate })));
      loadOpportunities(userId, vendorId, isActionRequired, fromDate, toDate)(dispatch);
    }

    if (isActionRequired !== currentActionRequired) {
      let newStartDate;
      let newEndDate;
      if (isActionRequired) {
        setPrevFromDate(currentStartDate);
        setPrevToDate(currentEndDate);
        newStartDate = moment().subtract(60, 'days').format('MM/DD/YYYY');
        newEndDate = moment().add(30, 'days').format('MM/DD/YYYY');
      } else {
        newStartDate = !prevFromDate ? moment().subtract(10, 'days').format('MM/DD/YYYY') : prevFromDate;
        newEndDate = !prevToDate ? moment().add(45, 'days').format('MM/DD/YYYY') : prevToDate;
      }
      dispatch(
        push(
          createUrl(pathname, search, {
            startDate: newStartDate,
            endDate: newEndDate,
            showActionRequired: isActionRequired ? YES : NO,
          }),
        ),
      );
      loadOpportunities(userId, vendorId, isActionRequired, newStartDate, newEndDate)(dispatch);
    }
    setCurrentActionRequired(isActionRequired);
    setCurrentSearchTerm(searchTerm);
    setCurrentDivisionIds(divisionIds);
    setCurrentEquipmentSubTypeIds(equipmentSubTypeIds || []);
    setCurrentMaterialTypeIds(materialTypeIds || []);
    setCurrentServiceIds(requestTypeIds || []);
    setAllOpenAwardsChecked(false);
    setSelectedOpenAwardsOpportunities([]);
  };

  const opportunitiesFormInitialValues: Partial<OpportunitiesFormValues> = {
    dateRange: {
      from: initialStartDate,
      to: initialEndDate,
    },
    divisionIds: difference(divisionIds, disabledFilters.disabledDivisionIds),
    equipmentSubTypeIds: difference(equipmentSubTypes, disabledFilters.disabledEquipmentSubTypeIds),
    isActionRequired: showActionRequired === YES,
    materialTypeIds: difference(materialTypeIds, disabledFilters.disabledMaterialTypeIds),
    requestTypeIds: difference(requestTypeIds, disabledFilters.disabledServiceIds),
  };

  const doubleFilteredOpportunities = React.useMemo(() => {
    const noStaticFilters =
      !currentSearchTerm &&
      !currentDivisionIds.length &&
      !currentEquipmentSubTypeIds.length &&
      !currentMaterialTypeIds.length &&
      !currentRequestTypeIds.length;

    if (noStaticFilters) {
      return filteredOpportunities;
    }

    return filteredOpportunities.filter(
      opportunity =>
        (!currentDivisionIds.length || currentDivisionIds.indexOf(opportunity.vendorId) !== -1) &&
        (!currentEquipmentSubTypeIds.length ||
          currentEquipmentSubTypeIds.indexOf(opportunity.equipment.subType) !== -1) &&
        (!currentMaterialTypeIds.length ||
          currentMaterialTypeIds.indexOf(opportunity.equipment.wasteMaterialId) !== -1) &&
        (!currentRequestTypeIds.length || currentRequestTypeIds.indexOf(opportunity.requestType.id) !== -1) &&
        (!currentSearchTerm ||
          multiWordAndSearch(opportunity.workOrderNumber, currentSearchTerm) ||
          multiWordAndSearch(
            translate(`haulerProfile.equipments.equipmentSubTypes.${opportunity.equipmentSubType}`),
            currentSearchTerm,
          ) ||
          multiWordAndSearch(opportunity.material, currentSearchTerm) ||
          multiWordAndSearch(opportunity.customerName, currentSearchTerm) ||
          multiWordAndSearch(opportunity.customerAddress, currentSearchTerm) ||
          multiWordAndSearch(opportunity.siteName, currentSearchTerm)),
    );
  }, [
    filteredOpportunities,
    currentSearchTerm,
    currentDivisionIds,
    currentEquipmentSubTypeIds,
    currentMaterialTypeIds,
    currentRequestTypeIds,
  ]);

  const getSmartFilter = (filter: SmartFilterExtended<any, number>) => {
    return (
      <StatusFilterButton small={isMobile} type="button" isActive={filter.isActive} onClick={() => filter.setActive()}>
        <Text uppercase size="small" weight="medium" margin="no" color="primary">
          {filter.title}
        </Text>

        {!!filter.dynamicSubtitle && (
          <Text size="small" margin="xxSmall no no" weight="medium">
            {filter.dynamicSubtitle(filter.entriesCount)}
          </Text>
        )}

        {!!filter.cautionEntriesCount && !!filter.dynamicCaution && (
          <Text size="small" margin="xxSmall no no" color="alert">
            {filter.dynamicCaution(filter.cautionEntriesCount)}
          </Text>
        )}
      </StatusFilterButton>
    );
  };

  const checkAllAwardedOpportunities = () => {
    const openAwardsOpportunities: number[] = [];
    doubleFilteredOpportunities.forEach(opportunity => {
      if (
        selectedOpenAwardsOpportunities.length === 0 &&
        opportunity.requestType.id === AWARD &&
        opportunity.opportunityStatus.id === OPEN_AWARD &&
        !opportunity.pendingBatchResponseStatus
      ) {
        openAwardsOpportunities.push(opportunity.itemId);
      }
    });

    setAllOpenAwardsChecked(!allOpenAwardsChecked);
    setSelectedOpenAwardsOpportunities(openAwardsOpportunities);
  };

  const checkOpportunity = (itemId: number, e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();

    const updatedSelectedOpenAwardsOpportunities = [...selectedOpenAwardsOpportunities];
    const indexDelete = updatedSelectedOpenAwardsOpportunities.indexOf(itemId);
    if (indexDelete > -1) {
      updatedSelectedOpenAwardsOpportunities.splice(indexDelete, 1);
    } else {
      updatedSelectedOpenAwardsOpportunities.push(itemId);
    }
    setSelectedOpenAwardsOpportunities(updatedSelectedOpenAwardsOpportunities);
  };

  const doubleFilteredOpportunitiesOptions = map(doubleFilteredOpportunities, opportunity => {
    return {
      ...opportunity,
      isChecked: selectedOpenAwardsOpportunities.includes(opportunity.itemId),
    };
  });

  const openAwardOpportunities = doubleFilteredOpportunities.filter(
    opportunity =>
      opportunity.requestType.id === AWARD &&
      opportunity.opportunityStatus.id === OPEN_AWARD &&
      !opportunity.pendingBatchResponseStatus,
  );
  const openBidOpportunities = doubleFilteredOpportunities.filter(
    opportunity =>
      opportunity.requestType.id === REQUEST_FOR_PRICING &&
      opportunity.opportunityStatus.id === OPEN_FOR_BID &&
      opportunity.offer.hasNoBid,
  );

  const checkboxCell = !!openAwardOpportunities.length
    ? {
        name: 'selectAll',
        component: CheckAllAwardedOpportunities,
        componentProps: {
          checkAll: checkAllAwardedOpportunities,
          checked: selectedOpenAwardsOpportunities.length === openAwardOpportunities.length,
          partial:
            0 < selectedOpenAwardsOpportunities.length &&
            selectedOpenAwardsOpportunities.length < openAwardOpportunities.length,
        },
        width: '5%',
      }
    : {
        name: 'selectAll',
        width: '5%',
        padding: 'no',
        noPaddingRight: true,
      };
  const cells = [
    checkboxCell,
    {
      name: 'requestType',
      label: translate('opportunity.opportunityRequestType'),
      width: '10%',
      sortable: true,
    },
    {
      name: 'customerName',
      label: translate('autoDispatch.rubiconDispatchesColumns.customer'),
      width: '19%',
      sortable: true,
    },
    {
      name: 'equipmentType',
      label: translate('opportunity.serviceInfo'),
      width: '19%',
      sortable: true,
    },
    {
      name: 'statusName',
      label: translate('common.status'),
      width: '13%',
      sortable: true,
    },
    {
      name: 'rate',
      label: translate('common.rate'),
      width: '18%',
    },
    {
      name: 'options',
      label: translate('opportunity.serviceChangeRequest.actions'),
      width: '16%',
      align: 'right',
    },
  ];

  const filterParams = {
    startDate: currentActionRequired ? moment().subtract(60, 'days').format('MM/DD/YYYY') : initialStartDate,
    endDate: currentActionRequired ? moment().add(30, 'days').format('MM/DD/YYYY') : initialEndDate,
    searchText: currentSearchTerm,
    equipmentSubTypes: currentEquipmentSubTypeIds,
    materialIds: currentMaterialTypeIds,
    vendorDivisionIds: currentDivisionIds,
    isActionRequired: showActionRequired === YES,
  };

  const moreButtonItems: any[] = [];

  moreButtonItems.push({
    id: 'import-prcing-button',
    text: translate('opportunity.opportunities.importOpenBids'),
    handler: () => showOpenBidsUploadModal(),
    disabled: !openBidOpportunities.length,
  });

  moreButtonItems.push({
    id: 'export-pricing-button',
    text: translate('opportunity.opportunities.exportOpenBids'),
    handler: () => downloadOpenBidsTemplate(vendorId, filterParams),
    disabled: !openBidOpportunities.length,
  });

  // Bulk Accept Open Awards opportunities
  const openBulkAcceptOpportunityModal = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsBulkAcceptModalOpen(true);

    const selectedAwardsWithEndDestinations = doubleFilteredOpportunities
      .filter(opportunity => selectedOpenAwardsOpportunities.indexOf(opportunity.itemId) !== -1)
      .map(filteredOpportunity => map(filteredOpportunity.endDestinations, 'id'));
    const commonEndDestinations = selectedAwardsWithEndDestinations.reduce((p, c) => p.filter(e => c.includes(e)));

    const uniqueEndDestinations = uniqBy(
      flatMap(
        map(
          doubleFilteredOpportunities.filter(
            opportunity => selectedOpenAwardsOpportunities.indexOf(opportunity.itemId) !== -1,
          ),
          o => pick(o, ['endDestinations']),
        ),
        ({ endDestinations }) => map(endDestinations, endDestination => ({ ...endDestination })),
      ),
      'id',
    );

    const filterCommonEndDestination = uniqueEndDestinations.filter(
      endDestination => commonEndDestinations.indexOf(endDestination.id) !== -1,
    );
    setEndDestinations(filterCommonEndDestination);
  };

  const closeBulkAcceptOpportunityModal = async (formPristine: boolean, successful = false) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    if (successful) {
      setSelectedOpenAwardsOpportunities([]);
    }
    dispatch(reset('bulkAcceptAwards'));
    setIsBulkAcceptModalOpen(false);
  };

  // Bulk Decline Open Awards opportunities
  const openBulkDeclineOpportunityModal = (event?: MouseEvent) => {
    event?.stopPropagation();
    setIsBulkDeclineModalOpen(true);
  };

  const closeBulkDeclineOpportunityModal = async (formPristine: boolean, successful = false) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    if (successful) {
      setSelectedOpenAwardsOpportunities([]);
    }
    dispatch(reset('bulkDeclineAwards'));
    setIsBulkDeclineModalOpen(false);
  };

  const closeNotesModal = async (isReload: boolean, formPristine: boolean) => {
    if (!formPristine) {
      if (!(await confirm(translate('common.alertMessages.leavePageWithoutSaving')))) {
        return;
      }
    }
    dispatch(reset('notesForm'));
    dispatch(hideNotesModal());
  };

  const showOpenBidsUploadModal = (visible: boolean = true) => {
    setIsImportOpenBidsModalOpen(visible);
  };
  return (
    <>
      <PageContent isLoading={isLoading}>
        {!isMobile && (
          <PageHeader>
            <PageDetails>
              <PageTitleContainer>
                <PageTitle>{translate('opportunity.opportunities.currentOpportunities')}</PageTitle>
              </PageTitleContainer>
            </PageDetails>
            <PageActions flex>
              {!!moreButtonItems.length && <MoreButton margin="no small no no" items={moreButtonItems} />}
            </PageActions>
          </PageHeader>
        )}
        <Panel>
          <PanelSection withBorder>
            <DetailsContainer noShadow padding="no">
              <StatusFiltersContainer
                verticalCenter={isMobile}
                noWrap={isMobile}
                maxFilterWidth={isMobile ? 1 : 5}
                padding={isMobile ? 'small xxSmall' : 'xxSmall'}
              >
                {filters.map((filter, index) => (
                  <Fragment key={filter.id}>
                    {isMobile ? (
                      getSmartFilter(filter)
                    ) : (
                      <PopoverWrapper
                        key={index}
                        size="small"
                        popoverContent={<Popover>{getSmartFilterPopoverContext(filter.id)}</Popover>}
                        triggerButton={getSmartFilter(filter)}
                      />
                    )}

                    {index !== filters.length - 1 && (
                      <ForwardIconContainer flex>
                        <ModalBackButtonIcon forward selfCentered />
                      </ForwardIconContainer>
                    )}
                  </Fragment>
                ))}
              </StatusFiltersContainer>
            </DetailsContainer>
          </PanelSection>
          {!isMobile && (
            <OpportunitiesPageFilterForm
              initialValues={opportunitiesFormInitialValues}
              onChange={onOpportunitiesCriteriaChange}
              {...disabledFilters}
            />
          )}

          {!!size(doubleFilteredOpportunitiesOptions) &&
            (!isMobile ? (
              <PanelSection>
                <Table
                  cells={cells}
                  rows={doubleFilteredOpportunitiesOptions}
                  rowComponent={OpportunitiesFormTableRow}
                  rowProps={{
                    paymentInfo,
                    checkOpportunity: checkOpportunity,
                  }}
                  virtualized
                  scrollMarker
                  noOverflow
                  virtualizedProps={{
                    height: Math.min(
                      doubleFilteredOpportunitiesOptions.length * TABLE_ROW_HEIGHT_xLARGE,
                      TABLE_ROW_HEIGHT_xLARGE * 8,
                    ),
                    itemSize: TABLE_ROW_HEIGHT_xLARGE,
                  }}
                />
              </PanelSection>
            ) : (
              <MobileList
                rows={doubleFilteredOpportunitiesOptions}
                rowComponent={OpportunityMobileListRow}
                detailsComponent={OpportunitiesFormTableRow}
                detailsComponentProps={{ showMobileDetails: true, paymentInfo }}
                onSearch={searchTerm =>
                  onOpportunitiesCriteriaChange({
                    searchTerm,
                    dateRange: { from: prevFromDate, to: prevToDate },
                    divisionIds: currentDivisionIds,
                    equipmentSubTypeIds: currentEquipmentSubTypeIds,
                    isActionRequired: currentActionRequired,
                    materialTypeIds: currentMaterialTypeIds,
                    requestTypeIds: currentRequestTypeIds,
                  })
                }
                virtualizedProps={{
                  itemSize: 123,
                  height: !doubleFilteredOpportunitiesOptions.length
                    ? 0
                    : document.documentElement.clientHeight - (52 + 105 + 70),
                }}
              />
            ))}
          {!!selectedOpenAwardsOpportunities.length && selectedOpenAwardsOpportunities.length >= 2 && (
            <PanelSection centered padding="no">
              <PageFooter>
                <PopoverWrapper
                  triggerButton={
                    <Button
                      id="bulk-accept-awards-button"
                      color="primary"
                      onClick={openBulkAcceptOpportunityModal}
                      margin="no small no no"
                      disabled={selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS}
                    >
                      {translate('opportunity.opportunities.acceptXOpportunities', {
                        noOfOpenAwards: selectedOpenAwardsOpportunities.length,
                      })}
                    </Button>
                  }
                  popoverContent={
                    selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS && (
                      <Popover>
                        {selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS && (
                          <Text block weight="medium" margin="xxSmall no xxSmall">
                            {translate('opportunity.opportunities.alertMessages.selectedOpenAwardsMoreThan', {
                              SELECTED_MAX_OPEN_AWARDS,
                            })}
                          </Text>
                        )}
                      </Popover>
                    )
                  }
                  size="large"
                />
                <PopoverWrapper
                  triggerButton={
                    <Button
                      id="bulk-decline-awards-button"
                      color="alert"
                      onClick={openBulkDeclineOpportunityModal}
                      margin="no small no no"
                      disabled={selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS}
                    >
                      {translate('opportunity.opportunities.declineXOpportunities', {
                        noOfOpenAwards: selectedOpenAwardsOpportunities.length,
                      })}
                    </Button>
                  }
                  popoverContent={
                    selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS && (
                      <Popover>
                        {selectedOpenAwardsOpportunities.length > SELECTED_MAX_OPEN_AWARDS && (
                          <Text block weight="medium" margin="xxSmall no xxSmall">
                            {translate('opportunity.opportunities.alertMessages.selectedOpenAwardsMoreThan', {
                              SELECTED_MAX_OPEN_AWARDS,
                            })}
                          </Text>
                        )}
                      </Popover>
                    )
                  }
                  size="large"
                />
              </PageFooter>
            </PanelSection>
          )}
          {!size(doubleFilteredOpportunitiesOptions) && (
            <PanelSection>
              <Text margin="small">{translate('opportunity.opportunities.noRecord')}</Text>
            </PanelSection>
          )}
        </Panel>
      </PageContent>
      <MobileWidthView>
        <OpportunitiesMobileFiltersModal
          hidden={!isMobileFilterModalOpen}
          onClose={() => setIsMobileFilterModalOpen(false)}
          initialValues={opportunitiesFormInitialValues}
          onChange={onOpportunitiesCriteriaChange}
          {...disabledFilters}
        />
      </MobileWidthView>

      {isBulkAcceptModalOpen && (
        <BulkAcceptAwardsModal
          endDestinations={endDestinations}
          selectedOpenAwardsItemIds={selectedOpenAwardsOpportunities}
          onCancel={closeBulkAcceptOpportunityModal}
        />
      )}

      {isBulkDeclineModalOpen && (
        <BulkDeclineAwardsModal
          selectedOpenAwardsItemIds={selectedOpenAwardsOpportunities}
          onCancel={closeBulkDeclineOpportunityModal}
        />
      )}
      {!!notesModal && <RubiconDispatchesNotesModalResolver onCancel={closeNotesModal} />}
      {isImportOpenBidsModalOpen && (
        <ImportOpenBidsModal
          vendorId={vendorId}
          filterParams={filterParams}
          onClose={() => showOpenBidsUploadModal(false)}
        />
      )}
    </>
  );
};

export default withRouter(OpportunitiesPage);
