import { mapKeys, flatten, mapValues, find } from 'lodash-es';

import boxYard from '../assets/img/vendorLocations/boxYard.png';
import disposalSite from '../assets/img/vendorLocations/disposalSite.png';
import truckYard from '../assets/img/vendorLocations/truckYard.png';
import disposalNew from '../assets/img/vendorLocationsNew/disposal.png';
import fuelingStation from '../assets/img/vendorLocationsNew/fuelingStation.png';
import containerYard from '../assets/img/vendorLocationsNew/containerYard.png';
import truckYardNew from '../assets/img/vendorLocationsNew/truckYardNew.png';
import maintenance from '../assets/img/vendorLocationsNew/maintenance.png';
import other from '../assets/img/vendorLocationsNew/other.png';
import waterFillingStation from '../assets/img/vendorLocationsNew/waterFillingStation.png';
import saltChemical from '../assets/img/vendorLocationsNew/saltChemicalFillingStation.png';
import other_new from '../assets/img/vendorLocations/other_new.png';
import waterFillingStation_new from '../assets/img/vendorLocations/waterFillingStation_new.png';
import saltChemical_new from '../assets/img/vendorLocations/saltChemicalFillingStation_new.png';
import fuelingStation_new from '../assets/img/vendorLocations/fuelingStation_new.png';

import {
  CUSTOMER_SUBTYPE_CONTAINER_YARD,
  CUSTOMER_SUBTYPE_FUELING_STATION,
  CUSTOMER_SUBTYPE_LANDFILL,
  CUSTOMER_SUBTYPE_LANDFILL_OTHER,
  CUSTOMER_SUBTYPE_MAINTENANCE,
  CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION,
  CUSTOMER_SUBTYPE_TRUCK_YARD,
  CUSTOMER_SUBTYPE_WATER_FILLING_STATION,
} from './customerSubTypes';
import { HaulerLocation } from 'src/dashboard/interfaces/haulerLocations';
import IconWithTypes from '../interfaces/IconWithTypes';
import { DISPOSAL_FACILITY } from './customerTypes';

export const VENDOR_LOCATION_ICON_TYPES: IconWithTypes[] = [
  new IconWithTypes('boxYard', boxYard, [CUSTOMER_SUBTYPE_CONTAINER_YARD]),
  new IconWithTypes('disposalSite', disposalSite, [CUSTOMER_SUBTYPE_LANDFILL]),
  new IconWithTypes('truckYard', truckYard, [CUSTOMER_SUBTYPE_TRUCK_YARD]),
  new IconWithTypes('saltChemical', saltChemical_new, [CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION]),
  new IconWithTypes('fuelingStation', fuelingStation_new, [CUSTOMER_SUBTYPE_FUELING_STATION]),
  new IconWithTypes('other', other_new, [CUSTOMER_SUBTYPE_LANDFILL_OTHER]),
  new IconWithTypes('waterFillingStation', waterFillingStation_new, [CUSTOMER_SUBTYPE_WATER_FILLING_STATION]),
];

export const VENDOR_LOCATION_ICON_TYPES_NEW: IconWithTypes[] = [
  new IconWithTypes('disposal', disposalNew, [CUSTOMER_SUBTYPE_LANDFILL]),
  new IconWithTypes('fuelingStation', fuelingStation, [CUSTOMER_SUBTYPE_FUELING_STATION]),
  new IconWithTypes('containerYard', containerYard, [CUSTOMER_SUBTYPE_CONTAINER_YARD]),
  new IconWithTypes('truckYardNew', truckYardNew, [CUSTOMER_SUBTYPE_TRUCK_YARD]),
  new IconWithTypes('maintenance', maintenance, [CUSTOMER_SUBTYPE_MAINTENANCE]),
  new IconWithTypes('other', other, [CUSTOMER_SUBTYPE_LANDFILL_OTHER]),
  new IconWithTypes('waterFillingStation', waterFillingStation, [CUSTOMER_SUBTYPE_WATER_FILLING_STATION]),
  new IconWithTypes('saltChemical', saltChemical, [CUSTOMER_SUBTYPE_SALT_CHEMICAL_FILLING_STATION]),
];

const VENDOR_LOCATION_ICONS = mapValues(
  mapKeys(
    flatten(
      VENDOR_LOCATION_ICON_TYPES.map(iconType =>
        iconType.types.map(customerSubtype => ({ type: customerSubtype, icon: iconType.iconUrl })),
      ),
    ),
    value => value.type,
  ),
  value => value.icon,
);

export default VENDOR_LOCATION_ICONS;

export const getHaulerLocationIcon = (haulerLocation: HaulerLocation) => {
  if (haulerLocation.customerTypeId === DISPOSAL_FACILITY) {
    return 'disposal';
  } else {
    const icon = find(
      VENDOR_LOCATION_ICON_TYPES_NEW,
      type => type.types.indexOf(haulerLocation.customerSubTypeId) !== -1,
    )?.id;
    if (icon) {
      return icon;
    }
    return 'other';
  }
};
