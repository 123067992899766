import { http } from '../../core/services/http';

export const loadRubiconServiceRates = (serviceId: number, serviceStatus: string, vendorId: string, userId: string) => {
  return http
    .post(`gus/rubiconservices/rates/vendor/${vendorId}/user/${userId}`, {
      serviceId: serviceId,
      serviceStatus,
    })
    .then(response => {
      return response.data;
    });
};
