import update from 'immutability-helper';
import { find, findIndex, get, identity, isEmpty, reduce } from 'lodash-es';
import { AnyAction, Dispatch } from 'redux';
import { createSelector } from 'reselect';

import { isAdmin } from '../../account/ducks/login';
import { AppState } from '../../store';
import { Feature, FeaturesState } from '../interfaces/Features';
import { loadFeatures as doLoadFeatures, saveFeatures as doSaveFeatures } from '../services/features';

// Actions
const COMPLETE_LOAD = 'vendors/features/COMPLETE_LOAD';
const COMPLETE_SAVE = 'vendors/features/COMPLETE_SAVE';
const FAIL_LOAD = 'vendors/features/FAIL_LOAD';
const FAIL_SAVE = 'vendors/features/FAIL_SAVE';
const RESET = 'vendors/features/RESET';
const START_LOAD = 'vendors/features/START_LOAD';
const START_SAVE = 'vendors/features/START_SAVE';
const TOGGLE_FEATURE = 'vendors/features/TOGGLE_FEATURE';

// Initial state
const initialState: FeaturesState = {
  features: [],
  isLoading: false,
  isLoadingChild: false,
  isSaving: false,
  isSavingChild: false,
};

export const BILLING_MODULE = 'BillingModule';

const ALTERNATIVE_FLEET = 'AlternativeFleet';
const AUTO_REFRESH_ENABLED = 'DashboardAutoRefresh';
const AUTOMATIC_ACCOUNT_NUMBER_GENERATION = 'AutomaticAccountNumberGeneration';
const BULKY_ITEM_SCHEDULER = 'BulkyItemScheduler';
const CITY_ALERT = 'CityAlertNew';
const CONTAINER_MANAGEMENT = 'ContainerManagement';
const DISPLAY_UNSCHEDULED_STOPS = 'DisplayUnscheduledStops';
const FLAG_LOCATION = 'FlagLocation';
const GEO_FENCE = 'GeoFencing';
const INFERENCE_AUDIT = 'InferenceAudit';
const LOCATION_ALERTS = 'LocationAlerts';
const ON_DEMAND_VIDEO_DOWNLOAD = 'OnDemandVideoDownload';
const OPTIMIZED_STOP_INSERTION = 'OptimizedStopInsertion';
const ROUTE_SEQUENCING = 'RouteSequencing';
const SINGLE_SIGN_ON = 'SSO';
const SNOW_PLOW = 'SnowPlow';
const STREET_SWEEPING = 'StreetSweeping';
const TRAVEL_PATH_NAVIGATION = 'TravelPathNavigation';
const VEHICLE_STROBE_IMAGES = 'VehicleStrobeImages';
const VISION_CONFIGURATION = 'VisionConfiguration';
const WASTE_AUDIT = 'WasteAudit';

export const NAVI_3 = 'Navi3';
export const OPEN_311_CONFIG = 'Open311Configuration';

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: {
          isLoading: !action.isOnlyChildLoading,
          isLoadingChild: action.isOnlyChildLoading,
        },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          features: action.features,
          isLoading: false,
          isLoadingChild: false,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: {
          features: undefined,
          isLoading: false,
          isLoadingChild: false,
        },
      });

    case TOGGLE_FEATURE: {
      const { code, enabled } = action;
      const featureIndex = findIndex(state.features, { code });

      return update(state, {
        features: {
          [featureIndex]: {
            enabled: { $set: enabled },
          },
        },
      } as any);
    }

    case START_SAVE:
      return update(state, {
        $merge: {
          isSaving: !action.isOnlyChildLoading,
          isSavingChild: action.isOnlyChildLoading,
        },
      });

    case COMPLETE_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          isSavingChild: false,
        },
      });

    case FAIL_SAVE:
      return update(state, {
        $merge: {
          isSaving: false,
          isSavingChild: false,
        },
      });

    case RESET:
      return update(state, { $merge: initialState });

    default:
      return state;
  }
};

// Action creators
const startLoad = (isOnlyChildLoading?: boolean) => ({
  type: START_LOAD,
  isOnlyChildLoading,
});

const completeLoad = (features: Feature[], isOnlyChildLoading?: boolean) => ({
  type: COMPLETE_LOAD,
  features,
  isOnlyChildLoading,
});

const failLoad = (isOnlyChildLoading?: boolean) => ({
  type: FAIL_LOAD,
  isOnlyChildLoading,
});

const startSave = (isOnlyChildLoading?: boolean) => ({
  type: START_SAVE,
  isOnlyChildLoading,
});

const completeSave = (isOnlyChildLoading?: boolean) => ({
  type: COMPLETE_SAVE,
  isOnlyChildLoading,
});

const failSave = (isOnlyChildLoading?: boolean) => ({
  type: FAIL_SAVE,
  isOnlyChildLoading,
});

export const resetFeatures = () => ({
  type: RESET,
});

export const loadFeatures = (vendorId: number, isOnlyChildLoading?: boolean) => (dispatch: Dispatch) => {
  dispatch(startLoad(isOnlyChildLoading));

  const loadFeaturesPromise = doLoadFeatures(vendorId);

  loadFeaturesPromise
    .then(features => dispatch(completeLoad(features, isOnlyChildLoading)))
    .catch(() => dispatch(failLoad(isOnlyChildLoading)));

  return loadFeaturesPromise;
};

export const saveFeatures =
  (vendorId: number, features: Feature[], isOnlyChildLoading?: boolean) => (dispatch: Dispatch) => {
    dispatch(startSave(isOnlyChildLoading));

    const saveFeaturesPromise = doSaveFeatures(vendorId, features);

    saveFeaturesPromise
      .then(() => dispatch(completeSave(isOnlyChildLoading)))
      .catch(() => dispatch(failSave(isOnlyChildLoading)));

    return saveFeaturesPromise;
  };

// Selectors
const getFeatureStatus = (featuresState: Feature[], featureCode: string, def: boolean = false) =>
  get(
    find(featuresState, feature => feature.code === featureCode),
    'enabled',
    def,
  );

export const featureStatusSelector = createSelector(getFeatureStatus, identity);

const getBillingFeatureStatus = (featuresState: Feature[]) => getFeatureStatus(featuresState, BILLING_MODULE);
export const billingFeatureStatusSelector = createSelector(getBillingFeatureStatus, identity);

const getAutomaticAccountNumberGenerationStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === AUTOMATIC_ACCOUNT_NUMBER_GENERATION),
    'enabled',
    undefined,
  );

export const automaticAccountNumberGenerationSelector = createSelector(
  getAutomaticAccountNumberGenerationStatus,
  identity,
);

const getRouteSequencingStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === ROUTE_SEQUENCING),
    'enabled',
    undefined,
  );

export const routeSequencingStatusSelector = createSelector(getRouteSequencingStatus, identity);

const getVisionConfigurationStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === VISION_CONFIGURATION),
    'enabled',
    undefined,
  );
export const visionConfigurationStatusSelector = createSelector(getVisionConfigurationStatus, identity);

const getVehicleStrobeImagesStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === VEHICLE_STROBE_IMAGES),
    'enabled',
    undefined,
  );

export const vehicleStrobeImagesStatusSelector = createSelector(getVehicleStrobeImagesStatus, identity);

const inferenceAuditStatusStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === INFERENCE_AUDIT),
    'enabled',
    undefined,
  );

export const inferenceAuditStatusSelector = createSelector(inferenceAuditStatusStatus, identity);

const getAutoRefreshStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === AUTO_REFRESH_ENABLED),
    'enabled',
    undefined,
  );

export const autoRefreshStatusSelector = createSelector(getAutoRefreshStatus, identity);

const getDisplayUnscheduledStopsStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === DISPLAY_UNSCHEDULED_STOPS),
    'enabled',
    undefined,
  );

export const displayUnscheduledStopsStatusSelector = createSelector(getDisplayUnscheduledStopsStatus, identity);

const getFeaturesForLoggedInUser = (featuresState: any, isAdmin: boolean) => {
  const features = get(featuresState, 'features', []);

  return reduce(
    features,
    (filteredFeatures, feature) => {
      if (isAdmin || feature.isFeatureVisible) {
        filteredFeatures.push(feature);
      }
      return filteredFeatures;
    },
    [] as Feature[],
  );
};

export const featuresForLoggedInUserSelector = createSelector<any, boolean, Feature[], Feature[]>(
  getFeaturesForLoggedInUser,
  identity,
);

export const featuresForLoggedInUser = (state: AppState) =>
  featuresForLoggedInUserSelector(state.vendors.features, isAdmin(state));

const getWasteAuditStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === WASTE_AUDIT),
    'enabled',
    undefined,
  );
export const wasteAuditStatusSelector = createSelector(getWasteAuditStatus, identity);

const getFlagLocationStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === FLAG_LOCATION),
    'enabled',
    undefined,
  );
export const flagLocationStatusSelector = createSelector(getFlagLocationStatus, identity);

const getGeoFenceStatus = (featuresState: any) =>
  get(
    find(featuresState, feature => feature.code === GEO_FENCE),
    'enabled',
    false,
  );
export const geoFenceSelector = createSelector<any, any, boolean>(getGeoFenceStatus, identity);

export const checkIfGeoFenceIsEnabled = (state: AppState) => geoFenceSelector(state.vendors.features.features);

export const checkIfContainerManagementIsEnabled = (state: AppState) =>
  get(
    find(state.vendors.features.features, feature => feature.code === CONTAINER_MANAGEMENT),
    'enabled',
    false,
  );

export const checkIfSnowPlowIsEnabled = (state: AppState) =>
  !isEmpty(state.vendors.features.features) &&
  get(
    state.vendors.features.features.find(feature => feature.code === SNOW_PLOW),
    'enabled',
    false,
  ) && alternativeFleetFeatureIsEnabled(state);

export const checkIfStreetSweepingIsEnabled = (state: AppState) =>
  !isEmpty(state.vendors.features.features) &&
  get(
    state.vendors.features.features.find(feature => feature.code === STREET_SWEEPING),
    'enabled',
    false,
  ) && alternativeFleetFeatureIsEnabled(state);

export const alternativeFleetFeatureIsEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === ALTERNATIVE_FLEET),
    'enabled',
    false,
  );

export const isTravelPathNavigationFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === TRAVEL_PATH_NAVIGATION),
    'enabled',
    false,
  );

export const isNavi3FeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === NAVI_3),
    'enabled',
    false,
  );

export const isOptimizedStopInsertionFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === OPTIMIZED_STOP_INSERTION),
    'enabled',
    false,
  );

export const isSingleSignOnFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === SINGLE_SIGN_ON),
    'enabled',
    false,
  );

export const isOnDemandVideoDownloadFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === ON_DEMAND_VIDEO_DOWNLOAD),
    'enabled',
    false,
  );

export const isCityAlertFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === CITY_ALERT),
    'enabled',
    false,
  );

export const isLocationAlertFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === LOCATION_ALERTS),
    'enabled',
    false,
  );

export const isBulkyItemSchedulerFeatureEnabled = (state: AppState) =>
  get(
    state.vendors.features.features.find(feature => feature.code === BULKY_ITEM_SCHEDULER),
    'enabled',
    false,
  );
