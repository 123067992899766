import { identity, get } from 'lodash-es';
import { createSelector } from 'reselect';

import { date } from '../../utils/services/formatter';

const getCustomerEditorFormInitialValues = (
  customerState: any,
  customerTypes: any[] = [],
  serviceContractCustomerName?: string,
  serviceContractCustomerTypeId?: number,
) => {
  const customer = get(customerState, 'customer', {});
  const fromDate = get(customer.futureAccountStatus, 'fromDate');
  const toDate = get(customer.futureAccountStatus, 'toDate');
  return {
    ...customer,
    name: serviceContractCustomerName || customer.name,
    customerTypeId:
      customerTypes.length === 1 ? customerTypes[0].id : serviceContractCustomerTypeId || customer.customerTypeId,
    customerAccountStatusTypeId: customer.customerAccountStatusTypeId || 1,
    futureAccountStatus: customer.futureAccountStatus
      ? {
          ...customer.futureAccountStatus,
          accountStatusId: get(customer.futureAccountStatus, 'accountStatusId') || undefined,
          fromDate: fromDate ? date(fromDate) : undefined,
          toDate: toDate ? date(toDate) : undefined,
        }
      : null,
  };
};

const customerEditorFormInitialValuesSelector = createSelector<any, any, any, any>(
  getCustomerEditorFormInitialValues,
  identity,
);

export default customerEditorFormInitialValuesSelector;
