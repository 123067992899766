import { change, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { debounce, map, size } from 'lodash-es';
import { Fragment, PureComponent } from 'react';
import moment from 'moment';

import { AppState } from 'src/store';
import {
  ActionButtonTooltip,
  DatePicker,
  Dropdown,
  Input,
  PopoverWrapper,
  Table,
  TypeAhead,
} from 'src/core/components';
import {
  Button,
  FormGroupClear,
  FormGroupClearContainer,
  Grid,
  GridColumn,
  Message,
  ModalSection,
  ModalTitle,
  Popover,
  Text,
} from 'src/core/components/styled';
import { currentVendorIdSelector } from 'src/vendors/services/currentVendorSelector';
import { CUSTOMER_TIME_OPTIONS, PROXIMITY_SETTINGS } from 'src/customers/constants';
import { CustomerProximityMapGL, ProximitySearchTableRow } from '.';
import { DASHBOARD_FILTER_FORM_MAPBOX } from 'src/dashboard/constants/dashboardFilter';
import { date, time } from 'src/utils/services/formatter';
import { DuckFunction } from 'src/contracts/ducks';
import { getMapBounds } from 'src/common/components/map/util';
import { isDateValidValidator, isRequired } from 'src/utils/services/validator';
import { loadVendor } from 'src/vendors/ducks';
import { MAP_CITY_ZOOM_IN } from 'src/core/constants';
import { PageLoading } from 'src/common/components/styled';
import { PinLocation, VehicleInProximity } from 'src/customers/interfaces/Customers';
import { PinOnMapMapbox } from 'src/routes/components';
import { ProximitySearchForm, ProximitySearchMapContainer } from '../styled';
import { resetVehiclesInProximity } from 'src/customers/ducks';
import { SelectOnMap } from 'src/routes/components/styled';
import { TODAY, TODAY_FORMATTED } from 'src/core/constants/weekdays';
import focusFirstInvalidField from 'src/utils/services/focusFirstInvalidField';
import searchCustomerOnlyWithLocations from 'src/routes/services/searchCustomerOnlyWithLocations';
import translate from 'src/core/services/translate';

interface ComponentProps {
  change?: any;
  customerDate?: string;
  customerLocationSelected?: string;
  customerLocationSelectedAddress?: any;
  customerTime?: string;
  defaultDate?: Date | string;
  defaultDateIsSelected?: boolean;
  isPageLoading?: boolean;
  locationId?: number;
  onFormSubmit: (
    customerDate: Date | string | undefined,
    customerTime: string | undefined,
    pinLocation: PinLocation,
    proximitySetting: number,
    vendorId: number,
  ) => void;
}

interface PropsWithoutReduxForm extends ComponentProps {
  loadVendor: DuckFunction<typeof loadVendor>;
  proximitySetting: number;
  resetVehiclesInProximity: () => void;
  vendorId: number;
  proximitySearchResults?: VehicleInProximity[];
}

interface FormValues {
  customerDate: Date | string;
  customerTime: string;
  pinLocation: PinLocation;
  proximitySetting: number;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

interface State {
  customerLocationCleared: boolean;
  isLoading: boolean;
  isPinOnMapVisible: boolean;
  mapCenterByVendor?: PinLocation;
  mapCenterByVendorPinOnMap?: google.maps.LatLng;
  pinLocation?: PinLocation;
}

class CustomerProximitySearchForm extends PureComponent<Props, State> {
  readonly state = {
    customerLocationCleared: false,
    isLoading: true,
    isPinOnMapVisible: false,
    mapCenterByVendor: undefined,
    mapCenterByVendorPinOnMap: undefined,
    pinLocation: undefined,
  };

  static defaultProps = {
    proximitySearchResults: [],
  };

  getBounds = (latitude: number, longitude: number) => {
    return getMapBounds([{ latitude, longitude }], {
      capZoom: MAP_CITY_ZOOM_IN,
    });
  };

  componentDidMount() {
    const { vendorId, loadVendor, customerLocationSelectedAddress } = this.props;

    if (customerLocationSelectedAddress) {
      this.setState({
        isLoading: false,
        pinLocation: this.getBounds(
          customerLocationSelectedAddress.latitude,
          customerLocationSelectedAddress.longitude,
        ),
      });
    }

    const noLoadingIndicator = true;

    loadVendor(vendorId, noLoadingIndicator).then(response => {
      const mapCenterByVendor = this.getBounds(response.homeAddress.latitude, response.homeAddress.longitude);
      const mapCenterByVendorPinOnMap = new google.maps.LatLng({
        lat: response.homeAddress.latitude,
        lng: response.homeAddress.longitude,
      });

      this.setState({
        isLoading: false,
        mapCenterByVendor,
        mapCenterByVendorPinOnMap,
      });
    });
  }

  componentWillUnmount() {
    this.resetVehiclesInProximity();
  }

  onFormSubmit = (
    customerDate: Date | string | undefined,
    customerTime: string | undefined,
    pinLocation: PinLocation,
    proximitySetting: number,
  ) => {
    const { onFormSubmit: onSubmit, vendorId } = this.props;

    if (moment(customerDate, 'MM/DD/YYYY', true).isValid())
      onSubmit(customerDate, customerTime, pinLocation, proximitySetting, vendorId);
  };

  onCustomerLocationChanged = (event: any) => {
    if (event && event.location) {
      this.setState({
        pinLocation: this.getBounds(event.location.address.latitude, event.location.address.longitude),
      });
    } else {
      this.setState({ pinLocation: undefined });
    }

    this.setState({ customerLocationCleared: false });
    this.resetVehiclesInProximity();
  };

  onCustomerTimeChanged = () => {
    this.resetVehiclesInProximity();
  };

  onDateChanged = () => {
    this.resetVehiclesInProximity();
  };

  onCustomerProximityChanged = () => {
    this.resetVehiclesInProximity();
  };

  setPinLocationOnMap = (location: any) => {
    this.setState({
      pinLocation: this.getBounds(location.latitude, location.longitude),
    });

    this.resetVehiclesInProximity();
  };

  resetVehiclesInProximity = () => {
    const { resetVehiclesInProximity } = this.props;
    resetVehiclesInProximity();
  };

  loadCustomerLocations = debounce((searchTerm, onOptionsLoaded) => {
    const { vendorId } = this.props;
    if (searchTerm.trim().length < 3) {
      onOptionsLoaded([]);
      return;
    }

    searchCustomerOnlyWithLocations(vendorId, searchTerm).then(onOptionsLoaded);
  }, 500);

  customerTimeOptions = map(CUSTOMER_TIME_OPTIONS, ({ value, name }) => ({
    value,
    label: name,
  }));

  proximitySettingOptions = map(PROXIMITY_SETTINGS, ({ value, name }) => ({
    value,
    label: name,
  }));

  handlePinClickEvent = (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const { change } = this.props;

    this.setState({ isPinOnMapVisible: true, pinLocation: undefined });
    change('customerLocation', undefined);
    this.resetVehiclesInProximity();
  };

  handleProximitySearchClickEvent = () => {
    const { customerLocationSelectedAddress } = this.props;
    const { customerLocationCleared } = this.state;

    this.setState({
      isPinOnMapVisible: false,
      pinLocation:
        customerLocationSelectedAddress && !customerLocationCleared
          ? this.getBounds(customerLocationSelectedAddress.latitude, customerLocationSelectedAddress.longitude)
          : undefined,
    });
    this.resetVehiclesInProximity();
  };

  handleClearAddress = () => {
    this.setState({ customerLocationCleared: true, pinLocation: undefined });
    this.resetVehiclesInProximity();
  };

  proximitySearchTableCells = [
    {
      name: 'vehicleName',
      label: translate('customers.vehicle'),
      width: '16%',
    },
    {
      name: 'timeEnterd',
      label: translate('customers.timeEnterd'),
      width: '17%',
    },
    {
      name: 'timeExited',
      label: translate('customers.timeExited'),
      width: '16%',
    },
    {
      name: 'duration',
      label: translate('customers.duration'),
      width: '15%',
    },
    {
      name: 'averageSpeed',
      label: translate('customers.averageSpeed'),
      width: '18%',
    },
    {
      name: 'minimumSpeed',
      label: translate('customers.minimumSpeed'),
      width: '18%',
    },
  ];

  render() {
    const {
      customerDate,
      customerLocationSelected,
      customerTime,
      isPageLoading,
      proximitySearchResults,
      proximitySetting,
    } = this.props;
    const {
      customerLocationCleared,
      isLoading,
      isPinOnMapVisible,
      mapCenterByVendor,
      mapCenterByVendorPinOnMap,
      pinLocation,
    } = this.state;

    const isButtonDisabled = !pinLocation || !customerTime || !proximitySetting || !customerDate;

    const circleOptions = {
      strokeColor: 'black',
      strokeWeight: 1,
      strokeOpacity: 0.5,
      fillOpacity: 0.2,
      radius: proximitySetting / 1.094, // transform yards in meters
    };

    return (
      <ModalSection padding="medium" fluid>
        <form>
          <ModalTitle align="center" size="large" margin="no no sMedium">
            {translate('customers.customerProximitySearch')}
          </ModalTitle>

          <ProximitySearchForm isPinOnMapVisible={isPinOnMapVisible}>
            <Grid padding="no small no no" multiLine>
              {!isPinOnMapVisible && (
                <Fragment>
                  {!customerLocationSelected || customerLocationCleared ? (
                    <GridColumn size="11/12">
                      <Field
                        name="customerLocation"
                        component={TypeAhead}
                        placeholder={`${translate('common.customer')} / ${translate('common.location')}`}
                        getOptions={this.loadCustomerLocations}
                        isClearable
                        margin="no"
                        validate={[isRequired]}
                        onChange={this.onCustomerLocationChanged}
                      />
                    </GridColumn>
                  ) : (
                    <Fragment>
                      <GridColumn size="11/12">
                        <Field name="customerLocationSelected" component={Input} margin="no" disabledDarker />
                        <FormGroupClearContainer position="absolute">
                          <FormGroupClear onClick={this.handleClearAddress} />
                        </FormGroupClearContainer>
                      </GridColumn>
                    </Fragment>
                  )}

                  <GridColumn size="1/12" align="right">
                    <SelectOnMap onClick={this.handlePinClickEvent}>
                      <ActionButtonTooltip icon="pinOnMap" tooltip="searchAddressPinLocationOnMap" size="medium" />
                    </SelectOnMap>
                  </GridColumn>
                </Fragment>
              )}

              <GridColumn size="3/12" padding="sMedium xSmall no xSmall">
                <Field
                  label={translate('common.date')}
                  name="customerDate"
                  component={DatePicker}
                  tabletAlignLeft
                  onChange={this.onDateChanged}
                  margin="no"
                  disabledDays={[{ after: TODAY }]}
                  validate={[isRequired, isDateValidValidator]}
                />
              </GridColumn>

              <GridColumn size="3/12" padding="sMedium xSmall no xSmall">
                <Field
                  component={Dropdown}
                  label={translate('customers.time')}
                  name="customerTime"
                  options={this.customerTimeOptions}
                  validate={[isRequired]}
                  onChange={this.onCustomerTimeChanged}
                />
              </GridColumn>

              <GridColumn size="3/12" padding="sMedium xSmall no xSmall">
                <Field
                  component={Dropdown}
                  label={translate('customers.proximitySetting')}
                  name="proximitySetting"
                  options={this.proximitySettingOptions}
                  validate={[isRequired]}
                  onChange={this.onCustomerProximityChanged}
                />
              </GridColumn>

              <GridColumn size="3/12" padding="lMedium xSmall no xSmall" align="right">
                <PopoverWrapper
                  triggerButton={
                    <Button
                      type="button"
                      color="primary"
                      onClick={() =>
                        this.onFormSubmit(
                          customerDate!,
                          customerTime!,
                          pinLocation || ({} as PinLocation),
                          proximitySetting,
                        )
                      }
                      disabled={isButtonDisabled}
                    >
                      {translate('common.search')}
                    </Button>
                  }
                  popoverContent={
                    isButtonDisabled && (
                      <Popover>
                        <Text block weight="medium" margin="xxSmall no xxSmall">
                          {translate('customers.alertMessages.selectSearchCriteria')}
                        </Text>
                      </Popover>
                    )
                  }
                  size="large"
                />
              </GridColumn>
            </Grid>
          </ProximitySearchForm>
        </form>

        {isLoading ? (
          <PageLoading height="422px" />
        ) : (
          <Grid padding="no small no no" multiLine position="relative" zIndex={2}>
            {!isPinOnMapVisible ? (
              <GridColumn size="12/12">
                <ProximitySearchMapContainer>
                  <CustomerProximityMapGL
                    address={pinLocation}
                    customerLocationCleared={customerLocationCleared}
                    mapCenterByVendor={mapCenterByVendor}
                    radius={proximitySetting}
                  />
                </ProximitySearchMapContainer>
              </GridColumn>
            ) : (
              <GridColumn size="12/12">
                <ProximitySearchMapContainer isPinOnMapVisible>
                  <PinOnMapMapbox
                    circleOptions={{ pinLocation, options: circleOptions, proximitySearchResults }}
                    handleBackClick={() => {}}
                    handlePinSelection={() => {}}
                    handleProximitySearchClickEvent={this.handleProximitySearchClickEvent}
                    mapCenterByVendor={mapCenterByVendorPinOnMap}
                    modalHasTitle={false}
                    radius={proximitySetting}
                    setPinLocationOnMap={this.setPinLocationOnMap}
                    sourceIsProximity
                  />
                </ProximitySearchMapContainer>
              </GridColumn>
            )}

            <GridColumn size="12/12" padding="small xSmall no xSmall">
              {isPageLoading ? (
                <PageLoading height="180px" />
              ) : (
                <Fragment>
                  <Table
                    cells={this.proximitySearchTableCells}
                    rows={proximitySearchResults}
                    rowComponent={ProximitySearchTableRow}
                    rowProps={{}}
                  />
                  {!size(proximitySearchResults) && (
                    <Message padding="sMedium">{translate('customers.alertMessages.noVehicles')}</Message>
                  )}
                </Fragment>
              )}
            </GridColumn>
          </Grid>
        )}
      </ModalSection>
    );
  }
}

const formSelector = formValueSelector('customerProximitySearch');
const dashboardSearchFormSelector = formValueSelector(DASHBOARD_FILTER_FORM_MAPBOX);

const mapStateToProps = (state: AppState, ownProps: ComponentProps) => {
  const format = 'LT';

  const route = state.routes.route.route as any;
  const routeStops = state.routes.routeStops.routeStops;
  const routeTemplate = state.routes.routeTemplate.routeTemplate as any;
  const { stopMapDetails } = state.routes.stopMapDetails;
  const { routeSummary } = state.routes.routeSummary;

  const dashboardFilterDate = dashboardSearchFormSelector(state, 'date');

  const customerLocationDefaultFromCustomers = state.customers.locations.locations
    ? (state.customers.locations.locations! as any[]).find(location => location.id === ownProps.locationId)
    : undefined;
  const customerLocationFromCustomersSelected = customerLocationDefaultFromCustomers
    ? customerLocationDefaultFromCustomers.address
    : undefined;


  const customerLocationDefaultFromDashboard: any = undefined;

  const customerLocationFromDashboardSelected = customerLocationDefaultFromDashboard
    ? {
        latitude: customerLocationDefaultFromDashboard.latitude,
        longitude: customerLocationDefaultFromDashboard.longitude,
        address: customerLocationDefaultFromDashboard.address,
      }
    : undefined;
  const customerLocationFromDashboardSelectedTime =
    customerLocationDefaultFromDashboard && customerLocationDefaultFromDashboard.reportDateTime
      ? moment(time(customerLocationDefaultFromDashboard.reportDateTime), format)
      : undefined;

  const customerLocationDefaultFromRoutes = route?.id
    ? route?.routeLocations.find((location: any) => location.id === ownProps.locationId)
    : routeTemplate
    ? routeTemplate.routeLocations.find(
        (location: any) => location.serviceContractRouteTemplateId === ownProps.locationId,
      )
    : undefined;


  const customerLocationDefaultFromDashboardBeta: any = undefined;

  const customerLocationFromDashboardSelectedBeta = customerLocationDefaultFromDashboardBeta
    ? {
        latitude: customerLocationDefaultFromDashboardBeta.displayLatitude,
        longitude: customerLocationDefaultFromDashboardBeta.displayLongitude,
        address: customerLocationDefaultFromDashboardBeta.locationName,
      }
    : undefined;

  const customerLocationFromDashboardBetaSelectedTime =
    customerLocationDefaultFromDashboardBeta && customerLocationDefaultFromDashboardBeta.statusDate
      ? moment(time(customerLocationDefaultFromDashboardBeta.statusDate), format)
      : undefined;

  const customerLocationDefaultFromRoutesBeta = routeStops.find(s => s.id === ownProps.locationId);

  const customerLocationFromRoutesSelected = customerLocationDefaultFromRoutes
    ? {
        ...customerLocationDefaultFromRoutes.location.address,
        latitude: customerLocationDefaultFromRoutes.service
          ? customerLocationDefaultFromRoutes.service.serviceContractBinDetails[0].displayLatitude
          : customerLocationDefaultFromRoutes.location.address.latitude,
        longitude: customerLocationDefaultFromRoutes.service
          ? customerLocationDefaultFromRoutes.service.serviceContractBinDetails[0].displayLongitude
          : customerLocationDefaultFromRoutes.location.address.longitude,
      }
    : customerLocationDefaultFromRoutesBeta
    ? {
        latitude: customerLocationDefaultFromRoutesBeta.displayLatitude,
        longitude: customerLocationDefaultFromRoutesBeta.displayLongitude,
      }
    : undefined;
  const customerLocationFromStopSelected = stopMapDetails
    ? {
        address: stopMapDetails.address,
        latitude: stopMapDetails.displayLatitude || stopMapDetails.binLatitude,
        longitude: stopMapDetails.displayLongitude || stopMapDetails.binLongitude,
      }
    : undefined;

  const customerLocationFromRoutesSelectedDate = customerLocationDefaultFromRoutes
    ? route?.id
      ? route?.routeDate
      : undefined
    : undefined;

  const statusDate =
    customerLocationDefaultFromRoutes?.pickupStatusDate || customerLocationDefaultFromRoutesBeta?.statusDate;

  const customerLocationFromRoutesSelectedTime = statusDate ? moment(time(statusDate), format) : undefined;

  let customerDate: Date | string = TODAY_FORMATTED;
  if (
    customerLocationFromDashboardSelected ||
    customerLocationFromDashboardSelectedBeta ||
    ownProps.defaultDateIsSelected
  ) {
    customerDate = date(ownProps.defaultDate! || dashboardFilterDate);
  } else if (customerLocationFromRoutesSelected || routeSummary?.date) {
    customerDate = customerLocationFromRoutesSelectedDate || date(routeSummary?.date) || customerDate;
  }

  const currentTime = moment(new Date(), format);
  let customerTime: string | undefined;

  map(CUSTOMER_TIME_OPTIONS, ({ startDateTime, endDateTime, id }) => {
    if (!customerTime) {
      const beforeTime = moment(startDateTime, format);
      const afterTime = moment(endDateTime, format);

      if (
        (customerLocationFromDashboardSelectedTime &&
          customerLocationFromDashboardSelectedTime.isBetween(beforeTime, afterTime)) ||
        (customerLocationFromRoutesSelectedTime &&
          customerLocationFromRoutesSelectedTime.isBetween(beforeTime, afterTime)) ||
        (customerLocationFromDashboardBetaSelectedTime &&
          customerLocationFromDashboardBetaSelectedTime.isBetween(beforeTime, afterTime))
      ) {
        customerTime = CUSTOMER_TIME_OPTIONS[id].value;
      }
    }
  });

  if (!customerTime) {
    map(CUSTOMER_TIME_OPTIONS, ({ startDateTime, endDateTime, id }) => {
      const beforeTime = moment(startDateTime, format);
      const afterTime = moment(endDateTime, format);

      if (currentTime.isBetween(beforeTime, afterTime)) {
        customerTime = CUSTOMER_TIME_OPTIONS[id].value;
      }
    });
  }

  return {
    customerLocation: formSelector(state, 'customerLocation'),
    customerTime: formSelector(state, 'customerTime'),
    customerDate: formSelector(state, 'customerDate'),
    proximitySetting: formSelector(state, 'proximitySetting'),
    customerLocationSelected: formSelector(state, 'customerLocationSelected'),
    vendorId: currentVendorIdSelector(state.account.login, state.vendors.defaultVendor) as any,
    proximitySearchResults: state.customers.vehiclesInProximity.vehiclesInProximity,
    isPageLoading: state.customers.vehiclesInProximity.isLoading,
    customerLocationSelectedAddress:
      customerLocationFromCustomersSelected ||
      customerLocationFromDashboardSelected ||
      customerLocationFromRoutesSelected ||
      customerLocationFromStopSelected ||
      customerLocationFromDashboardSelectedBeta,
    initialValues: {
      customerDate,
      customerLocationSelected:
        customerLocationFromCustomersSelected?.line1 ||
        customerLocationFromDashboardSelected?.address ||
        customerLocationFromRoutesSelected?.line1 ||
        customerLocationFromDashboardSelectedBeta?.address ||
        customerLocationFromStopSelected?.address ||
        stopMapDetails?.address,
      customerTime,
      proximitySetting: map(PROXIMITY_SETTINGS)[0].value,
    },
  };
};

const mapDispatchToProps = {
  change,
  loadVendor,
  resetVehiclesInProximity,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(
  reduxForm<FormValues, PropsWithoutReduxForm>({
    form: 'customerProximitySearch',
    enableReinitialize: true,
    onSubmitFail: focusFirstInvalidField,
  })(CustomerProximitySearchForm),
);
