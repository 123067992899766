import { connect } from 'react-redux';
import { Route, Redirect, withRouter, RouteProps, RouteComponentProps } from 'react-router';

import { AppState } from '../../store';
import { AuthRouteProps } from './AuthRoute';
import { Dictionary } from 'src/common/interfaces/Dictionary';
import { getFallbackUrl } from '../../common/components/DefaultRoute';

const getRoute = (
  props: RouteComponentProps,
  Component: RouteProps['component'],
  isLoggedIn: boolean,
  lastLocations?: Dictionary<string>,
) => {
  const hash = window.location.hash.replace('#', '');
  const fallbackUrl = hash && getFallbackUrl(hash);
  const redirectToUrl = fallbackUrl || '/workorders';

  // if has sso auth param, let the login page be displayed
  const hasSSOAuth = window.location.search.includes('ssoAuth') || window.location.search.includes('ssoError');

  let route;

  const lastLocation =
    lastLocations && lastLocations.lastLocations ? Object.values(lastLocations?.lastLocations)[0] : undefined;

  if (fallbackUrl) {
    route = <Redirect to={redirectToUrl} />;
  } else if (isLoggedIn && !hasSSOAuth) {
    route = (
      <Redirect
        to={{
          pathname: lastLocation || '/workorders',
        }}
      />
    );
  } else if (Component) {
    route = <Component {...props} />;
  }

  return route;
};

const GuestRoute = ({ isLoggedIn, component: Component, lastLocations, ...rest }: AuthRouteProps & RouteProps) => {
  return <Route {...rest} render={props => getRoute(props, Component, isLoggedIn, lastLocations)} />;
};

const mapStateToProps = (state: AppState) => ({
  isLoggedIn: state.account.login.isLoggedIn,
});

export default withRouter(connect(mapStateToProps)(GuestRoute));
