import { useSelector } from 'react-redux';
import { Field, InjectedFormProps, getFormMeta, reduxForm } from 'redux-form';
import { isSingleSignOnFeatureEnabled } from 'src/vendors/ducks/features';
import FormMeta from '../../../common/interfaces/FormMeta';
import { Input } from '../../../core/components';
import LabeledDataView from '../../../core/components/LabeledDataView';
import { Button, Grid, GridColumn } from '../../../core/components/styled';
import translate from '../../../core/services/translate';
import { isRequired, minLength8, passwordComplexity } from '../../../utils/services/validator';
import { USER_PROFILE_FORM } from '../../constants/accountSettings';

const differentPassword = (
  value: string,
  allValues: {
    name: string;
    email: string;
    oldPassword: string;
    password: string;
    confirmPassword: string;
  },
) => (value !== allValues.oldPassword ? undefined : translate('account.mustBeDifferentPassword'));

const matchPassword = (
  value: string,
  allValues: {
    name: string;
    email: string;
    oldPassword: string;
    password: string;
    confirmPassword: string;
  },
) => (value === allValues.password ? undefined : translate('account.mustMatchNewPassword'));

interface FormValues {
  name: string;
  email: string;
  oldPassword: string;
  password: string;
  confirmPassword: string;
}

interface PropsWithoutReduxForm {
  passwordEditable?: boolean;
}

type Props = PropsWithoutReduxForm & InjectedFormProps<FormValues, PropsWithoutReduxForm>;

const AccountSettingsUserForm = (props: Props) => {
  const { passwordEditable = false, initialValues, valid, handleSubmit } = props;

  const isSingleSignOnEnabled = useSelector(isSingleSignOnFeatureEnabled);

  const meta: FormMeta<FormValues> = useSelector(getFormMeta(USER_PROFILE_FORM) as any);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid multiLine>
          <GridColumn size="12/12" padding="no no small">
            {!passwordEditable ? (
              <LabeledDataView label={translate('common.name')} value={initialValues.name} />
            ) : (
              <Field
                name="name"
                component={Input}
                props={{ margin: 'no', label: translate('common.name'), readOnly: true }}
              />
            )}
          </GridColumn>

          <GridColumn size="12/12" padding="no no small">
            {!passwordEditable ? (
              <LabeledDataView label={translate('common.email')} value={initialValues.email} />
            ) : (
              <Field
                name="email"
                autoComplete="username"
                component={Input}
                props={{ margin: 'no', label: translate('common.email'), readOnly: true }}
              />
            )}
          </GridColumn>

          {!isSingleSignOnEnabled && (
            <>
              <GridColumn size="12/12" padding={passwordEditable ? 'no no small' : 'no'}>
                {!passwordEditable ? (
                  <LabeledDataView label={translate('common.password')} value="•••••••" />
                ) : (
                  <Field
                    name="oldPassword"
                    type="password"
                    key="oldPassword"
                    autoComplete="current-password"
                    component={Input}
                    validate={[isRequired]}
                    props={{
                      showErrorBeforeSubmit: meta.oldPassword && meta.oldPassword.touched,
                      margin: 'no',
                      label: translate('common.oldPassword'),
                    }}
                  />
                )}
              </GridColumn>
              {passwordEditable && (
                <>
                  <GridColumn size="12/12" padding="no no small">
                    <Field
                      name="password"
                      type="password"
                      key="password"
                      autoComplete="new-password"
                      component={Input}
                      validate={[isRequired, minLength8, passwordComplexity, differentPassword]}
                      props={{
                        showErrorBeforeSubmit: meta.password && meta.password.touched,
                        margin: 'no',
                        label: translate('common.newPassword'),
                        readOnly: !passwordEditable,
                      }}
                    />
                  </GridColumn>
                  <GridColumn size="12/12" padding="no no small">
                    <Field
                      name="confirmPassword"
                      type="password"
                      key="confirmPassword"
                      autoComplete="new-password"
                      component={Input}
                      label={translate('account.passwordConfirmation')}
                      validate={[isRequired, matchPassword]}
                      props={{
                        showErrorBeforeSubmit: meta.confirmPassword && meta.confirmPassword.touched,
                      }}
                    />
                  </GridColumn>
                  <GridColumn size="6/12" padding="small no no">
                    <Button line color="primary" size="small" type="submit" disabled={!valid}>
                      {translate('account.changePassword')}
                    </Button>
                  </GridColumn>
                </>
              )}
            </>
          )}
        </Grid>
      </form>
    </>
  );
};

export default reduxForm<FormValues, PropsWithoutReduxForm>({
  form: USER_PROFILE_FORM,
  enableReinitialize: true,
  touchOnChange: true,
})(AccountSettingsUserForm);
