import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { mapper, sizeMapper } from '../../../utils/styles';
import { colorMapperWithoutProps } from '../../../utils/styles/colorMapper';

export const LinkContainer = styled.div<{ margin?: string; isAbsolute?: boolean }>`
  margin: ${props => sizeMapper(props.margin, 'large no no no')};
  text-align: center;
  cursor: pointer;

  ${props =>
    props.isAbsolute &&
    css`
      position: absolute;
      left: 0;
      width: 100%;
      text-align: center;
      margin: 0;
      top: 85%;
    `}
`;

interface BetaBaseWrapperProps {
  inline?: boolean;
}

export const BetaBaseWrapper = styled.div<BetaBaseWrapperProps>`
  display: ${props => (props.inline ? 'inline-flex' : 'flex')};
  flex-direction: column;
  justify-content: center;
`;

interface BetaBaseProps {
  padding?: string;
  margin?: string;
  align?: 'left' | 'center' | 'right';
  color?: string;
  noUnderline?: boolean;
}

const BetaBaseStyle = css<BetaBaseProps>`
  display: flex;
  align-items: center;
  justify-content: ${props =>
    mapper(
      props.align,
      {
        left: 'flex-start',
        center: 'center',
        right: 'flex-end',
      },
      'left',
    )};
  text-transform: capitalize;
  color: ${props => colorMapperWithoutProps(props.color, 'primary')};
  padding: ${props => sizeMapper(props.padding, 'xSmall')};
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    !props.noUnderline &&
    css`
      text-decoration: underline;
    `}
`;

export const BetaLink = styled(Link)<BetaBaseProps>`
  ${BetaBaseStyle}
`;

interface StatusContainerProps {
  margin?: string;
  vertical?: boolean;
}

export const StatusContainer = styled.div<StatusContainerProps>`
  display: flex;
  margin: ${props => sizeMapper(props.margin, 'no')};

  ${props =>
    props.vertical &&
    css`
      align-items: center;
    `};
`;

export const PassesStatusComplex = styled.div<{ forwardColor?: string; reverseColor?: string }>`
  width: 36px;
  height: 10px;

  ${props =>
    props.forwardColor &&
    css`
      border-top: 4px solid ${props.forwardColor};
    `};

  ${props =>
    ((!props.forwardColor && props.reverseColor) || (props.forwardColor && !props.reverseColor)) &&
    css`
      height: 4px;
    `};

  ${props =>
    props.reverseColor &&
    css`
      border-bottom: 4px solid ${props.reverseColor};
    `};
`;

export const PassesStatusSimple = styled.div<{ customColor?: string }>`
  width: 36px;
  height: 6px;

  ${props =>
    props.customColor &&
    css`
      background: #fff;
      border: 1px solid #ccc;
      margin-bottom: 3px;
    `};
`;
