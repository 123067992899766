import React, { useCallback, useState } from 'react';

import VendorWithGusIdGuard from '../../account/components/VendorWithGusIdGuard';
import translate from '../../core/services/translate';
import LanguageSettingsModal from '../../settings/components/LanguageSettingsModal';
import {
  MainNavigationButton,
  MainNavigationIcon,
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuItemButton,
} from './styled/NavigationBar';

interface Props {
  isLoggedIn: boolean;
  signInNonTechHaulerUrl: string;
}

export const NavigationBarSettingsGear: React.FC<Props> = ({ isLoggedIn, signInNonTechHaulerUrl }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLanguageSettingsModalOpen, setIsLanguageSettingsModalOpen] = useState(false);

  const closeMenu = useCallback(() => {
    setIsMenuOpen(false);
    document.removeEventListener('click', closeMenu);
  }, []);

  const openMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
    document.addEventListener('click', closeMenu);
  };

  return (
    <>
      <MainNavigationButton onClick={openMenu} isHighlighted={isMenuOpen} id="menu-settings-button">
        <MainNavigationIcon icon="settingsCircle" />
        <NavigationMenu isOpen={isMenuOpen}>
          {!isLoggedIn ? (
            <NavigationMenuItem to={signInNonTechHaulerUrl} id="menu-logout-button">
              {translate('account.login')}
            </NavigationMenuItem>
          ) : (
            <div>
              <NavigationMenuItemButton
                type="button"
                onClick={() => setIsLanguageSettingsModalOpen(true)}
                id="menu-language-button"
              >
                {translate('account.languageSettings')}
              </NavigationMenuItemButton>
              <NavigationMenuItem to="/account-settings" id="menu-account-button">
                {translate('common.applicationSettings')}
              </NavigationMenuItem>
              {/* <AdminGuard>
                <NavigationMenuItem to="/settings/tracking" id="menu-application-button">
                  {translate('common.adminSettings')}
                </NavigationMenuItem>
              </AdminGuard> */}
                <VendorWithGusIdGuard>
                  <NavigationMenuItem to="/hauler-profile/overview" id="menu-account-button">
                    {translate('haulerProfile.haulerProfile')}
                  </NavigationMenuItem>
                </VendorWithGusIdGuard>
              <NavigationMenuItem to="/account/logout" id="menu-logout-button">
                {translate('account.logout')}
              </NavigationMenuItem>
            </div>
          )}
        </NavigationMenu>
      </MainNavigationButton>
      {isLanguageSettingsModalOpen && (
        <LanguageSettingsModal onCloseModal={() => setIsLanguageSettingsModalOpen(false)} />
      )}
    </>
  );
};
