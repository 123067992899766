import { date } from '../../../utils/services/formatter';

export enum DispatchStatus {
  CLOSED = 'Closed',
  COMPLETE = 'Work Order Complete',
  CONFIRMED = 'Service Confirmed',
  DELIVERY_SUSPENDED = 'Delivery Suspended',
  DECLINE = 'Decline',
  PENDING = 'Pending',
  REQUESTED = 'Requested',
  SCHEDULED = 'Scheduled',
  UNABLE_TO_COMPLETE = 'Unable To Complete',
}

export const getDateByStatus = (statusName: string, dates: any) => {
  const { CLOSED, COMPLETE, CONFIRMED, DELIVERY_SUSPENDED, PENDING, REQUESTED, SCHEDULED, UNABLE_TO_COMPLETE } = DispatchStatus;

  switch (statusName) {
    case REQUESTED:
      return dates.requestedDate ? date(dates.requestedDate) : '';

    case PENDING:
      return dates.requestedDate ? date(dates.requestedDate) : '';

    case SCHEDULED:
      return dates.scheduledDate ? date(dates.scheduledDate) : '';

    case COMPLETE:
      return dates.completedDate ? date(dates.completedDate) : '';

    case CONFIRMED:
      return dates.completedDate ? date(dates.completedDate) : '';

    case DELIVERY_SUSPENDED:
      return dates.scheduledDate ? date(dates.scheduledDate) : '';

    case CLOSED:
      return dates.latestDate ? date(dates.latestDate) : '';

    case UNABLE_TO_COMPLETE:
      return dates.requestedDate ? date(dates.requestedDate) : date(dates.scheduledDate);

    default:
      return dates.requestedDate ? date(dates.requestedDate) : '';
  }
};

