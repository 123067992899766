import update from 'immutability-helper';
import { AnyAction, Dispatch } from 'redux';

import { DataRetention } from '../interfaces/DataRetention';
import {
  loadDataRetention as doLoadDataRetention,
} from '../services/dataRetention';

// Actions
const START_LOAD = 'vendors/dataRetention/START_LOAD';
const COMPLETE_LOAD = 'vendors/dataRetention/COMPLETE_LOAD';
const FAIL_LOAD = 'vendors/dataRetention/FAIL_LOAD';

// Initial state
const initialState = {
  isLoading: false,
  isSaving: false,
  dataRetention: [] as DataRetention[],
};

// Reducer
export const reducer = (state = initialState, action: AnyAction) => {
  switch (action.type) {
    case START_LOAD:
      return update(state, {
        $merge: { isLoading: true },
      });

    case COMPLETE_LOAD:
      return update(state, {
        $merge: {
          isLoading: false,
          dataRetention: action.dataRetention,
        },
      });

    case FAIL_LOAD:
      return update(state, {
        $merge: { isLoading: false },
      });

    default:
      return state;
  }
};

// Action creators
const startLoad = () => ({
  type: START_LOAD,
});

const completeLoad = (dataRetention: DataRetention[]) => ({
  type: COMPLETE_LOAD,
  dataRetention,
});

const failLoad = () => ({
  type: FAIL_LOAD,
});

export const loadDataRetention = () => (dispatch: Dispatch) => {
  dispatch(startLoad());
  const loadDataRetentionPromise = doLoadDataRetention();
  loadDataRetentionPromise
    .then((dataRetention: DataRetention[]) => {
      dispatch(completeLoad(dataRetention));
    })
    .catch(() => {
      dispatch(failLoad());
    });
  return loadDataRetentionPromise;
};
