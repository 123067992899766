import styled, { css } from 'styled-components';

import { Icon } from '../../../../core/components';
import { BareButtonStyle } from '../../../../core/components/styled';
import { sizeMapper } from '../../../../utils/styles';

export const InfoIcon = styled(Icon).attrs({ icon: 'infoFill' })`
  width: 12px;
  height: 12px;
  color: ${props => props.theme.grayDark};
  transition: color 0.3s ease-out;

  &:hover {
    color: ${props => props.theme.brandPrimary};
  }
`;

export const DispatchBoardContainerError = styled.div<{ margin?: string }>`
  max-width: 600px;
  text-align: center;
  margin: ${props => sizeMapper(props.margin, 'auto')};
  color: ${props => props.theme.brandAlert};
`;

export const DispatchBoardPaneBackButtonIcon = styled(Icon)`
  width: 14px;
  height: 14px;
  margin-right: 10px;
  color: ${props => props.theme.brandPrimary};
`;

export const DispatchBoardPaneBackButton = styled.button<{
  padding?: string;
}>`
  ${BareButtonStyle}
  line-height: 23px;
  top: 2px;
  padding: ${props => sizeMapper(props.padding, 'auto')};
`;

export const SelectOnMap = styled.button`
  ${BareButtonStyle}
  color: ${props => props.theme.brandPrimary};
  line-height: 23px;
`;

export const PinOnMapWrapper = styled.div<{ sourceIsProximity?: boolean }>`
  ${props =>
    props.sourceIsProximity &&
    css`
      margin-bottom: 70px;
    `};
`;
