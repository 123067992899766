import { camelCase, round } from 'lodash-es';
import { FC } from 'react';

import {
  MapGLPopup,
  MapGLPopupContent,
  MapGLPopupSubtitle,
  MapGLPopupTitle,
} from 'src/common/components/map/MapGLPopup';
import { getSpeedInformation } from 'src/common/services/getSpeedInformation';
import LabeledDataView from 'src/core/components/LabeledDataView';
import { useSelector } from 'src/core/hooks/useSelector';
import translate from 'src/core/services/translate';
import { getMeasurementType } from 'src/dashboard/hooks/useLoadDataForMapboxDashboard';
import { RouteVehiclePosition } from 'src/dashboard/interfaces/routesData';
import { VEHICLE_TYPE_IDS } from 'src/fleet/constants';
import { time } from 'src/utils/services/formatter';

interface Props {
  vehiclePosition: RouteVehiclePosition;
}
const TravelPathVehiclePositionPopup: FC<Props> = ({ vehiclePosition }) => {
  const systemOfMeasurementId = useSelector(state => state.vendors.vendor.vendor.systemOfMeasurementId);
  const { vehicleTypes } = useSelector(state => state.fleet.vehicleTypes);
  const vehicleInformation = useSelector(state => state.routes.travelPathBuildAndEdit.routeVehicleInformation);

  const vehicleSubTypes =
    vehicleTypes.find(vehicleType => vehicleType.id === vehiclePosition.vehicleTypeId)?.subTypes || [];
  const subType = vehicleSubTypes.find(
    vehicleSubType => vehicleSubType.id === vehicleInformation?.vehicleSubTypeId,
  )?.technicalName;
  const type = VEHICLE_TYPE_IDS[vehiclePosition.vehicleTypeId]?.technicalName;

  const vehicleType = subType ? subType : type;

  return (
    <MapGLPopup>
      <MapGLPopupTitle title={vehiclePosition.vehicleName} />

      <MapGLPopupSubtitle subtitle={translate(`vehicles.vehicleTypes.${camelCase(vehicleType)}`)} />

      <MapGLPopupContent>
        <LabeledDataView
          noWrap
          width="calc(100% - 10px)"
          size="small"
          label={translate('common.timestamp')}
          value={vehiclePosition.eventDateTime ? time(vehiclePosition.eventDateTime) : '-'}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('drivers.driverName')}
          value={vehiclePosition?.driverName || '-'}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.vehicleStatus')}
          value={translate(`common.${!!vehicleInformation.isActive ? 'active' : 'inactive'}`)}
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.lastReportedSpeed')}
          value={
            ((vehicleInformation.lastReportedSpeed || vehicleInformation.lastReportedSpeed === 0) &&
              getSpeedInformation(
                round(vehicleInformation.lastReportedSpeed),
                getMeasurementType(systemOfMeasurementId),
              )) ||
            '-'
          }
        />

        <LabeledDataView
          width="calc(100% - 10px)"
          size="small"
          label={translate('vehicles.totalMilesDriven')}
          value={vehicleInformation.totalMilesDriven || '-'}
        />
      </MapGLPopupContent>
    </MapGLPopup>
  );
};

export default TravelPathVehiclePositionPopup;
