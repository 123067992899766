import { combineReducers } from 'redux';

import { reducer as billingCycleOptions } from './billingCycleOptions';
import { reducer as dispatchOpportunitiesCountReducer } from './dispatchOpportunitiesMenuCount';
import { reducer as disposalSiteLocationsReducer } from './disposalSiteLocations';
import { reducer as equipmentTypesReducer } from './equipmentTypes';
import { reducer as filtersReducer } from './filters';
import { reducer as fleetRouteInstancesReducer } from './fleetRouteInstances';
import { reducer as holidaysReducer } from './holidays';
import { reducer as jobPriorityTypesReducer } from './jobPriorityTypes';
import { reducer as languageOptionsReducer } from './languageOptions';
import { reducer as pickupFrequencyTypesReducer } from './pickupFrequencyTypes';
import { reducer as serviceTypesReducer } from './serviceTypes';
import { reducer as streetSegmentAgingIntervalsReducer } from './streetSegmentAgingIntervals';
import { reducer as timeMeasurementTypesReducer } from './timeMeasurementTypes';
import { reducer as unitOfMeasureTypesReducer } from './unitOfMeasureTypes';
import { reducer as userTypesReducer } from './userTypes';
import { reducer as wasteAuditTypesReducer } from './wasteAuditTypes';
import { reducer as wasteTypesReducer } from './wasteTypes';
import { reducer as businessTypesReducer } from './businessTypes';
import { reducer as rateCodesReducer } from './rateCodes';

export { loadRateCodeTypes } from './rateCodes'
export { loadDispatchOpportunitiesCount } from './dispatchOpportunitiesMenuCount';
export {
  disposalSiteLocationsByIdSelector,
} from './disposalSiteLocations';
export { loadBillingCycles } from './billingCycleOptions';
export { loadEquipmentTypes } from './equipmentTypes';
export { loadFiltersSettings, saveGlobalFiltersSettings, savePreferencesFiltersSettings } from './filters';
export {
  completeSave,
  loadLanguageOptions,
  localeValue,
  resetLanguageOptions,
  saveLanguageOptions,
} from './languageOptions';
export {
  loadPickupFrequencyTypes,
} from './pickupFrequencyTypes';
export {
  equipmentSizesSelector,
  equipmentTypesSelector,
  loadServiceTypes,
  resetServiceTypes,
  serviceTypeIdByTechnicalNameSelector,
  technicalNameByServiceTypeIdSelector,
} from './serviceTypes';
export { loadStreetSegmentAgingIntervals } from './streetSegmentAgingIntervals';
export { loadTimeMeasurementTypes } from './timeMeasurementTypes';
export { loadUserTypes } from './userTypes';
export { loadWasteAuditTypes } from './wasteAuditTypes';
export {
  filterWasteTypesByVehicleType,
  loadWasteTypes,
  resetWasteTypes,
  technicalNameByWasteTypeIdSelector,
} from './wasteTypes';
export { loadFleetRouteInstances } from './fleetRouteInstances';
export { loadUnitOfMeasureTypes } from './unitOfMeasureTypes';

export { loadBusinessTypes } from './businessTypes';

export const reducer = combineReducers({
  billingCycles: billingCycleOptions,
  dispatchOpportunitiesCount: dispatchOpportunitiesCountReducer,
  disposalSiteLocations: disposalSiteLocationsReducer,
  equipmentTypes: equipmentTypesReducer,
  filters: filtersReducer,
  fleetRouteInstances: fleetRouteInstancesReducer,
  holidays: holidaysReducer,
  jobPriorityTypes: jobPriorityTypesReducer,
  languageOptions: languageOptionsReducer,
  pickupFrequencyTypes: pickupFrequencyTypesReducer,
  rateCodeTypes: rateCodesReducer,
  serviceTypes: serviceTypesReducer,
  streetSegmentAgingIntervals: streetSegmentAgingIntervalsReducer,
  timeMeasurementTypes: timeMeasurementTypesReducer,
  unitOfMeasureTypes: unitOfMeasureTypesReducer,
  userTypes: userTypesReducer,
  wasteAuditTypes: wasteAuditTypesReducer,
  wasteTypes: wasteTypesReducer,
  businessTypes: businessTypesReducer,
});
