
import { SIMPLE } from 'src/common/constants/drivingComplexity';
import store from 'src/store';

export const calculateIsDriverExperienceSimple = (isSnowPlowRoute?: boolean) => {
  const snowSettings = store.getState().vendors.snowPlowSettings.snowPlowSettings;
  const sweeperSettings = store.getState().vendors.streetSweepingSettings.streetSweepingSettings;
  return (
    (isSnowPlowRoute !== undefined && isSnowPlowRoute ? snowSettings : sweeperSettings)?.drivingComplexityType?.id ===
    SIMPLE
  );
};
