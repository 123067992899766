import { http } from '../../core/services/http';

export const createCustomer = (customer: any) =>
  http.post('customers', customer).then(response => response.data.customer);

export const updateCustomer = (customer: any) =>
  http
    .put(`customers/${customer.id}`, customer, {
      params: { shouldRecreateRoutes: customer.shouldRecreateRoutes || false },
    })
    .then(response => response.data);

export const loadCustomer = (customerId: number) => http.get(`customers/${customerId}`).then(response => response.data);
