import { combineReducers } from 'redux';
import { reducer as billingModuleReducer } from './billingModule';
import { reducer as bulkyItemSchedulerReducer } from './bulkyItemScheduler';
import { reducer as changePasswordReducer } from './changePassword';
import { reducer as cityAlertsReducer } from './cityAlerts';
import { reducer as cityAlertSettingsReducer } from './cityAlertSettings';
import { reducer as dataRetentionReducer } from './dataRetention';
import { reducer as defaultVendorReducer } from './defaultVendor';
import { reducer as excludeCustomerFromProcessingFeeReducer } from './excludeCustomerFromProcessingFee';
import { reducer as featuresReducer } from './features';
import { reducer as loadSnowPlowSettings } from './snowPlowSettings';
import { reducer as loadStreetSweepingSettings } from './streetSweepingSettings';
import { reducer as quickBooksReducer } from './quickBooks';
import { reducer as reasonTypesReducer } from './reasonTypes';
import { reducer as stationaryAlertsReducer } from './stationaryAlerts';
import { reducer as stripeAccountReducer } from './stripeAccount';
import { reducer as usersReducer } from './users';
import { reducer as vehicleTypesForExceptionConfigurationReducer } from './vehicleTypesForExceptionConfiguration';
import { reducer as vendorAccessReducer } from './vendorAccess';
import { reducer as vendorReducer } from './vendor';
import { reducer as vendorsReducer } from './vendors';

export { deleteDefaultVendor, resetDefaultVendor, setDefaultVendor } from './defaultVendor';

export { loadVendors, resetVendors, vendorByIdSelector } from './vendors';
export {
  loadAvailableVendors,
  loadAssignedVendors,
  saveVendorAccess,
  saveAssignedVendors,
  vendorAccessUserIdSelector,
} from './vendorAccess';

export {
  COMPLETE_DELETE as COMPLETE_DELETE_USER,
  COMPLETE_LOAD as COMPLETE_LOAD_USERS,
  COMPLETE_SAVE as COMPLETE_SAVE_USER,
  deleteUser,
  districtsUserIdSelector,
  gusIdSelector,
  isUserManagerSelector,
  loadDistricts,
  loadUserById,
  loadUsers,
  resetUsers,
  saveDivision,
  saveUser,
  updateDistricts,
  userIdSelector,
} from './users';

export {
  COMPLETE_SAVE as COMPLETE_SAVE_VENDOR,
  checkIfVendorHasNetworkFile,
  loadVendor,
  resetVendor,
  saveVendor,
  VIEW_ADVANCED_PROFILE,
  viewAdvancedProfile,
} from './vendor';

export {
  autoRefreshStatusSelector,
  displayUnscheduledStopsStatusSelector,
  featuresForLoggedInUserSelector,
  featureStatusSelector,
  flagLocationStatusSelector,
  geoFenceSelector,
  inferenceAuditStatusSelector,
  loadFeatures,
  resetFeatures,
  routeSequencingStatusSelector,
  saveFeatures,
  vehicleStrobeImagesStatusSelector,
  visionConfigurationStatusSelector,
  wasteAuditStatusSelector,
} from './features';

export { changePassword, COMPLETE_CHANGE_PASSWORD, resetChangePassword } from './changePassword';

export { loadStationaryAlert, resetStationaryAlerts, saveStationaryAlert } from './stationaryAlerts';

export { loadReasonTypes, resetReasonTypes } from './reasonTypes';

export {
  loadVehicleTypesForPickupExceptions,
  loadVehicleTypesForExceptionManagement,
  resetVehicleTypesForExceptionConfiguration,
  technicalNameByVehicleTypeIdSelector,
} from './vehicleTypesForExceptionConfiguration';

export { loadSnowPlowSettings, saveSnowPlowSettings, loadSnowPlowDefaultSettings } from './snowPlowSettings';

export {
  loadStreetSweepingSettings,
  saveStreetSweepingSettings,
  loadStreetSweepingDefaultSettings,
} from './streetSweepingSettings';

export {
  checkIsBillingActive,
  completeStripeAccountSetup,
  createStripeAccount,
  refreshStripeAccountLink,
} from './stripeAccount';


export { loadDataRetention } from './dataRetention';

export {
  loadBulkyItemScheduler,
} from './bulkyItemScheduler';

export {
  loadCityAlertImages,
  loadCityAlerts,
  resetCityAlertImages,
  loadCityAlertsForRoute,
  resetCityAlerts,
  saveCityAlert,
} from './cityAlerts';

export { loadBillingModule, saveBillingModule } from './billingModule';

export const reducer = combineReducers({
  billingModule: billingModuleReducer,
  bulkyItemScheduler: bulkyItemSchedulerReducer,
  changePassword: changePasswordReducer,
  cityAlerts: cityAlertsReducer,
  cityAlertSettings: cityAlertSettingsReducer,
  dataRetention: dataRetentionReducer,
  defaultVendor: defaultVendorReducer,
  excludeCustomerFromProcessingFee: excludeCustomerFromProcessingFeeReducer,
  features: featuresReducer,
  quickBooks: quickBooksReducer,
  reasonTypes: reasonTypesReducer,
  snowPlowSettings: loadSnowPlowSettings,
  stationaryAlerts: stationaryAlertsReducer,
  streetSweepingSettings: loadStreetSweepingSettings,
  stripeAccount: stripeAccountReducer,
  users: usersReducer,
  vehicleTypesForExceptionConfiguration: vehicleTypesForExceptionConfigurationReducer,
  vendor: vendorReducer,
  vendorAccess: vendorAccessReducer,
  vendors: vendorsReducer,
});
