import { toLower } from 'lodash-es';

const removeSpecialCharacters = (str: string) => str.replace(/([.?*+^$[\]\\(){}|-])/g, '');

export const multiWordAndSearch = (text?: string | number, searchWords?: string) => {
  if (!searchWords || !text) return false;
  const searchKeyWords = toLower(searchWords).split('*').filter(Boolean);

  let currTest: any;
  while (searchKeyWords.length > 0) {
    currTest = searchKeyWords.pop();
    if (!removeSpecialCharacters(text.toString()).match(new RegExp(removeSpecialCharacters(currTest), 'i')))
      return false;
  }
  return true;
};
